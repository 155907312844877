import React, { useEffect } from 'react';
import { FieldText, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Application, DurationDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  UpdateVacancySchema,
  handleFormsServerErrors,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

export const UpdateApplicationForm = ({
  onCancel,
  onSuccess,
  applicationToUpdate,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  applicationToUpdate: Application;
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<DurationDto>({
    resolver: yupResolver(UpdateVacancySchema),
  });

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerUpdateApplicationDuration(
        applicationToUpdate._id,
        {
          ...values,
        },
      )
      .then(() => {
        toast.success('Application Updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset({
      ...applicationToUpdate,
      availableFrom: applicationToUpdate.availableFrom?.split('T')?.[0] ?? '',
      availableTo: applicationToUpdate.availableTo?.split('T')?.[0] ?? '',
    });
  }, [applicationToUpdate]);

  return (
    <FormContainer>
      <FieldText
        label="Candidate Name"
        required
        value={
          applicationToUpdate?.candidate?.firstName +
          ' ' +
          applicationToUpdate?.candidate?.lastName
        }
        disabled
        data-testid="candidate-name"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Start Date"
        required
        type="date"
        register={register('availableFrom')}
        error={errors.availableFrom}
        min={applicationToUpdate.job.vacantFrom.split('T')[0]}
        max={applicationToUpdate.job.vacantTo.split('T')[0]}
        data-testid="start-date"
      />
      <FieldText
        placeholder="Enter here ..."
        label="End Date"
        required
        type="date"
        register={register('availableTo')}
        error={errors.availableTo}
        min={applicationToUpdate.job.vacantFrom.split('T')[0]}
        max={applicationToUpdate.job.vacantTo.split('T')[0]}
        data-testid="end-date"
      />
      <div className="form-actions">
        <Button onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
