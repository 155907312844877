import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { LoginContainer } from './login.style';
import { AppIcon } from '../../icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AxiosInstance, AxiosInstanceErrorResponse, ERoute } from '../../utils';
import { Button, TitlePage } from '../../components';
import { toast } from 'react-toastify';
import OtpInput from '../../components/ui/inputs/otp-input';

export const VerifyOtpPage = () => {
  const { login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const [otpValue, setOtpValue] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  const onClickVerifyOtp = async (otp: string) => {
    if (otp.length < 6) {
      toast.error('Please enter the complete OTP');
      return;
    }

    setIsSending(true);

    await AxiosInstance.auth
      .authControllerVerifyOtp({ email, otp })
      .then((response) => {
        login(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setIsSending(false);
  };

  const onRequestNewOtp = async () => {
    if (!email) {
      toast.error('Email is required to request a new OTP.');
      return;
    }

    await AxiosInstance.auth
      .authControllerRequestOtp({ email })
      .then(() => {
        toast.success('A new OTP has been sent to your email.');
        setTimer(60);
        setCanResend(false);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setOtpValue('');
  };

  const maskEmail = (email: string) => {
    const [user, domain] = email.split('@');
    if (user.length > 2) {
      return `${user[0]}${user[1]}${'*****'}@${domain}`;
    }
    return email;
  };

  useEffect(() => {
    if (!canResend) {
      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            setCanResend(true);
            clearInterval(countdown);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [canResend]);

  useEffect(() => {
    if (!email) {
      navigate(`/${ERoute.LOGIN}`);
    }
  }, [email]);

  return (
    <LoginContainer data-testid="login-container">
      <div className="left-container" data-testid="left-container">
        <div className="image-container" data-testid="image-container"></div>
        <div className="bottom-container" data-testid="bottom-container">
          <AppIcon isWhiteColor data-testid="app-icon" />
          <div className="title" data-testid="login-title">
            Let’s empower your <br /> candidates today.
          </div>
          <div className="subtitle" data-testid="login-subtitle">
            We help to complete all your conveyancing needs easily
          </div>
        </div>
      </div>
      <div className="right-container" data-testid="right-container">
        <div className="top-container" data-testid="top-container"></div>
        <div className="content-container" data-testid="content-container">
          <TitlePage className="text-center" data-testid="title-page">
            Verify your OTP
          </TitlePage>
          <br />
          <br />
          <br />
          <br />
          <div className="email-container" data-testid="otp-container">
            <div
              className="input-container text-center"
              data-testid="otp-input-container"
            >
              <h6 data-testid="otp-instruction">
                Please enter the OTP code <br /> to verify your account
              </h6>
              <div data-testid="otp-email-sent">
                <span>A code has been sent to</span> <b>{maskEmail(email)}</b>
              </div>
              <br />
              <OtpInput
                otpValue={otpValue}
                setOtpValue={setOtpValue}
                onComplete={onClickVerifyOtp}
                data-testid="otp-input"
              />
            </div>
          </div>
          <Button
            type="primary"
            onClick={() => onClickVerifyOtp(otpValue)}
            disabled={isSending}
            data-testid="submit-otp-button"
          >
            Submit OTP
          </Button>
          <div className="text-center mt-2" data-testid="resend-otp-container">
            <span className="d-block mobile-text" data-testid="resend-text">
              Don't receive the code?
            </span>
            <small
              className={`font-weight-bold text-danger ${canResend ? 'cursor-pointer' : 'text-muted'}`}
              onClick={() => canResend && onRequestNewOtp()}
              style={{ cursor: canResend ? 'pointer' : 'not-allowed' }}
              data-testid="resend-otp-link"
            >
              {canResend ? 'Request New OTP' : `Resend OTP in ${timer}s`}
            </small>
          </div>
        </div>
        <div className="bottom-container" data-testid="bottom-footer-container">
          <div className="copyright" data-testid="copyright">
            © 2024 Careo. All rights reserved.
          </div>
          <Link to={''} data-testid="terms-conditions-link">
            Terms & Conditions
          </Link>
          <a
            href={'https://www.careo.ai/privacy-policy'}
            target="_blank"
            data-testid="privacy-policy-link"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </LoginContainer>
  );
};
