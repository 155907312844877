import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AddLegalLeadDto,
  HostedFile,
  Lead,
  User,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { legalLeadSchema } from '../../../utils/validators/leads.validator';
import { useForm } from 'react-hook-form';
import { Button, Select } from '../../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  uploadLeadFiles,
} from '../../../utils';
import { toast } from 'react-toastify';
import { DocumentsListComponent } from '../../document';
import { LeftArrowIcon, PlusIcon } from '../../../icons';

type LegalFormStepProps = {
  usersList: User[];
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdLead: Lead;
  setCreatedLead: Dispatch<SetStateAction<Lead | undefined>>;
  setIsFormModalOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; data?: Lead }>
  >;
};

export const LegalFormStep = ({
  usersList,
  createdLead,
  setCreatedLead,
  setIsFormModalOpen,
  setStep,
}: LegalFormStepProps) => {
  const fileInputRef = useRef<any>(null);
  const [isUploading, setIsUploading] = useState(false);

  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    reset,
    setFocus,
    formState: { errors },
    control,
  } = useForm<AddLegalLeadDto>({
    resolver: yupResolver(legalLeadSchema),
  });

  const onAddLegalLead = async () => {
    const values = getValues();

    AxiosInstance.leads
      .leadsControllerAddLegalLeadyId(createdLead._id, values)
      .then((response) => {
        setCreatedLead(response.data);
        toast.success('Legal Lead saved successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getLeadDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target?.files ?? []);
    if (selectedFiles.length) {
      setIsUploading(true);
      await onUpload(selectedFiles);
      e.target.value = '';
      setIsUploading(false);
    }
  };

  const onUpload = async (files: File[]) => {
    await uploadLeadFiles(createdLead._id!, files)
      .then(() => {
        toast.success('Document uploaded successfully');
        getLeadDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getLeadDocuments = () => {
    AxiosInstance.files
      .filesControllerFindLeadDocuments(createdLead._id!)
      .then((result) => {
        setDocuments(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (createdLead) {
      reset({
        legalContactId: createdLead.legalContact?._id,
      });
      getLeadDocuments();
    }
  }, [createdLead._id]);

  return (
    <>
      <Select
        placeholder="Enter here ..."
        label="Legal Contact"
        options={usersList.map((el) => ({
          value: el._id,
          label: el.firstName + ' ' + el.lastName,
        }))}
        register={register('legalContactId')}
        control={control}
        required
        error={errors.legalContactId}
        data-testid="legal-contact-select" // Added test ID for Select
      />

      <DocumentsListComponent
        documents={documents}
        getDocuments={getLeadDocuments}
        onClickDelete={onClickDelete}
      />
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUpload}
        style={{ display: 'none' }}
        disabled={isUploading}
        multiple
      />
      <Button
        onClick={handleButtonClick}
        type="success"
        variant="outlined"
        disabled={isUploading}
      >
        <PlusIcon /> Add Another Document
      </Button>
      <div className="form-actions stepper">
        <div className="left-container">
          <Button
            type="danger"
            variant="outlined"
            onClick={() => setIsFormModalOpen({ isOpen: false })}
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button onClick={() => setStep(2)}>
            <LeftArrowIcon /> Back
          </Button>
          <Button onClick={handleSubmit(onAddLegalLead)} type="success">
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
