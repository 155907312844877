import { sidebarWidth } from './sidebar';

export const breakpoint = {
  mobile: 576,
  pad: 992,
};

export const theme = {
  header: {
    height: '65px',
    mobileHeight: '50px',
  },
  sidebar: {
    open: sidebarWidth.opened + 'px',
    close: sidebarWidth.closed + 'px',
  },
  text: {
    blackColor: '#111827',
    greenColor: '#27a376',
    greyColor: '#687588',
    yellowColor: '#FFC837',
    redColor: '#ee2f2f',
    orangeColor: '#ff8837',
  },
  firstColor: '#4723d9',
  firstColorLight: '#afa5d9',
  whiteColor: '#fff',
  bodyFont: "'Nunito', sans-serif",
  normalFontSize: '1rem',
  zIndexFixed: 100,
};

export type ITheme = typeof theme;

declare module 'styled-components' {
  type DefaultTheme = ITheme;
}

export type TUIType =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'neutral'
  | 'default';

export function getTypeStyles(
  type: string,
  variant: 'contained' | 'outlined' = 'contained',
) {
  const containedStyles = (color: string, backgroundColor: string) => `
        color: ${color};
        background-color: ${backgroundColor};
        svg path {
          fill: ${color};
        }
      `;

  const outlinedStyles = (color: string) => `
        color: ${color};
        background-color: transparent;
        border: 1px solid ${color};
        svg path {
          fill: ${color} !important;
        }
        &:not(.disabled):hover {
          background-color: rgba(0, 0, 0, 0.04); /* Adds a slight hover effect */
        }
      `;

  switch (type) {
    case 'success':
      return variant === 'outlined'
        ? outlinedStyles('#0CAF60')
        : containedStyles('#0CAF60', '#E7F7EF');
    case 'warning':
      return variant === 'outlined'
        ? outlinedStyles('#E6BB20')
        : containedStyles('#E6BB20', '#FFF6D3');
    case 'danger':
      return variant === 'outlined'
        ? outlinedStyles('#E03137')
        : containedStyles('#FFEDEC', '#E03137');
    case 'info':
      return variant === 'outlined'
        ? outlinedStyles('#8C62FF')
        : containedStyles('#8C62FF', '#F4F0FF');
    case 'primary':
      return variant === 'outlined'
        ? outlinedStyles('#111827')
        : containedStyles('#FFFFFF', '#111827');
    case 'neutral':
      return variant === 'outlined'
        ? outlinedStyles('#687588')
        : containedStyles('#687588', '#F4F4F4');
    default:
      return variant === 'outlined'
        ? outlinedStyles('#1f1b2a')
        : containedStyles('#1f1b2a', '#fafafa');
  }
}
