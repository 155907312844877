import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  Checkbox,
  NewCandidateForm,
  SearchInput,
  SideModal,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
  HeaderPageContainer,
  ConfirmModal,
  NewSharedCandidatesList,
  UploadButton,
  MultipleSelect,
  Pagination,
} from '../../../components';

import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../../icons';
import {
  getCountiesOfSelectedRegions,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterCandidate,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
  uploadCandidatesCsvRequest,
} from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Candidate,
  EUserRole,
  Payroll,
  User,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { isCRMApp } from '../../../environment/app.type';
import { useAuth } from '../../../contexts/auth.context';

type CandidatesListPageProps = {
  isDashboard?: boolean;
  itemsPerPage?: number;
};

export const CandidatesListPage = ({
  isDashboard = false,
  itemsPerPage = 8,
}: CandidatesListPageProps) => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isProfileCompleteQuery = searchParams.get('isProfileComplete');

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [candidatesList, setCandidatesList] = useState<Candidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalCreateListOpen, setIsModalCreateListOpen] = useState(false);
  const [isModalAddCandidateOpen, setIsModalAddCandidateOpen] = useState(false);
  const [isModalUpdateCandidateOpen, setIsModalUpdateCandidateOpen] =
    useState<Candidate>();
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [recordToDelete, setRecordToDelete] = useState<Candidate>();

  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    departments: [],
    levels: [],
    grades: [],
    status: [],
    completions: [],
  });

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [designationOptions, setDesignationOptions] = useState<string[]>([]);
  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState<string[]>(
    [],
  );

  const onClickCheckboxAll = (value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        return [...candidatesList.map((el) => el._id)];
      } else return [];
    });
  };

  const onClickCheckbox = (id: string, value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const onClickEdit = (candidate: Candidate) => {
    setIsModalUpdateCandidateOpen(candidate);
  };

  const onClickCreate = () => {
    setIsModalAddCandidateOpen(true);
  };

  const onClickCreateList = () => {
    setIsModalCreateListOpen(true);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CANDIDATES}/${id}`);
  };

  const uploadCandidatesCsv = async (file: File) => {
    await uploadCandidatesCsvRequest(file)
      .then(() => {
        getCandidates();
        toast.success('Candidates uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteCandidate = async () => {
    await AxiosInstance.candidates
      .candidatesControllerDeleteCandidate(recordToDelete?._id!)
      .then(() => {
        toast.success('Candidate Removed successfully');
        toast.info("All Candidate's placements has been removed successfully");
        getCandidates();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setRecordToDelete(undefined);
  };

  const getPayrolls = async () => {
    AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidates = async () => {
    setIsLoading(true);
    await AxiosInstance.candidates
      .candidatesControllerFindAll()
      .then((response) => {
        console.log('response');
        console.log(response);
        let result = response.data.items;
        if (isProfileCompleteQuery === 'false') {
          result = result.filter((el) => !el.isProfileComplete);
        }
        setCandidates(result);
        const options = new Set(result.map((el) => el.department ?? ''));
        setDesignationOptions([...(options as any)]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, itemsPerPage);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(candidates, filter);
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [candidates, filter, sort]);

  useEffect(() => {
    getCandidates();
    getPayrolls();
    getUsers();
  }, []);

  return (
    <>
      {!isDashboard && (
        <TabFilter
          data-testid="tab-filter"
          filters={[
            {
              title: 'Candidates',
              url: ERoute.CANDIDATES,
            },
            {
              title: 'Lists',
              url: ERoute.SHARED_CANDIDATES,
            },
          ]}
        />
      )}

      <CardContainer
        className="candidate-list-card-container"
        data-testid="candidate-list-card-container"
      >
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Candidates</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Candidate
            </SubTitlePage>
          </div>
          {!isDashboard && (
            <div className="right-container" data-testid="right-container">
              <UploadButton
                data-testid="upload-button"
                accept=".csv"
                onUpload={uploadCandidatesCsv}
              >
                Upload
              </UploadButton>
              <Button
                type="primary"
                onClick={() => onClickCreate()}
                data-testid="add-new-button"
              >
                <PlusIcon /> Add new
              </Button>
            </div>
          )}
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search candidate"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          {!isDashboard && (
            <>
              <MultipleSelect
                data-testid="regions-multiple-select"
                placeholder="All Regions"
                options={regionsWithCounties.map((el) => ({
                  label: el.region,
                  value: el.region,
                }))}
                onChange={(e) => {
                  const values = e as string[];
                  setFilter((prev) => ({
                    ...prev,
                    regions: values ?? [],
                  }));
                }}
              />
              <MultipleSelect
                data-testid="counties-multiple-select"
                placeholder="All Counties"
                options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
                  (el) => ({ label: el, value: el }),
                )}
                onChange={(e) => {
                  const values = e as string[];
                  setFilter((prev) => ({
                    ...prev,
                    counties: values ?? [],
                  }));
                }}
                disabled={!filter.regions.length && !filter.counties.length}
              />
            </>
          )}
          <MultipleSelect
            data-testid="specialties-multiple-select"
            placeholder="All Specialties"
            options={designationOptions.map((el) => ({ label: el, value: el }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                departments: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            data-testid="levels-multiple-select"
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
          />
          <MultipleSelect
            data-testid="grades-multiple-select"
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
          />
        </div>
        {!isDashboard && (
          <div
            className="select-checkbox-action-container"
            data-testid="select-checkbox-action-container"
          >
            <div className="number-selected-items" data-testid="selected-items">
              <b>{selectedCandidatesIds.length}</b> Candidates Selected
            </div>
            <div
              className="checkbox-action-buttons-container"
              data-testid="checkbox-action-buttons-container"
            >
              <Button
                type="success"
                onClick={() => onClickCreateList()}
                data-testid="create-list-button"
              >
                <PlusIcon /> Create List
              </Button>
            </div>
          </div>
        )}
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="candidates-table">
            <thead>
              <tr>
                {!isDashboard && (
                  <th className="checkbox-table" data-testid="checkbox-table">
                    <Checkbox
                      checked={
                        !!selectedCandidatesIds.length &&
                        selectedCandidatesIds.length === candidatesList.length
                      }
                      indeterminate={
                        !!selectedCandidatesIds.length &&
                        selectedCandidatesIds.length < candidatesList.length
                      }
                      onChange={(e) => onClickCheckboxAll(e.target.checked)}
                      data-testid="select-all-checkbox"
                    />
                  </th>
                )}
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="sort-name"
                >
                  <div>
                    <label>Name </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                      data-testid="sort-icon-name"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('designation', setSort)}
                  data-testid="sort-job-title"
                >
                  <div>
                    <label>Job title</label>
                    <SortIcon
                      value={sort.key === 'designation' ? sort.value : ''}
                      data-testid="sort-icon-job-title"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('grade', setSort)}
                  data-testid="sort-grade"
                >
                  <div>
                    <label>NHS Pay Grade</label>
                    <SortIcon
                      value={sort.key === 'grade' ? sort.value : ''}
                      data-testid="sort-icon-grade"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user', setSort)}
                  data-testid="sort-line-manager"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user' ? sort.value : ''}
                      data-testid="sort-icon-line-manager"
                    />
                  </div>
                </th>
                {!isDashboard && (
                  <th
                    onClick={() => onSelectSort('county', setSort)}
                    data-testid="sort-county"
                  >
                    <div>
                      <label>County</label>
                      <SortIcon
                        value={sort.key === 'county' ? sort.value : ''}
                        data-testid="sort-icon-county"
                      />
                    </div>
                  </th>
                )}
                {!isDashboard && (
                  <th
                    onClick={() => onSelectSort('region', setSort)}
                    data-testid="sort-region"
                  >
                    <div>
                      <label>Region</label>
                      <SortIcon
                        value={sort.key === 'region' ? sort.value : ''}
                        data-testid="sort-icon-region"
                      />
                    </div>
                  </th>
                )}
                {!isDashboard && (
                  <th
                    onClick={() => onSelectSort('isProfileComplete', setSort)}
                    data-testid="sort-profile"
                  >
                    <div>
                      <label>Profile</label>
                      <SortIcon
                        value={
                          sort.key === 'isProfileComplete' ? sort.value : ''
                        }
                        data-testid="sort-icon-profile"
                      />
                    </div>
                  </th>
                )}
                <th data-testid="empty-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((candidate) => {
                        const isCandidateBelongsToUser =
                          candidate?.recruiter?._id === user?._id ||
                          user?.role === EUserRole.Admin;
                        return (
                          <tr
                            key={candidate._id}
                            data-testid={`candidate-row-${candidate._id}`}
                          >
                            {!isDashboard && (
                              <td
                                className="checkbox-table"
                                data-testid={`checkbox-table-${candidate._id}`}
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    onClickCheckbox(
                                      candidate._id,
                                      e.target.checked,
                                    )
                                  }
                                  checked={selectedCandidatesIds.some(
                                    (el) => el === candidate._id,
                                  )}
                                  data-testid={`candidate-checkbox-${candidate._id}`}
                                />
                              </td>
                            )}
                            <td data-testid={`candidate-name-${candidate._id}`}>
                              <div className="name-item">
                                <UserIcon
                                  firstName={candidate.firstName}
                                  lastName={candidate.lastName}
                                  entity="candidate"
                                  data-testid={`user-icon-${candidate._id}`}
                                />
                                <div>
                                  <div>
                                    {candidate.firstName} {candidate.lastName}
                                  </div>
                                  <div className="email">{candidate.email}</div>
                                </div>
                              </div>
                            </td>
                            <td
                              data-testid={`candidate-job-title-${candidate._id}`}
                            >
                              <div className="name-item">
                                <div>
                                  <div> {candidate?.designation || '-'}</div>
                                  <div className="email">
                                    {candidate.department || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              data-testid={`candidate-grade-${candidate._id}`}
                            >
                              {candidate?.grade || '-'}
                            </td>
                            <td
                              data-testid={`candidate-line-manager-${candidate._id}`}
                            >
                              {candidate?.recruiter?.firstName}{' '}
                              {candidate?.recruiter?.lastName}
                            </td>
                            {!isDashboard && (
                              <td
                                data-testid={`candidate-county-${candidate._id}`}
                              >
                                {candidate.address?.county || '-'}{' '}
                              </td>
                            )}
                            {!isDashboard && (
                              <td
                                data-testid={`candidate-region-${candidate._id}`}
                              >
                                {candidate.address?.region || '-'}{' '}
                              </td>
                            )}
                            {!isDashboard && (
                              <td
                                data-testid={`candidate-profile-${candidate._id}`}
                              >
                                <Badge
                                  type={
                                    candidate.isProfileComplete
                                      ? 'success'
                                      : 'danger'
                                  }
                                  data-testid={`candidate-profile-badge-${candidate._id}`}
                                >
                                  {candidate.isProfileComplete
                                    ? 'Complete'
                                    : 'Incomplete'}
                                </Badge>
                              </td>
                            )}
                            <td
                              data-testid={`candidate-actions-${candidate._id}`}
                            >
                              <div className="action-item">
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(candidate._id)}
                                  data-testid={`view-icon-${candidate._id}`}
                                >
                                  <EyeIcon />
                                </div>

                                {isCRMApp && !isDashboard && (
                                  <div
                                    className={`edit-icon ${
                                      !isCandidateBelongsToUser && 'disabled'
                                    }`}
                                    onClick={() =>
                                      isCandidateBelongsToUser &&
                                      onClickEdit(candidate)
                                    }
                                    data-testid={`edit-icon-${candidate._id}`}
                                  >
                                    <EditIcon />
                                  </div>
                                )}
                                {!isDashboard && (
                                  <div
                                    className={`delete-icon ${
                                      user?.role !== EUserRole.Admin &&
                                      'disabled'
                                    }`}
                                    onClick={() =>
                                      user?.role === EUserRole.Admin &&
                                      setRecordToDelete(candidate)
                                    }
                                    data-testid={`delete-icon-${candidate._id}`}
                                  >
                                    <DeleteIcon />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-item-found">
                      <td colSpan={100} className="text-center">
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={candidatesList.length}
          data-testid="pagination"
        />
      </CardContainer>
      <SideModal
        isOpen={isModalAddCandidateOpen}
        setIsOpen={setIsModalAddCandidateOpen}
        title={'New Candidate'}
        data-testid="add-candidate-modal"
      >
        <NewCandidateForm
          onCancel={() => setIsModalAddCandidateOpen(false)}
          onSuccess={() => {
            getCandidates();
            setIsModalAddCandidateOpen(false);
          }}
          payrollsList={payrollsList}
          usersList={usersList}
          data-testid="new-candidate-form"
        />
      </SideModal>
      <SideModal
        isOpen={!!isModalUpdateCandidateOpen}
        setIsOpen={() => setIsModalUpdateCandidateOpen(undefined)}
        title={'Update Candidate'}
        data-testid="update-candidate-modal"
      >
        <UpdateCandidateForm
          onCancel={() => setIsModalUpdateCandidateOpen(undefined)}
          onSuccess={() => {
            getCandidates();
            setIsModalUpdateCandidateOpen(undefined);
          }}
          payrollsList={payrollsList}
          usersList={usersList}
          selectedCandidate={isModalUpdateCandidateOpen!}
          data-testid="update-candidate-form"
        />
      </SideModal>
      <SideModal
        isOpen={isModalCreateListOpen}
        setIsOpen={setIsModalCreateListOpen}
        title={'Create New List'}
        data-testid="create-list-modal"
      >
        <NewSharedCandidatesList
          onCancel={() => setIsModalCreateListOpen(false)}
          onSuccess={() => {
            getCandidates();
            setIsModalCreateListOpen(false);
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidatesIds={selectedCandidatesIds}
          data-testid="new-shared-candidates-list"
        />
      </SideModal>
      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Candidate"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteCandidate()}
        data-testid="delete-candidate-confirm-modal"
      >
        Do you want to delete{' '}
        <b>
          {recordToDelete?.firstName} {recordToDelete?.lastName}
        </b>
        <div
          style={{
            fontSize: '15px',
            color: '#E6BB20',
          }}
        >
          <span>Note: All Candidate's placements will be removed.</span>
        </div>
      </ConfirmModal>
    </>
  );
};
