import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  HeaderPageContainer,
  MultipleSelect,
  ProgressBar,
  SearchInput,
  SideModal,
  SubTitlePage,
  TitlePage,
  LeadForm,
  LeadContainerPage,
  LeadsList,
  ViewLead,
  ConfirmModal,
} from '../../components';
import { ColumnSwitch, EditIcon, PlusIcon, RowSwitch } from '../../icons';
import { useNavigate } from 'react-router-dom';
import { Client, Lead, Trust, User } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  filterLeads,
  TFilterLead,
} from '../../utils';
import { toast } from 'react-toastify';
import { LeadsPipeline } from '../../components/lead/leads-pipeline';
import { leadStatusesList, leadTagsList, leadTypesList } from '../../constants';

type LeadsListPageProps = {
  isDashboard?: boolean;
  itemsPerPage?: number;
};

export const LeadsListPage = ({}: LeadsListPageProps) => {
  const navigate = useNavigate();

  const [isRowSwitch, setIsRowSwitch] = useState(true);
  const [isFormModalOpen, setIsFormModalOpen] = useState<{
    isOpen: boolean;
    data?: Lead;
  }>({
    isOpen: false,
  });
  const [isViewModalOpen, setIsViewModalOpen] = useState<Lead>();

  const [recordToDelete, setRecordToDelete] = useState<Lead>();

  const [usersList, setUsersList] = useState<User[]>([]);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);

  const [leads, setLeads] = useState<Lead[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<TFilterLead>({
    search: '',
    statuses: [],
    types: [],
    tags: [],
    cpmIds: [],
  });

  const filteredData = useMemo(
    () => filterLeads(leads, filter),
    [leads, filter],
  );

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        setClientsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteLead = async () => {
    await AxiosInstance.leads
      .leadsControllerDeleteById(recordToDelete?._id!)
      .then(() => {
        toast.success('Lead Removed successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setIsViewModalOpen(undefined);
    setRecordToDelete(undefined);
  };

  const getLeads = async () => {
    setIsLoading(true);
    await AxiosInstance.leads
      .leadsControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setLeads(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
    getClients();
    getTrusts();
    getLeads();
  }, []);

  return (
    <>
      <LeadContainerPage data-testid="lead-container-page">
        <CardContainer
          className="target-container"
          data-testid="target-container"
        >
          <div className="left-container" data-testid="kpi-left-container">
            <TitlePage data-testid="kpi-title">KPI Health</TitlePage>
            <SubTitlePage data-testid="kpi-subtitle">Q3</SubTitlePage>
          </div>
          <div className="right-container" data-testid="kpi-right-container">
            <div className="budget-container" data-testid="budget-container-1">
              <div className="title" data-testid="budget-title">
                Budget
              </div>
              <div className="numbers" data-testid="budget-numbers">
                <span className="green">£123,456</span> / £1,000,000
              </div>
              <ProgressBar value={100} data-testid="progress-bar-1" />
            </div>
            <div className="budget-container" data-testid="budget-container-2">
              <div className="title" data-testid="budget-title-2">
                Budget
              </div>
              <div className="numbers" data-testid="budget-numbers-2">
                <span className="green">£123,456</span> / £1,000,000
              </div>
              <ProgressBar value={66} data-testid="progress-bar-2" />
            </div>
            <div className="budget-container" data-testid="edit-icon-container">
              <div
                className="edit-icon"
                onClick={() => {}}
                data-testid="edit-icon"
              >
                <EditIcon />
              </div>
            </div>
          </div>
        </CardContainer>

        <CardContainer
          className="leads-list-container"
          data-testid="leads-list-container"
        >
          <HeaderPageContainer
            className="header-leads-list-container"
            data-testid="header-leads-list-container"
          >
            <div className="left-container" data-testid="leads-left-container">
              <TitlePage data-testid="leads-title">Leads</TitlePage>
              <SubTitlePage data-testid="leads-subtitle">
                Manage your leads
              </SubTitlePage>
            </div>
            <div
              className="right-container"
              data-testid="leads-right-container"
            >
              <Button
                type="primary"
                onClick={() => setIsFormModalOpen({ isOpen: true })}
                data-testid="create-new-lead-button"
              >
                <PlusIcon /> Create New Lead
              </Button>
              {isRowSwitch ? (
                <RowSwitch
                  onClick={() => setIsRowSwitch(false)}
                  data-testid="row-switch"
                />
              ) : (
                <ColumnSwitch
                  onClick={() => setIsRowSwitch(true)}
                  data-testid="column-switch"
                />
              )}
            </div>
          </HeaderPageContainer>
          <div className="filter-container">
            <SearchInput
              placeholder="Search lead"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
              }
            />
            <MultipleSelect
              placeholder="All Status"
              options={leadStatusesList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  statuses: values ?? [],
                }));
              }}
            />
            <MultipleSelect
              placeholder="All Tags"
              options={leadTagsList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  tags: values ?? [],
                }));
              }}
            />
            <MultipleSelect
              placeholder="All CMPs"
              options={usersList.map((el) => ({
                label: el.firstName + ' ' + el.lastName,
                value: el._id,
              }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  cpmIds: values ?? [],
                }));
              }}
            />
            <MultipleSelect
              placeholder="All Types"
              options={leadTypesList.map((el) => ({ label: el, value: el }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  types: values ?? [],
                }));
              }}
            />
          </div>
          {isRowSwitch ? (
            <LeadsList
              leads={filteredData}
              setIsViewModalOpen={setIsViewModalOpen}
              setIsFormModalOpen={setIsFormModalOpen}
              setRecordToDelete={setRecordToDelete}
              isLoading={isLoading}
            />
          ) : (
            <LeadsPipeline
              leads={filteredData}
              getLeads={getLeads}
              setIsViewModalOpen={setIsViewModalOpen}
            />
          )}
        </CardContainer>
      </LeadContainerPage>

      <SideModal
        isOpen={isFormModalOpen.isOpen}
        setIsOpen={() => setIsFormModalOpen({ isOpen: false })}
      >
        <LeadForm
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          setIsFormModalOpen={setIsFormModalOpen}
          selectedLead={isFormModalOpen.data}
          data-testid="lead-form"
        />
      </SideModal>

      <SideModal
        data-testid="view-lead-modal"
        isOpen={!!isViewModalOpen}
        setIsOpen={() => setIsViewModalOpen(undefined)}
        title={'View Lead'}
        actions={[
          {
            label: 'Edit Lead',
            action: () => {
              setIsFormModalOpen({ isOpen: true, data: isViewModalOpen });
              setIsViewModalOpen(undefined);
            },
            'data-testid': 'edit-lead-action',
          },
          {
            label: 'Delete Lead',
            action: () => {
              setRecordToDelete(isViewModalOpen!);
            },
            'data-testid': 'delete-lead-action',
          },
        ]}
      >
        <ViewLead lead={isViewModalOpen!} data-testid="view-lead-component" />
      </SideModal>

      <ConfirmModal
        data-testid="confirm-modal"
        isOpen={!!recordToDelete}
        title="Delete Lead"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteLead()}
      >
        <span data-testid="confirm-modal-message">
          Do you want to delete{' '}
          <b data-testid="record-name">{recordToDelete?.name}</b> ?
        </span>
      </ConfirmModal>
    </>
  );
};
