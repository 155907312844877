import React, { useCallback, useEffect, useState } from 'react';
import {
  DeleteIcon,
  DownloadIcon,
  LeftArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  ATag,
  Badge,
  Button,
  CardContainer,
  DragDropUpload,
  Table,
  TitlePage,
  ValidatePlacementForm,
  ConfirmModal,
  SideModal,
  DocumentsListComponent,
  TabPageContainer,
} from '../../../components';
import { PlacementDetailsContainer } from './placement-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  calculateWeeksDifference,
  formatDate,
  uploadPlacementFiles,
  downloadFileByInvoice,
  downloadFileByTimesheet,
  formatCurrency,
  getStatus,
} from '../../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Application,
  EApplicationApprovalStatus,
  ETimesheetApproval,
  HostedFile,
  Invoice,
  Timesheet,
} from '../../../backend/careo-api';

enum EPlacementDetailsTabs {
  GENERAL = 'General',
  DOCUMENTS = 'Documents',
  TIMESHEETS = 'Timesheets',
  INVOICES = 'Invoices',
}

const tabs = [
  EPlacementDetailsTabs.GENERAL,
  EPlacementDetailsTabs.TIMESHEETS,
  EPlacementDetailsTabs.INVOICES,
  EPlacementDetailsTabs.DOCUMENTS,
];

export const PlacementDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isModalOpen, setIsModalOpen] = useState<Application | null>(null);
  const [selectedTab, setSelectedTab] = useState(EPlacementDetailsTabs.GENERAL);
  const [placement, setPlacement] = useState<Application | null>(null);
  const [timesheetsList, setTimesheetsList] = useState<Timesheet[]>([]);
  const [invoicesList, setInvoices] = useState<Invoice[]>([]);
  const [recordToDelete, setRecordToDelete] = useState<Timesheet>();
  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const deleteTimesheet = useCallback(() => {
    AxiosInstance.timesheets
      .timesheetsControllerDelete(recordToDelete?._id!)
      .then(() => {
        toast.success('Timesheet Deleted Successfully.');
        getTimesheets();
        setRecordToDelete(undefined);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  }, [recordToDelete]);

  const onClickGoBack = () => {
    navigate(-1);
  };

  const getPlacementDetails = () => {
    AxiosInstance.applications
      .applicationsControllerGetApplicationById(id!)
      .then((response) => {
        setPlacement(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getTimesheets = async () => {
    AxiosInstance.timesheets
      .timesheetsControllerFindAll({
        placementId: id!,
      })
      .then((response) => {
        const result = response.data.items;
        setTimesheetsList(result);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getInvoices = async () => {
    AxiosInstance.invoices
      .invoicesControllerFindAll({
        placementId: id!,
      })
      .then((response) => {
        const result = response.data.items;
        setInvoices(result);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getPlacementDocuments = () => {
    AxiosInstance.files
      .filesControllerFindPlacementDocs(id!)
      .then((result) => {
        setDocuments(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadPlacementFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getPlacementDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getPlacementDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    getPlacementDetails();
    getTimesheets();
    getInvoices();
    getPlacementDocuments();

    if (queryParams.get('tab'))
      setSelectedTab(
        (queryParams.get('tab') as EPlacementDetailsTabs) ||
          EPlacementDetailsTabs.GENERAL,
      );
  }, []);

  if (!placement) {
    return <></>;
  }

  return (
    <>
      <PlacementDetailsContainer data-testid="placement-details-container">
        <div className="placement-header" data-testid="placement-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon />
          </div>
          <TitlePage data-testid="placement-title">Detail Placement</TitlePage>
        </div>

        <CardContainer
          className="placement-information-container"
          data-testid="placement-information-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${el === selectedTab && 'active'}`}
                  onClick={() => setSelectedTab(el)}
                  data-testid={`tab-item-${el.toLowerCase()}`}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === EPlacementDetailsTabs.GENERAL && (
                <div className="info-card" data-testid="general-tab-content">
                  <div
                    className="info-card-header"
                    data-testid="info-card-header"
                  >
                    <div
                      className="info-card-title"
                      data-testid="info-card-title"
                    >
                      Placed Candidate
                    </div>
                    {placement.approvalStatus ===
                      EApplicationApprovalStatus.NOT_ACTIVE && (
                      <Button
                        type="primary"
                        onClick={() => setIsModalOpen(placement)}
                        data-testid="validate-button"
                      >
                        Validate
                      </Button>
                    )}
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="info-card-content"
                  >
                    {/* Repeating rows for each info item */}
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-fullname"
                    >
                      <div className="info-item-title col-4">Full Name</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.firstName}{' '}
                        {placement.candidate?.lastName}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-gender"
                    >
                      <div className="info-item-title col-4">Gender</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.gender || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="info-item-dob">
                      <div className="info-item-title col-4">Date of Birth</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.birthDate
                          ? formatDate(placement.candidate?.birthDate)
                          : '-'}
                      </div>
                    </div>
                    {/* Continue for other fields */}
                  </div>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.DOCUMENTS && (
                <div data-testid="documents-tab-content">
                  <div
                    className="info-card"
                    data-testid="personal-documents-card"
                  >
                    <div
                      className="info-card-title"
                      data-testid="personal-documents-title"
                    >
                      Personal Documents
                    </div>
                    <hr />
                    <DocumentsListComponent
                      documents={documents}
                      onClickDelete={onClickDelete}
                      getDocuments={getPlacementDocuments}
                      data-testid="documents-list"
                    />
                  </div>
                  <div
                    className="info-card"
                    data-testid="drag-drop-upload-card"
                  >
                    <DragDropUpload
                      onUpload={handleFileChange}
                      data-testid="drag-drop-upload"
                    />
                  </div>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.TIMESHEETS && (
                <div
                  className="data-table-container"
                  data-testid="timesheets-table-container"
                >
                  <Table data-testid="timesheets-table">
                    <thead>
                      <tr data-testid="timesheets-table-header">
                        <th
                          className="checkbox-table"
                          data-testid="checkbox-column"
                        >
                          <input type="checkbox" />
                        </th>
                        <th data-testid="employee-name-column">
                          <div>
                            <label>Employee Name</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th data-testid="job-title-column">
                          <div>
                            <label>Job Title</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th data-testid="from-column">
                          <div>
                            <label>From</label>
                            <SortIcon />
                          </div>
                        </th>
                        {/* More headers */}
                      </tr>
                    </thead>
                    <tbody data-testid="timesheets-table-body">
                      {timesheetsList.length ? (
                        timesheetsList.map((timesheet) => (
                          <tr
                            key={timesheet._id}
                            data-testid={`timesheet-row-${timesheet._id}`}
                          >
                            <td className="checkbox-table">
                              <input type="checkbox" />
                            </td>
                            {/* Other cells */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={100}
                            className="text-center"
                            data-testid="no-items-found"
                          >
                            No item found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.INVOICES && (
                <div
                  className="data-table-container"
                  data-testid="invoices-table-container"
                >
                  <Table data-testid="invoices-table">
                    <thead>
                      <tr data-testid="invoices-table-header">
                        <th
                          className="checkbox-table"
                          data-testid="checkbox-column"
                        >
                          <input type="checkbox" />
                        </th>
                        {/* Other headers */}
                      </tr>
                    </thead>
                    <tbody data-testid="invoices-table-body">
                      {invoicesList.map((invoice) => (
                        <tr
                          key={invoice.referenceNumber}
                          data-testid={`invoice-row-${invoice.referenceNumber}`}
                        >
                          <td className="checkbox-table">
                            <input type="checkbox" />
                          </td>
                          {/* Other cells */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </PlacementDetailsContainer>

      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Validate Placement"
        data-testid="side-modal"
      >
        <ValidatePlacementForm
          application={isModalOpen as any}
          getPlacementDetails={getPlacementDetails}
          setIsOpen={setIsModalOpen as any}
          data-testid="validate-placement-form"
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Timesheet"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteTimesheet()}
        data-testid="confirm-modal"
      >
        <div data-testid="confirm-modal-message">
          Do you want to delete{' '}
          <b data-testid="timesheet-bold-text">this timesheet</b>?
        </div>
      </ConfirmModal>
    </>
  );
};
