import React, { useMemo, useState } from 'react';
import { ConfirmModal, Table } from '../ui';
import { DeleteIcon, DownloadIcon, EditIcon, SortIcon } from '../../icons';
import {
  TSortDocument,
  TSortValues,
  formatDateTime,
  onSelectSort,
  sortDocuments,
} from '../../utils';
import { SideModal } from '../ui';
import { RenameDocumentForm } from './rename-document-form.component';
import { downloadFile } from '../../utils';
import { HostedFile } from '../../backend/careo-api';

type DocumentsListComponentProps = {
  documents: HostedFile[];
  onClickDelete: (id: string) => void;
  getDocuments: () => void;
  onDocumentRename?: (_id: string, newFileName: string) => void;
};

export const DocumentsListComponent = ({
  documents,
  onClickDelete,
  getDocuments,
  onDocumentRename,
}: DocumentsListComponentProps) => {
  const [isModalOpen, setIsModalOpen] = useState<HostedFile>();
  const [documentIdToBeDelete, setDocumentIdToBeDelete] =
    useState<HostedFile>();

  const [sort, setSort] = useState<{
    key: TSortDocument;
    value: TSortValues;
  }>({
    key: '',
    value: '',
  });

  const sortedDocuments = useMemo(() => {
    return sortDocuments(
      documents.filter((el) => el),
      sort,
    );
  }, [documents, sort]);

  const onClickEdit = (document: HostedFile) => {
    setIsModalOpen(document);
  };

  return (
    <>
      <Table data-testid="document-table">
        <thead>
          <tr>
            <th
              onClick={() => onSelectSort('fileName', setSort)}
              data-testid="sort-file-name"
            >
              <div>
                <label>Document Name</label>
                <SortIcon
                  value={sort.key === 'fileName' ? sort.value : ''}
                  data-testid="sort-icon-file-name"
                />
              </div>
            </th>
            <th
              onClick={() => onSelectSort('timestamp', setSort)}
              data-testid="sort-date"
            >
              <div>
                <label>Date</label>
                <SortIcon
                  value={sort.key === 'timestamp' ? sort.value : ''}
                  data-testid="sort-icon-timestamp"
                />
              </div>
            </th>
            <th className="action-th" data-testid="action-column">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedDocuments.length > 0 ? (
            <>
              {sortedDocuments.map((document) => (
                <tr
                  key={document._id}
                  data-testid={`document-row-${document._id}`}
                >
                  <td>{document.fileName}</td>
                  <td>{formatDateTime(document.timestamp)}</td>
                  <td>
                    <div className="action-item">
                      <div
                        className="download-icon"
                        onClick={() => downloadFile(document)}
                        data-testid={`download-icon-${document._id}`}
                      >
                        <DownloadIcon />
                      </div>
                      <div
                        className="edit-icon"
                        onClick={() => onClickEdit(document)}
                        data-testid={`edit-icon-${document._id}`}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className="delete-icon"
                        onClick={() => setDocumentIdToBeDelete(document)}
                        data-testid={`delete-icon-${document._id}`}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr data-testid="no-items-row">
              <td colSpan={100} className="text-center">
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={() => setIsModalOpen(undefined)}
        title={'Rename document'}
        data-testid="rename-document-modal"
      >
        <RenameDocumentForm
          onCancel={() => setIsModalOpen(undefined)}
          onSuccess={(_id, newFileName) => {
            getDocuments();
            setIsModalOpen(undefined);
            onDocumentRename?.(_id, newFileName);
          }}
          document={isModalOpen!}
          data-testid="rename-document-form"
        />
      </SideModal>

      {documentIdToBeDelete && (
        <ConfirmModal
          isOpen={true}
          title="Delete File"
          onNegativeBtnClick={() => setDocumentIdToBeDelete(undefined)}
          onPositiveBtnClick={() => {
            onClickDelete(documentIdToBeDelete?._id!);
            setDocumentIdToBeDelete(undefined);
          }}
          data-testid="confirm-delete-modal"
        >
          Do you want to delete <b>{documentIdToBeDelete?.fileName}</b>
        </ConfirmModal>
      )}
    </>
  );
};
