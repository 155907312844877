import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Trust, UpdateClientDto, User } from '../../backend/careo-api';
import {
  countriesWithRegions,
  regionsWithCounties,
  titles,
} from '../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  clientSchema,
  handleFormsServerErrors,
} from '../../utils';
import { TitleCard, FieldText, FormContainer, Select, Button } from '../ui';

type UpdateClientFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  clientIdToUpdate: string;
};

export const UpdateClientForm = ({
  onCancel,
  onSuccess,
  clientIdToUpdate,
}: UpdateClientFormProps) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    setError,
    setFocus,
  } = useForm<UpdateClientDto>({
    resolver: yupResolver(clientSchema as any),
  });
  const formValues = watch();

  const [usersList, setUsersList] = useState<User[]>([]);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);

  const getClient = async (id: string) => {
    await AxiosInstance.clients
      .clientsControllerGetClient(id)
      .then((response) => {
        const result = response.data;
        reset({
          ...response,
          userId: result.user?._id,
          trustId: result.trust?._id,
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.clients
      .clientsControllerUpdate(clientIdToUpdate, values)
      .then(() => {
        toast.success('Client updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getClient(clientIdToUpdate);
  }, [clientIdToUpdate]);

  useEffect(() => {
    getUsers();
    getTrusts();
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <Select
        placeholder="Select recruiter"
        label="Recruiter"
        required
        options={[
          ...usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('userId')}
        error={errors.userId}
        control={control}
        data-testid="recruiter-select"
      />
      <TitleCard data-testid="personal-details-title">
        Personal Details
      </TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Client Name"
        required
        register={register('clientName')}
        error={errors.clientName}
        data-testid="client-name-field"
      />
      <Select
        placeholder="Select trust"
        label="Trust"
        options={trustsList.map((el) => ({
          label: el.name,
          value: el._id,
        }))}
        register={register('trustId')}
        error={errors.trustId}
        control={control}
        data-testid="trust-select"
      />
      {/* Address Form */}
      <>
        <TitleCard data-testid="address-title">Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Street Address"
          register={register('address.street')}
          error={errors?.address?.street}
          data-testid="street-address-field"
        />
        <FieldText
          placeholder="Enter here ..."
          label="City"
          register={register('address.city')}
          error={errors?.address?.city}
          data-testid="city-field"
        />
        <div className="row" data-testid="address-row-1">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country')}
              error={errors?.address?.country}
              onChange={() => {
                setValue('address.region', '');
                setValue('address.county', '');
              }}
              control={control}
              data-testid="country-select"
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region', { required: 'true' })}
              error={errors?.address?.region}
              disabled={!formValues.address?.country}
              control={control}
              data-testid="region-select"
            />
          </div>
        </div>
        <div className="row" data-testid="address-row-2">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county', { required: 'true' })}
              error={errors?.address?.county}
              disabled={!formValues.address?.region}
              control={control}
              data-testid="county-select"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              register={register('address.zip')}
              error={errors?.address?.zip}
              data-testid="postcode-field"
            />
          </div>
        </div>
      </>
      {/* Primary Contact Form */}
      <>
        <TitleCard data-testid="primary-contact-title">
          Primary Contact
        </TitleCard>
        <div className="row" data-testid="primary-contact-row-1">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('title')}
              error={errors.title}
              control={control}
              withoutChip
              data-testid="title-select"
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstName')}
              error={errors.firstName}
              data-testid="first-name-field"
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastName')}
              error={errors.lastName}
              data-testid="last-name-field"
            />
          </div>
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          register={register('jobTitle')}
          error={errors.jobTitle}
          data-testid="job-title-field"
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('email')}
          error={errors.email}
          data-testid="email-field"
        />
        <div className="row" data-testid="primary-contact-row-2">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumber')}
              error={errors.phoneNumber}
              data-testid="mobile-phone-field"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Work Phone Number"
              register={register('workPhoneNumber')}
              error={errors.workPhoneNumber}
              data-testid="work-phone-field"
            />
          </div>
        </div>
        <div className="row" data-testid="primary-contact-row-3">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Website"
              register={register('website')}
              error={errors.website}
              data-testid="website-field"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Fax number"
              register={register('faxNumber')}
              error={errors.faxNumber}
              data-testid="fax-number-field"
            />
          </div>
        </div>
      </>
      {/* Bank Details Form */}
      <>
        <TitleCard data-testid="bank-details-title">Bank details</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Bank Name"
          register={register('bank.name')}
          error={errors.bank?.name}
          data-testid="bank-name-field"
        />
        <FieldText
          placeholder="Enter here ..."
          label="Bank Account Name"
          register={register('bank.accountName')}
          error={errors.bank?.accountName}
          data-testid="bank-account-name-field"
        />
        <div className="row" data-testid="bank-details-row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Sort Code"
              register={register('bank.sortNumber')}
              error={errors.bank?.sortNumber}
              data-testid="sort-code-field"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Account Number"
              register={register('bank.accountNumber')}
              error={errors.bank?.accountNumber}
              data-testid="account-number-field"
            />
          </div>
        </div>
      </>
      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
