import React, { useState } from 'react';
import {
  ATag,
  Button,
  FieldText,
  FormContainer,
  MultipleSelect,
  ProgressBar,
  Select,
  SubTitlePage,
  Textarea,
  TabPageContainer,
} from '../ui';
import { DeleteIcon, MinusIcon, PlusIcon, RightArrowIcon } from '../../icons';
import { useForm } from 'react-hook-form';

export const CreateNewProjectForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const formValues = watch();

  const [activities, setActivities] = useState([
    {
      subSpeciality: '',
      activityType: '',
      startDate: '',
      endDate: '',
      contact: '',
      type: '',
      rate: '',
      template: '',
      bcv: '',
      roles: [
        {
          id: 1,
          role: '',
          timings: '',
          ratePerPlacement: '',
          expensesBudget: '',
        },
      ],
    },
  ]);

  const [step, setStep] = useState(1); // Track the current step

  const nextStep = () => {
    setStep((prev) => (prev < 4 ? prev + 1 : prev));
  };

  const prevStep = () => {
    setStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const onSubmit = () => {
    // Handle form submission logic
  };

  const addActivity = () => {
    setActivities((prev) => [
      ...prev,
      {
        subSpeciality: '',
        activityType: '',
        startDate: '',
        endDate: '',
        contact: '',
        type: '',
        rate: '',
        template: '',
        bcv: '',
        roles: [
          {
            id: 1,
            role: '',
            timings: '',
            ratePerPlacement: '',
            expensesBudget: '',
          },
        ],
      },
    ]);
  };

  const removeActivity = (activityIndex: number) => {
    setActivities((prev) => [
      ...prev.slice(0, activityIndex),
      ...prev.slice(activityIndex + 1),
    ]);
  };

  const addRole = (activityIndex: number) => {
    setActivities((prev) =>
      prev.map((activity, idx) =>
        idx === activityIndex
          ? {
              ...activity,
              roles: [
                ...activity.roles,
                {
                  id: Date.now(),
                  role: '',
                  timings: '',
                  ratePerPlacement: '',
                  expensesBudget: '',
                },
              ],
            }
          : activity,
      ),
    );
  };

  return (
    <FormContainer data-testid="form-container">
      <ProgressBar data-testid="progress-bar" value={(step / 4) * 100} />
      <TabPageContainer
        className="form-tab-container"
        data-testid="tab-page-container"
      >
        <div className="tab-items-list" data-testid="tab-items-list">
          <ATag
            className={`tab-item ${step === 1 ? 'active' : ''}`}
            data-testid="tab-general"
          >
            General
          </ATag>
          <ATag
            className={`tab-item ${step === 2 ? 'active' : ''}`}
            data-testid="tab-activities"
          >
            Activities
          </ATag>
          <ATag
            className={`tab-item ${step === 3 ? 'active' : ''}`}
            data-testid="tab-financial"
          >
            Financial
          </ATag>
          <ATag
            className={`tab-item ${step === 4 ? 'active' : ''}`}
            data-testid="tab-legal"
          >
            Legal
          </ATag>
        </div>
      </TabPageContainer>

      {/* Render the form fields based on the current step */}
      {step === 1 && (
        <>
          <div className="row" data-testid="row-client-category">
            <div className="col-md-6" data-testid="col-client">
              <Select
                placeholder="Select client"
                label="Client Name"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                withoutChip
                data-testid="select-client"
              />
            </div>
            <div className="col-md-6" data-testid="col-category">
              <Select
                placeholder="Select category"
                label="Category"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-category"
              />
            </div>
          </div>

          <div className="row" data-testid="row-cpm">
            <div className="col-md-12" data-testid="col-cpm">
              <MultipleSelect
                placeholder="Enter here ..."
                label="CPM"
                options={[
                  { label: 'CPM 1', value: 'cpm1' },
                  { label: 'CPM 2', value: 'cpm2' },
                ]}
                isWithTags
                data-testid="multiple-select-cpm"
              />
            </div>
          </div>

          <div className="row" data-testid="row-service-coordinator">
            <div className="col-md-6" data-testid="col-service-coordinator">
              <Select
                placeholder="Select service coordinator"
                label="Service Coordinator"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-service-coordinator"
              />
            </div>
            <div className="col-md-6" data-testid="col-service-cem">
              <Select
                placeholder="Select CEM"
                label="Service CEM"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-service-cem"
              />
            </div>
          </div>

          <div className="row" data-testid="row-hospital">
            <div className="col-md-12" data-testid="col-hospital">
              <Select
                placeholder="Select Hospital"
                label="Hospital"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-hospital"
              />
            </div>
          </div>
          <div className="row" data-testid="row-region-trust">
            <div className="col-md-6" data-testid="col-region">
              <Select
                placeholder="Select Region"
                label="Region"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-region"
              />
            </div>
            <div className="col-md-6" data-testid="col-trust">
              <Select
                placeholder="Select Trust"
                label="Trust"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-trust"
              />
            </div>
          </div>

          <div className="row" data-testid="row-reporting-instructions">
            <div className="col-md-12" data-testid="col-reporting-instructions">
              <Textarea
                label={'Reporting Instructions'}
                placeholder="Enter here ..."
                rows={3}
                data-testid="textarea-reporting-instructions"
              />
            </div>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div
            className="accordion"
            id="activities-accordion"
            data-testid="activities-accordion"
          >
            {activities.map((activity, activityIndex) => (
              <div
                className="accordion-item primary-item"
                key={activityIndex}
                data-testid={`accordion-item-${activityIndex}`}
              >
                <h2
                  className="accordion-header"
                  id={`activities-accordion-header-${activityIndex}`}
                  data-testid={`accordion-header-${activityIndex}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#activities-accordion-content-${activityIndex}`}
                    aria-expanded="true"
                    aria-controls={`activities-accordion-content-${activityIndex}`}
                    data-testid={`accordion-button-${activityIndex}`}
                  >
                    <b>Activity #{activityIndex + 1}</b>
                  </button>
                </h2>
                <div
                  id={`activities-accordion-content-${activityIndex}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`activities-accordion-header-${activityIndex}`}
                  data-testid={`accordion-content-${activityIndex}`}
                >
                  <div className="accordion-body">
                    {/* Overview Section */}
                    <div
                      className="accordion-item secondary-item"
                      data-testid={`accordion-overview-${activityIndex}`}
                    >
                      <h2
                        className="accordion-header"
                        id={`activities-accordion-overview-header-${activityIndex}`}
                        data-testid={`overview-header-${activityIndex}`}
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#activities-accordion-overview-content-${activityIndex}`}
                          aria-expanded="true"
                          aria-controls={`activities-accordion-overview-content-${activityIndex}`}
                          data-testid={`overview-button-${activityIndex}`}
                        >
                          <b>Overview</b>
                        </button>
                      </h2>
                      <div
                        id={`activities-accordion-overview-content-${activityIndex}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`activities-accordion-overview-header-${activityIndex}`}
                        data-testid={`overview-content-${activityIndex}`}
                      >
                        <div className="accordion-body">
                          <div
                            className="row"
                            data-testid={`overview-row-${activityIndex}`}
                          >
                            <div
                              className="col-md-6"
                              data-testid={`sub-specialty-select-${activityIndex}`}
                            >
                              <Select
                                placeholder=""
                                label="Sub specialty"
                                options={[]}
                              />
                            </div>
                            <div
                              className="col-md-6"
                              data-testid={`activity-type-select-${activityIndex}`}
                            >
                              <Select
                                placeholder=""
                                label="Activity type"
                                options={[
                                  { label: 'Category 1', value: 'category1' },
                                  { label: 'Category 2', value: 'category2' },
                                ]}
                              />
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`dates-row-${activityIndex}`}
                          >
                            <div
                              className="col-md-6"
                              data-testid={`start-date-select-${activityIndex}`}
                            >
                              <Select
                                placeholder=""
                                label="Activity Start Date"
                                options={[]}
                              />
                            </div>
                            <div
                              className="col-md-6"
                              data-testid={`end-date-select-${activityIndex}`}
                            >
                              <Select
                                placeholder=""
                                label="Activity End Date"
                                options={[
                                  { label: 'Category 1', value: 'category1' },
                                  { label: 'Category 2', value: 'category2' },
                                ]}
                              />
                            </div>
                          </div>
                          <Select
                            placeholder=""
                            label="Activity Contact"
                            options={[
                              { label: 'Category 1', value: 'category1' },
                              { label: 'Category 2', value: 'category2' },
                            ]}
                            data-testid={`activity-contact-select-${activityIndex}`}
                          />
                          <div
                            className="row"
                            data-testid={`rate-template-row-${activityIndex}`}
                          >
                            <div
                              className="col-md-3"
                              data-testid={`type-select-${activityIndex}`}
                            >
                              <Select
                                placeholder=""
                                label="Type"
                                options={[]}
                              />
                            </div>
                            <div
                              className="col-md-3"
                              data-testid={`rate-field-${activityIndex}`}
                            >
                              <FieldText label="Rate" />
                            </div>
                            <div
                              className="col-md-3"
                              data-testid={`template-field-${activityIndex}`}
                            >
                              <FieldText label="Template" />
                            </div>
                            <div
                              className="col-md-3"
                              data-testid={`bcv-field-${activityIndex}`}
                            >
                              <FieldText label="BCV" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Roles/Placements Section */}
                    <div
                      className="accordion-item secondary-item"
                      data-testid={`accordion-roles-${activityIndex}`}
                    >
                      <h2
                        className="accordion-header"
                        id={`activities-accordion-roles-header-${activityIndex}`}
                        data-testid={`roles-header-${activityIndex}`}
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#activities-accordion-roles-content-${activityIndex}`}
                          aria-expanded="true"
                          aria-controls={`activities-accordion-roles-content-${activityIndex}`}
                          data-testid={`roles-button-${activityIndex}`}
                        >
                          <b>Roles/Placements</b>
                        </button>
                      </h2>
                      <div
                        id={`activities-accordion-roles-content-${activityIndex}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`activities-accordion-roles-header-${activityIndex}`}
                        data-testid={`roles-content-${activityIndex}`}
                      >
                        <div className="accordion-body">
                          {activity.roles.map((role, roleIndex) => (
                            <div
                              key={`${activityIndex}-${roleIndex}`}
                              data-testid={`role-${activityIndex}-${roleIndex}`}
                            >
                              <div
                                className="row"
                                data-testid={`role-row-${activityIndex}-${roleIndex}`}
                              >
                                <div
                                  className="col-md-6"
                                  data-testid={`role-select-${activityIndex}-${roleIndex}`}
                                >
                                  <Select
                                    placeholder=""
                                    label="Role"
                                    options={[]}
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  data-testid={`timings-select-${activityIndex}-${roleIndex}`}
                                >
                                  <Select
                                    placeholder=""
                                    label="Timings"
                                    options={[
                                      {
                                        label: 'Category 1',
                                        value: 'category1',
                                      },
                                      {
                                        label: 'Category 2',
                                        value: 'category2',
                                      },
                                    ]}
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  data-testid={`empty-select-${activityIndex}-${roleIndex}`}
                                >
                                  <Select
                                    placeholder=""
                                    label="&nbsp;"
                                    options={[
                                      {
                                        label: 'Category 1',
                                        value: 'category1',
                                      },
                                      {
                                        label: 'Category 2',
                                        value: 'category2',
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                data-testid={`role-rate-row-${activityIndex}-${roleIndex}`}
                              >
                                <div
                                  className="col-md-6"
                                  data-testid={`role-rate-select-${activityIndex}-${roleIndex}`}
                                >
                                  <Select
                                    placeholder=""
                                    label="Rate per placement"
                                    options={[]}
                                  />
                                </div>
                                <div
                                  className="col-md-6 d-flex align-items-center justify-content-between"
                                  data-testid={`role-expenses-${activityIndex}-${roleIndex}`}
                                >
                                  <Select
                                    placeholder=""
                                    label="Expenses budget per placement"
                                    options={[
                                      {
                                        label: 'Category 1',
                                        value: 'category1',
                                      },
                                      {
                                        label: 'Category 2',
                                        value: 'category2',
                                      },
                                    ]}
                                  />
                                  <div
                                    className="action-container"
                                    data-testid={`role-delete-${activityIndex}-${roleIndex}`}
                                  >
                                    <div>&nbsp;</div>
                                    <div
                                      className="delete-icon"
                                      onClick={() => {}}
                                      data-testid={`role-delete-icon-${activityIndex}-${roleIndex}`}
                                    >
                                      <DeleteIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}
                          <Button
                            type="success"
                            onClick={() => addRole(activityIndex)}
                            data-testid={`add-role-button-${activityIndex}`}
                          >
                            <PlusIcon /> Add another role
                          </Button>
                        </div>
                      </div>
                    </div>

                    {/* Remove Activity Button */}
                    <Button
                      type="danger"
                      onClick={() => removeActivity(activityIndex)}
                      data-testid={`remove-activity-button-${activityIndex}`}
                    >
                      <MinusIcon /> Remove this activity
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Button
            type="success"
            onClick={() => addActivity()}
            data-testid="add-activity-button"
          >
            <PlusIcon /> Add Another Activity
          </Button>
        </>
      )}

      {step === 3 && (
        <>
          <SubTitlePage data-testid="financials-subtitle">
            Financials
          </SubTitlePage>
          <div className="row">
            <div className="col-md-3">
              <Select
                placeholder="Select Revenue per"
                label="Revenue per"
                options={[
                  { label: 'Category 1', value: 'category1' },
                  { label: 'Category 2', value: 'category2' },
                ]}
                data-testid="select-revenue-per"
              />
            </div>
            <div className="col-md-3">
              <FieldText
                placeholder="Enter here ..."
                label="&nbsp;"
                data-testid="field-revenue"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Cost"
                data-testid="field-cost"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Margin"
                data-testid="field-margin"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Purchase order number"
                data-testid="field-purchase-order"
              />
            </div>
          </div>

          <SubTitlePage data-testid="financials-subtitle-end">
            Financials
          </SubTitlePage>
          <div className="row">
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Contact"
                data-testid="field-contact"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Invoicing To"
                data-testid="field-invoicing-to"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Invoicing CC"
                data-testid="field-invoicing-cc"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Staff Pay Frequency"
                data-testid="field-staff-pay-frequency"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Payment Terms"
                data-testid="field-payment-terms"
              />
            </div>
            <div className="col-md-6">
              <FieldText
                placeholder="Enter here ..."
                label="Invoice Frequency"
                data-testid="field-invoice-frequency"
              />
            </div>
          </div>
        </>
      )}

      {step === 4 && (
        <>
          <FieldText
            placeholder="Enter here ..."
            label="Legal Contact"
            data-testid="field-legal-contact"
          />
          <FieldText
            placeholder="Enter here ..."
            label="Document Name"
            data-testid="field-document-name"
          />
          <Button type="primary" data-testid="button-add-another-document">
            Add Another Document
          </Button>
        </>
      )}

      <div className="form-actions stepper" data-testid="form-actions-stepper">
        <div className="left-container" data-testid="left-container">
          {step < 4 && (
            <Button onClick={() => onSubmit()} data-testid="button-save-exit">
              Save & Exit
            </Button>
          )}
          <Button data-testid="button-cancel">Cancel</Button>
        </div>
        <div className="right-container" data-testid="right-container">
          {step < 4 ? (
            <Button type="primary" onClick={nextStep} data-testid="button-next">
              Next <RightArrowIcon />
            </Button>
          ) : (
            <Button type="success" data-testid="button-create-project">
              Create Project
            </Button>
          )}
        </div>
      </div>
    </FormContainer>
  );
};
