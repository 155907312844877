import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { DonutChart, DonutChartConfig } from '../charts/donut-chart';
import { Container } from './charts.style';
import { chartsColors } from '../../constants/colors';
import { Chevron } from '../../icons';
import { calculateSumBySelectionRoles } from '../../utils/statistics.utils';

type Props = {
  stats: Record<string, Record<string, Record<string, Record<string, number>>>>;
  selectedSpeciality: string;
  setSelectedSpeciality: Dispatch<SetStateAction<string>>;
  isLoading?: boolean;
};

export const DashboardSpecialitiesChart = ({
  stats,
  selectedSpeciality,
  setSelectedSpeciality,
  isLoading,
}: Props) => {
  const [labelsCountToDisplay, setLabelsCountToDisplay] = useState(6);
  const [chartConfig, setChartConfig] = useState<DonutChartConfig>({
    labels: [],
    backgroundColors: [],
    borderColors: [],
    size: 250,
    subtitle: 'Specialities',
    title: 'Specialities',
  });

  const data = useMemo(() => {
    if (!stats) return [];
    const specialities = Object.keys(stats);
    const colors: string[] = [];
    const labels: string[] = [];
    const data: number[] = [];

    specialities
      .map((speciality, index) => {
        const specialityTotal = Object.keys(stats[speciality]).reduce(
          (prev, status) =>
            prev + calculateSumBySelectionRoles(stats[speciality], status),
          0,
        );

        colors.push(chartsColors[index]);

        return { speciality, total: specialityTotal };
      })
      .sort((a, b) => {
        return b.total - a.total;
      })
      .map((entry) => {
        labels.push(entry.speciality);
        data.push(entry.total);
      });

    setChartConfig({
      ...chartConfig,
      labels,
      backgroundColors: colors,
      borderColors: colors,
    });

    return data;
  }, [stats]);

  return (
    <Container data-testid="speciality-container">
      <div className="header" data-testid="speciality-header">
        <span className="title" data-testid="speciality-title">
          Speciality
        </span>
      </div>

      <DonutChart
        config={chartConfig}
        data={data}
        onArcClick={(label, value) => {
          if (selectedSpeciality === label) {
            setSelectedSpeciality?.('');
          } else {
            setSelectedSpeciality?.(label);
          }
        }}
        selectedItem={selectedSpeciality}
        isLoading={isLoading}
        data-testid="donut-chart-speciality"
      />

      <div className="labels-container" data-testid="chart-data-labels">
        {chartConfig.labels.map((label, index) => {
          if (index < labelsCountToDisplay) {
            return (
              <div
                className="label"
                key={label}
                data-testid={`chart-label-${label}`}
              >
                <div>
                  <div
                    style={{
                      backgroundColor: chartConfig.backgroundColors?.[index],
                    }}
                    data-testid={`chart-color-box-${label}`}
                  ></div>
                  <div data-testid={`chart-label-text-${label}`}>{label}</div>
                </div>
                <div data-testid={`chart-value-${label}`}>{data[index]}</div>
              </div>
            );
          }
          return null;
        })}

        {labelsCountToDisplay < chartConfig.labels.length && (
          <div
            className="show-more"
            onClick={() => setLabelsCountToDisplay(labelsCountToDisplay + 5)}
            data-testid="show-more-button"
          >
            <label data-testid="show-more-label">Show More</label>
            <Chevron
              direction="bottom"
              size={25}
              data-testid="show-more-chevron"
            />
          </div>
        )}
      </div>
    </Container>
  );
};
