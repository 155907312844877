import {
  Application,
  Candidate,
  CandidatesList,
  Client,
  ExtendedCandidate,
  HostedFile,
  Job,
  Lead,
  Payroll,
  Trust,
} from '../../backend/careo-api';

export type TSortValues = '+' | '-' | '';
export const onSelectSort = <T>(
  key: T,
  setSort: React.Dispatch<
    React.SetStateAction<{ key: any; value: TSortValues }>
  >,
) => {
  setSort((prev) => {
    let value: '+' | '-' | '' = '';
    if (prev.key === key) {
      if (prev.value === '') {
        value = '+';
      } else if (prev.value === '+') {
        value = '-';
      } else if (prev.value === '-') {
        value = '';
      }
    } else {
      value = '+';
    }
    return { key, value };
  });
};

const sortFunction = (keyA: any, keyB: any, sortValue: '+' | '-') => {
  if (
    typeof keyA !== 'string' &&
    typeof keyA !== 'number' &&
    typeof keyA !== 'boolean'
  ) {
    keyA = '';
  }

  if (
    typeof keyB !== 'string' &&
    typeof keyB !== 'number' &&
    typeof keyA !== 'boolean'
  ) {
    keyB = '';
  }

  if (typeof keyA === 'number' || typeof keyA === 'boolean') {
    keyA = Number(keyA ?? 0);
    keyB = Number(keyB ?? 0);
    if (sortValue === '+') {
      return keyA - keyB;
    } else {
      return keyB - keyA;
    }
  }

  const result = keyA.localeCompare(keyB.toString());
  return sortValue === '+' ? result : -result;
};

export const sortCandidates = (
  candidates: Candidate[] | ExtendedCandidate[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = candidates.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'county' || sort.key === 'region') {
      keyA = a?.address?.[sort.key] ?? '';
      keyB = b?.address?.[sort.key] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export const sortCandidatesLists = (
  candidatesLists: CandidatesList[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = candidatesLists.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    const keyA = (a as any)?.[sort.key];
    const keyB = (b as any)?.[sort.key];

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export const sortClients = (
  clients: Client[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = clients.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'user-firstName') {
      const newKey = sort.key.includes('user-')
        ? (sort.key.split('user-')[1] as any)
        : sort.key;
      keyA = (a?.user as any)?.[newKey] ?? '';
      keyB = (b?.user as any)?.[newKey] ?? '';
    } else if (sort.key === 'region' || sort.key === 'county') {
      keyA = (a?.address as any)?.[sort.key] ?? '';
      keyB = (b?.address as any)?.[sort.key] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export const sortTrusts = (
  clients: Trust[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = clients.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    const keyA = (a as any)?.[sort.key];
    const keyB = (b as any)?.[sort.key];

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export const sortVacancies = (
  jobs: Job[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = jobs.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'client-firstName') {
      const newKey = sort.key.includes('client-')
        ? (sort.key.split('client-')[1] as any)
        : sort.key;
      keyA = (a?.client as any)?.[newKey] ?? '';
      keyB = (b?.client as any)?.[newKey] ?? '';
    } else if (sort.key === 'user-firstName') {
      const newKey = sort.key.includes('user-')
        ? (sort.key.split('user-')[1] as any)
        : sort.key;
      keyA = (a?.user as any)?.[newKey] ?? '';
      keyB = (b?.user as any)?.[newKey] ?? '';
    } else if (sort.key === 'region') {
      keyA = (a?.client as any)?.address?.[sort.key] ?? '';
      keyB = (b?.client as any)?.address?.[sort.key] ?? '';
    } else if (sort.key === 'trust') {
      keyA = (a?.client as any)?.trust?.name ?? '';
      keyB = (b?.client as any)?.trust?.name ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export const sortPlacements = (
  placements: Application[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = placements.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'firstName') {
      keyA = a?.candidate?.[sort.key] ?? '';
      keyB = b?.candidate?.[sort.key] ?? '';
    } else if (sort.key === 'client-firstName' || sort.key === 'clientName') {
      const newKey = sort.key.includes('client-')
        ? (sort.key.split('client-')[1] as any)
        : sort.key;
      keyA = (a?.job?.client as any)?.[newKey] ?? '';
      keyB = (b?.job?.client as any)?.[newKey] ?? '';
    } else if (sort.key === 'grade') {
      keyA = (a?.job as any)?.[sort.key] ?? '';
      keyB = (b?.job as any)?.[sort.key] ?? '';
    } else if (sort.key === 'availableFrom' || sort.key === 'availableTo') {
      keyA = new Date((a as any)?.[sort.key]);
      keyB = new Date((b as any)?.[sort.key]);
      const result = keyA > keyB ? 1 : keyA === keyB ? 0 : -1;
      return sort.value === '+' ? result : -result;
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export const sortPayrolls = (
  payrolls: Payroll[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = payrolls.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'user-firstName') {
      const newKey = sort.key.includes('user-')
        ? (sort.key.split('user-')[1] as any)
        : sort.key;
      keyA = (a?.user as any)?.[newKey] ?? '';
      keyB = (b?.user as any)?.[newKey] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};

export type TSortDocument = 'fileName' | 'timestamp' | '';

export const sortDocuments = (
  documents: HostedFile[],
  sort: { key: TSortDocument; value: '+' | '-' | '' },
) => {
  const { key, value } = sort;

  if (!key || !value || !documents.length) {
    return documents;
  }

  const sortedData = documents.slice().sort((a, b) => {
    let keyA: any, keyB: any;

    switch (key) {
      case 'fileName':
        keyA = a.fileName;
        keyB = b.fileName;
        break;
      case 'timestamp':
        keyA = a.timestamp;
        keyB = b.timestamp;
        break;
      default:
        keyA = '';
        keyB = '';
        break;
    }

    if (typeof keyA !== 'string' && typeof keyA !== 'number') keyA = '';
    if (typeof keyB !== 'string' && typeof keyB !== 'number') keyB = '';

    const result = keyA.localeCompare(keyB, undefined, { numeric: true });

    return value === '+' ? result : -result;
  });

  return sortedData;
};

export const sortLeads = (
  leads: Lead[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = leads.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    // if (sort.key === 'user-firstName') {
    //   const newKey = sort.key.includes('user-')
    //     ? (sort.key.split('user-')[1] as any)
    //     : sort.key;
    //   keyA = (a?.user as any)?.[newKey] ?? '';
    //   keyB = (b?.user as any)?.[newKey] ?? '';
    // } else {
    //   keyA = (a as any)?.[sort.key];
    //   keyB = (b as any)?.[sort.key];
    // }

    return sortFunction(keyA, keyB, sort.value);
  });

  return sortedData;
};
