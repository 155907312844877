import React from 'react';
import { ConfirmModalContainer } from './confirm-modal.style';
import { CancelIcon } from '../../../../icons';
import { Button, TitlePage } from '../../../ui';

type ConfirmModalProps = {
  isOpen: boolean;
  title: string;
  children?: any;
  onPositiveBtnClick?: () => void;
  onNegativeBtnClick?: () => void;
};

export const ConfirmModal = ({
  isOpen,
  title,
  children,
  onPositiveBtnClick = () => {},
  onNegativeBtnClick = () => {},
}: ConfirmModalProps) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <ConfirmModalContainer data-testid="confirm-modal-container">
      <div
        className="confirmation-container"
        data-testid="confirmation-container"
      >
        <div className="confirmation-header" data-testid="confirmation-header">
          <TitlePage data-testid="modal-title">{title}</TitlePage>
          <CancelIcon onClick={onNegativeBtnClick} data-testid="cancel-icon" />
        </div>
        <div className="confirmation-body" data-testid="confirmation-body">
          {children}
        </div>
        <div className="confirmation-footer" data-testid="confirmation-footer">
          <Button
            type="danger"
            onClick={onPositiveBtnClick}
            data-testid="positive-button"
          >
            {title}
          </Button>
          <Button
            type="default"
            onClick={onNegativeBtnClick}
            data-testid="negative-button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </ConfirmModalContainer>
  );
};
