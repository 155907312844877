import React, { useEffect, useState } from 'react';
import { Button, CardContainer, ConfirmModal, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { CampaignTemplate } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { SideModal } from '../ui';
import {
  NewCampaignTemplateForm,
  UpdateCampaignTemplateForm,
} from '../campaign';

export const CampaignTemplates = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [campaignTemplatesList, setCampaignTemplates] = useState<
    CampaignTemplate[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<CampaignTemplate | null>(
    null,
  );

  const [documentIdToBeDelete, setDocumentIdToBeDelete] =
    useState<CampaignTemplate>();

  const onClickDeleteCampaign = async (id: string) => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerDelete(id)
      .then((response) => {
        toast.success('Campaign Template deleted successfully');
        getCampaignTemplates();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCampaignTemplates = async () => {
    setIsLoading(true);
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerFindAll()
      .then((response) => {
        setCampaignTemplates(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getCampaignTemplates();
  }, []);

  return (
    <>
      <CardContainer className="right-container" data-testid="card-container">
        <div
          className="settings-title-content"
          data-testid="settings-title-content"
        >
          <TitlePage>Campaign Templates</TitlePage>
          <Button
            type="primary"
            onClick={() => setIsModalOpen(true)}
            data-testid="add-new-button"
          >
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content" data-testid="settings-content">
          <div
            className="data-table-container"
            data-testid="data-table-container"
          >
            <Table data-testid="campaign-templates-table">
              <thead>
                <tr>
                  <th>
                    <div>
                      <label data-testid="template-name-header">
                        Template Name
                      </label>
                      <SortIcon data-testid="sort-template-name" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="message-header">Message</label>
                      <SortIcon data-testid="sort-message" />
                    </div>
                  </th>
                  <th className="action-th">
                    <div data-testid="action-header">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="loading-row"
                    >
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {campaignTemplatesList.length > 0 ? (
                      <>
                        {campaignTemplatesList.map((campaign) => (
                          <tr
                            key={campaign._id}
                            data-testid={`campaign-row-${campaign._id}`}
                          >
                            <td data-testid={`campaign-name-${campaign._id}`}>
                              {campaign.name}
                            </td>
                            <td
                              data-testid={`campaign-message-${campaign._id}`}
                            >
                              {campaign.message}
                            </td>
                            <td>
                              <div
                                className="action-item"
                                data-testid={`action-item-${campaign._id}`}
                              >
                                <div
                                  className="edit-icon"
                                  onClick={() => setEntityToUpdate(campaign)}
                                  data-testid={`edit-icon-${campaign._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() =>
                                    setDocumentIdToBeDelete(campaign)
                                  }
                                  data-testid={`delete-icon-${campaign._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={100}
                          className="text-center"
                          data-testid="no-item-row"
                        >
                          No item found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New Template'}
      >
        <NewCampaignTemplateForm
          setIsOpen={setIsModalOpen}
          getCampaignTemplates={getCampaignTemplates}
        />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update Template'}
      >
        <UpdateCampaignTemplateForm
          campaignTemplate={entityToUpdate as CampaignTemplate}
          setIsOpen={() => setEntityToUpdate(null)}
          getCampaignTemplates={getCampaignTemplates}
        />
      </SideModal>

      {documentIdToBeDelete && (
        <ConfirmModal
          isOpen={true}
          title="Delete File"
          onNegativeBtnClick={() => setDocumentIdToBeDelete(undefined)}
          onPositiveBtnClick={() => {
            onClickDeleteCampaign(documentIdToBeDelete?._id!);
            setDocumentIdToBeDelete(undefined);
          }}
        >
          Do you want to delete <b>{documentIdToBeDelete?.name}</b>
        </ConfirmModal>
      )}
    </>
  );
};
