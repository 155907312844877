import React, { useState } from 'react';
import { onSelectSort, TSortValues } from '../../utils';
import { Project } from '../../pages/project/projects-list.page';
import { Table } from '../ui';
import { EditIcon, EyeIcon, SortIcon } from '../../icons';

type ProjectDetailsActivitiesProps = {
  project: Project;
};

export const ProjectDetailsActivities = ({
  project,
}: ProjectDetailsActivitiesProps) => {
  const activities = [
    {
      name: 'Dental and Skin (Adults) - Theatre',
    },
    { name: 'SpR Clinic' },
    { name: 'SpR MOS' },
  ];
  const roles: any[] = [{}];

  const [sort, setSort] = useState<{
    key: any;
    value: TSortValues;
  }>({
    key: '',
    value: '',
  });

  return (
    <div
      className="info-card accordion"
      id="activities-accordion"
      data-testid="activities-accordion"
    >
      {activities.map((activity, activityIndex) => (
        <div
          className="accordion-item primary-item"
          key={activityIndex}
          data-testid={`activity-item-${activityIndex}`}
        >
          <h2
            className="accordion-header"
            id={`activities-accordion-header-${activityIndex}`}
            data-testid={`activity-header-${activityIndex}`}
          >
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#activities-accordion-content-${activityIndex}`}
              aria-expanded="true"
              aria-controls={`activities-accordion-content-${activityIndex}`}
              data-testid={`activity-button-${activityIndex}`}
            >
              <b>{activity.name}</b>
            </button>
          </h2>
          <div
            id={`activities-accordion-content-${activityIndex}`}
            className="accordion-collapse collapse show"
            aria-labelledby={`activities-accordion-header-${activityIndex}`}
            data-testid={`activity-content-${activityIndex}`}
          >
            <div className="accordion-body">
              {/* Overview Section */}
              <div
                className="accordion-item secondary-item"
                data-testid={`overview-section-${activityIndex}`}
              >
                <h2
                  className="accordion-header"
                  id={`activities-accordion-overview-header-${activityIndex}`}
                  data-testid={`overview-header-${activityIndex}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#activities-accordion-overview-content-${activityIndex}`}
                    aria-expanded="true"
                    aria-controls={`activities-accordion-overview-content-${activityIndex}`}
                    data-testid={`overview-button-${activityIndex}`}
                  >
                    <b>Overview</b>
                  </button>
                </h2>
                <div
                  id={`activities-accordion-overview-content-${activityIndex}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`activities-accordion-overview-header-${activityIndex}`}
                  data-testid={`overview-content-${activityIndex}`}
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="info-card-content row"
                          data-testid={`overview-info-${activityIndex}`}
                        >
                          <div
                            className="row"
                            data-testid={`client-name-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Client Name
                            </div>
                            <div className="info-item-data col-8">
                              Addenbrookes Hospital
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`category-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Category
                            </div>
                            <div className="info-item-data col-8">OMFS</div>
                          </div>
                          <div
                            className="row"
                            data-testid={`hospital-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Hospital
                            </div>
                            <div className="info-item-data col-8">
                              Addenbrookes Hospital
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`region-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">Region</div>
                            <div className="info-item-data col-8">Midlands</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="info-card-content row"
                          data-testid={`overview-cpm-${activityIndex}`}
                        >
                          <div
                            className="row"
                            data-testid={`cpm-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">CPM</div>
                            <div className="info-item-data col-8">
                              Gary Sheridan
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`service-coordinator-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Service Coordinator
                            </div>
                            <div className="info-item-data col-8">
                              Steve Old
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`cem-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">CEM</div>
                            <div className="info-item-data col-8">
                              Nick Hall
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`reporting-instructions-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Reporting Instructions
                            </div>
                            <div className="info-item-data col-8">
                              Clinical Genetics Department on Level B Callum
                              Edwards, Performance & Operation Manager
                              <br /> Clinical Genetics Ext. 25651
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Rates Section */}
              <div
                className="accordion-item secondary-item"
                data-testid={`rates-section-${activityIndex}`}
              >
                <h2
                  className="accordion-header"
                  id={`activities-accordion-rates-header-${activityIndex}`}
                  data-testid={`rates-header-${activityIndex}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#activities-accordion-rates-content-${activityIndex}`}
                    aria-expanded="true"
                    aria-controls={`activities-accordion-rates-content-${activityIndex}`}
                    data-testid={`rates-button-${activityIndex}`}
                  >
                    <b>Rates</b>
                  </button>
                </h2>
                <div
                  id={`activities-accordion-rates-content-${activityIndex}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`activities-accordion-rates-header-${activityIndex}`}
                  data-testid={`rates-content-${activityIndex}`}
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="info-card-content row"
                          data-testid={`rates-info-${activityIndex}`}
                        >
                          <div
                            className="row"
                            data-testid={`client-name-rates-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Client Name
                            </div>
                            <div className="info-item-data col-8">
                              Addenbrookes Hospital
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`category-rates-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Category
                            </div>
                            <div className="info-item-data col-8">OMFS</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="info-card-content row"
                          data-testid={`rates-cpm-${activityIndex}`}
                        >
                          <div
                            className="row"
                            data-testid={`cpm-rates-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">CPM</div>
                            <div className="info-item-data col-8">
                              Gary Sheridan
                            </div>
                          </div>
                          <div
                            className="row"
                            data-testid={`service-coordinator-rates-${activityIndex}`}
                          >
                            <div className="info-item-title col-4">
                              Service Coordinator
                            </div>
                            <div className="info-item-data col-8">
                              Steve Old
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Roles Section */}
              <div
                className="accordion-item secondary-item"
                data-testid={`roles-section-${activityIndex}`}
              >
                <h2
                  className="accordion-header"
                  id={`activities-accordion-roles-header-${activityIndex}`}
                  data-testid={`roles-header-${activityIndex}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#activities-accordion-roles-content-${activityIndex}`}
                    aria-expanded="true"
                    aria-controls={`activities-accordion-roles-content-${activityIndex}`}
                    data-testid={`roles-button-${activityIndex}`}
                  >
                    <b>Roles</b>
                  </button>
                </h2>
                <div
                  id={`activities-accordion-roles-content-${activityIndex}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`activities-accordion-roles-header-${activityIndex}`}
                  data-testid={`roles-content-${activityIndex}`}
                >
                  <div className="accordion-body">
                    <Table data-testid={`roles-table-${activityIndex}`}>
                      <thead>
                        <tr>
                          <th
                            onClick={() => onSelectSort('fileName', setSort)}
                            data-testid={`role-sort-header`}
                          >
                            <div>
                              <label>Role</label>
                              <SortIcon
                                value={
                                  sort.key === 'fileName' ? sort.value : ''
                                }
                              />
                            </div>
                          </th>
                          <th
                            onClick={() => onSelectSort('timestamp', setSort)}
                            data-testid={`timings-sort-header`}
                          >
                            <div>
                              <label>Timings</label>
                              <SortIcon
                                value={
                                  sort.key === 'timestamp' ? sort.value : ''
                                }
                              />
                            </div>
                          </th>
                          <th
                            onClick={() => onSelectSort('timestamp', setSort)}
                            data-testid={`rate-sort-header`}
                          >
                            <div>
                              <label>Rate</label>
                              <SortIcon
                                value={
                                  sort.key === 'timestamp' ? sort.value : ''
                                }
                              />
                            </div>
                          </th>
                          <th
                            onClick={() => onSelectSort('timestamp', setSort)}
                            data-testid={`expenses-budget-sort-header`}
                          >
                            <div>
                              <label>Expenses Budget</label>
                              <SortIcon
                                value={
                                  sort.key === 'timestamp' ? sort.value : ''
                                }
                              />
                            </div>
                          </th>
                          <th
                            className="action-th"
                            data-testid={`action-column-header`}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {roles.length > 0 ? (
                          <>
                            {roles.map((role) => (
                              <tr
                                key={role._id}
                                data-testid={`role-row-${role._id}`}
                              >
                                <td>Surgeon (CON)</td>
                                <td>01 Mar 2023</td>
                                <td>£1800.00</td>
                                <td>£0.00</td>
                                <td>
                                  <div
                                    className="action-item"
                                    data-testid={`role-action-${role._id}`}
                                  >
                                    <div
                                      className="view-icon"
                                      onClick={() => {}}
                                      data-testid={`view-icon-${role._id}`}
                                    >
                                      <EyeIcon />
                                    </div>
                                    <div
                                      className="edit-icon"
                                      onClick={() => {}}
                                      data-testid={`edit-icon-${role._id}`}
                                    >
                                      <EditIcon />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan={100}
                              className="text-center"
                              data-testid="no-item-found"
                            >
                              No item found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
