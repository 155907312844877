import React, { useState } from 'react';
import { MainContentContainer } from './content.style';
import { Header } from '../header/header.component';
import { Sidebar } from '../sidebar/sidebar.component';
import { Outlet } from 'react-router-dom';
import { PORTALS } from '../../../backend/careo-api';

export const AppLayout = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  return (
    <>
      {process.env.REACT_APP_NAME === PORTALS.Crm && (
        <Sidebar
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
          data-testid="sidebar" // Added test ID for the Sidebar component
        />
      )}

      <MainContentContainer
        isSidebarActive={isSidebarActive}
        data-testid="main-content-container" // Added test ID for MainContentContainer
      >
        <Header
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
          data-testid="header" // Added test ID for the Header component
        />
        <div className="divider" data-testid="divider"></div>{' '}
        {/* Added test ID for divider */}
        <div className="content" data-testid="content">
          {' '}
          {/* Added test ID for content section */}
          <Outlet data-testid="outlet" /> {/* Added test ID for Outlet */}
        </div>
      </MainContentContainer>
    </>
  );
};
