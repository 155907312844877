import React, { useState } from 'react';
import { Select, Button, FieldText, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  CreateApplicationDto,
  DurationDto,
  Job,
} from '../../backend/careo-api';
import {
  AddCandidateVacancySchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
} from '../../utils';
import { toast } from 'react-toastify';
import { applicationStatusList } from '../../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { MinusIcon, PlusIcon } from '../../icons';
import { handleFormsServerErrors } from '../../utils';

type AddCandidateVacancyProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidates: Candidate[];
  job: Job;
};

export const AddCandidateVacancy = ({
  onCancel,
  onSuccess,
  candidates,
  job,
}: AddCandidateVacancyProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateApplicationDto>({
    resolver: yupResolver(AddCandidateVacancySchema as any),
  });

  const [durations, setDurations] = useState<DurationDto[]>([
    {} as DurationDto,
  ]);

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.applications
      .applicationsControllerCreate({
        ...values,
        jobId: job._id,
      })
      .then(() => {
        toast.success('Candidate added to vacancy successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  const onClickAddDuration = () => {
    setDurations((prev) => [...prev, {} as DurationDto]);
  };

  const onClickDeleteDuration = (index: number) => {
    if (durations.length <= 1) {
      return;
    }
    setDurations(() => {
      const tempDurations = getValues('durations');
      tempDurations.splice(index, 1);
      setValue('durations', tempDurations);
      const durationErrors = errors?.durations ?? [];
      durationErrors.splice?.(index, 1);
      setError('durations', durationErrors as any);
      return [...tempDurations];
    });
  };

  return (
    <FormContainer data-testid="form-container">
      <Select
        options={candidates.map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          value: el._id!,
        }))}
        placeholder="Option"
        label="Candidate"
        required
        register={register('candidateId', { required: true })}
        error={errors.candidateId}
        data-testid="select-candidate"
      />
      <Select
        options={applicationStatusList.filter((el) => el.isDraggableTo)}
        placeholder="Option"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
        data-testid="select-status"
      />

      {durations.map((_, index) => (
        <div className="row" key={index} data-testid={`row-duration-${index}`}>
          <div className="col-md-5" data-testid={`col-start-date-${index}`}>
            <FieldText
              placeholder="Enter here ..."
              label="Start Date"
              required
              type="date"
              register={register(`durations.${index}.availableFrom`)}
              error={errors.durations?.[index]?.availableFrom}
              min={job.vacantFrom.split('T')[0]}
              max={job.vacantTo.split('T')[0]}
              data-testid={`field-start-date-${index}`}
            />
          </div>
          <div className="col-md-5" data-testid={`col-end-date-${index}`}>
            <FieldText
              placeholder="Enter here ..."
              label="End Date"
              required
              type="date"
              register={register(`durations.${index}.availableTo`)}
              error={errors.durations?.[index]?.availableTo}
              min={job.vacantFrom.split('T')[0]}
              max={job.vacantTo.split('T')[0]}
              data-testid={`field-end-date-${index}`}
            />
          </div>
          <div
            className="col-md-2 delete-button"
            data-testid={`col-delete-button-${index}`}
          >
            <label htmlFor="" data-testid={`label-delete-${index}`}>
              *
            </label>
            <Button
              type="danger"
              onClick={() => onClickDeleteDuration(index)}
              disabled={durations.length <= 1}
              data-testid={`button-delete-duration-${index}`}
            >
              <MinusIcon data-testid={`minus-icon-${index}`} />
            </Button>
          </div>
        </div>
      ))}

      <div
        className="add-duration-container d-flex justify-content-start"
        data-testid="add-duration-container"
      >
        <Button
          type="success"
          onClick={onClickAddDuration}
          data-testid="button-add-duration"
        >
          <PlusIcon data-testid="plus-icon" /> Add New Duration
        </Button>
      </div>

      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onCancel} data-testid="button-cancel">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="button-create"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
