import React, { useEffect, useMemo, useState } from 'react';
import {
  calculateDaysDifference,
  getCalendarByMonthAndYear,
  isDateInRangeForMonth,
  mergeContiguousAvailability,
} from '../../../utils';
import {
  Availability,
  Candidate,
  EAvailabilityStatus,
  EJobShift,
} from '../../../backend/careo-api';
import { Button, FieldText, Select } from '../../ui';
import { shiftList, monthsList } from '../../../constants';
import {
  ColumnSwitch,
  DownloadIcon,
  PlusIcon,
  RowSwitch,
} from '../../../icons';
import { NewAvailabilityForm } from '../new-availability-form.component';
import { SideModal } from '../../ui';
import AvailabilityCalendar from './availability-calendar.component';
import AvailabilityList from './availability-list.component';

type AvailabilityComponentProps = {
  getAvailabilities: () => void;
  availabilities: Availability[];
};

export interface IGroupedAvailability {
  _id: string;
  from: string;
  to?: string;
  status: EAvailabilityStatus;
  availabilityTime: EJobShift;
  candidate: Candidate;
}

export const AvailabilityComponent = ({
  getAvailabilities,
  availabilities,
}: AvailabilityComponentProps) => {
  const [isRowSwitch, setIsRowSwitch] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [weeksDay, setWeeksDays] = useState<
    {
      day: number;
      label: string;
      week: number;
      month: number;
      year: number;
    }[][]
  >([]);

  const filteredAvailabilities = useMemo(() => {
    return mergeContiguousAvailability(availabilities)
      ?.filter?.((el) => {
        return isDateInRangeForMonth(
          new Date(el.from),
          el.to ? new Date(el.to) : undefined,
          selectedMonth,
          selectedYear,
        );
      })

      .sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime())
      .map((el) => {
        const isOneDay = el.to && el.to !== el.from;
        return {
          _id: el._id,
          from: el.from,
          to: isOneDay ? el.to : undefined,
          availabilityTime: shiftList.find(
            (shift) => shift.value === el.availabilityTime,
          ),
          differenceDays: `${
            isOneDay
              ? calculateDaysDifference(new Date(el.from), new Date(el.to!))
              : 1
          } Day(s)`,
          status: el.status,
        };
      });
  }, [availabilities, selectedMonth, selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedMonth >= 0) {
      setWeeksDays(getCalendarByMonthAndYear(selectedMonth, selectedYear));
    }
  }, [selectedMonth, selectedYear]);

  return (
    <>
      <div>
        <div className="info-card">
          <div className="info-card-title">Current Availability</div>
          <div className="availability-actions-container">
            <Select
              placeholder="Select date"
              options={monthsList}
              className="month-input"
              value={selectedMonth}
              onChange={(value) => setSelectedMonth(Number(value))}
            />
            <FieldText
              className="month-input"
              type="number"
              placeholder="Select Year"
              min={2000}
              onChange={(e) => {
                if (e.target.value.length <= 4)
                  setSelectedYear(Number(e.target.value) ?? 2000);
              }}
              value={selectedYear}
              maxLength={4}
            />
            <Button type="default" disabled>
              <DownloadIcon /> Download CSV
            </Button>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              <PlusIcon /> New Availability
            </Button>
            {isRowSwitch ? (
              <RowSwitch onClick={() => setIsRowSwitch(false)} />
            ) : (
              <ColumnSwitch onClick={() => setIsRowSwitch(true)} />
            )}
          </div>
          <div className="data-table-container">
            {isRowSwitch ? (
              <AvailabilityList
                filteredAvailabilities={filteredAvailabilities}
                getAvailabilities={getAvailabilities}
              />
            ) : (
              <AvailabilityCalendar
                availabilities={availabilities}
                weeksDay={weeksDay}
                getAvailabilities={getAvailabilities}
              />
            )}
          </div>
        </div>
      </div>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Add to Availability"
      >
        <NewAvailabilityForm
          onCancel={() => setIsModalOpen(false)}
          onSuccess={() => {
            getAvailabilities();
            setIsModalOpen(false);
          }}
        />
      </SideModal>
    </>
  );
};
