import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  ConfirmModal,
  HeaderPageContainer,
  NewTrustForm,
  Pagination,
  SearchInput,
  SideModal,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UploadButton,
  UpdateTrustForm,
} from '../../../components';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterTrust,
  filterTrusts,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortTrusts,
  uploadTrustsCsvRequest,
} from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { Trust, EUserRole } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';

const itemsPerPage = 8;

export const TrustsListPage = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const [trust, setTrusts] = useState<Trust[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [recordToDelete, setRecordToDelete] = useState<Trust>();

  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<string>();

  const [filter, setFilter] = useState<TFilterTrust>({
    search: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const uploadTrustsCsv = async (file: File) => {
    await uploadTrustsCsvRequest(file)
      .then(() => {
        getTrusts();
        toast.success('Trusts uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    setIsLoading(true);
    await AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setTrusts(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteTrust = async () => {
    await AxiosInstance.trust
      .trustsControllerDeleteById(recordToDelete?._id!)
      .then(() => {
        toast.success('Trust removed successfully');
        getTrusts();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(trustsList, currentPage, itemsPerPage);
  }, [trustsList, currentPage]);

  useEffect(() => {
    const filteredData = filterTrusts(trust, filter);
    const sortedData = sortTrusts(filteredData, sort);
    setTrustsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [trust, filter, sort]);

  useEffect(() => {
    getTrusts();
  }, []);

  return (
    <>
      <TabFilter
        filters={[
          {
            title: 'Clients',
            url: ERoute.CLIENTS,
          },
          {
            title: 'Trust',
            url: ERoute.TRUSTED_CLIENTS,
          },
        ]}
        data-testid="tab-filter"
      />
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="trusts-title">Trusts</TitlePage>
            <SubTitlePage data-testid="trusts-subtitle">
              Manage your Trust
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={uploadTrustsCsv}
              data-testid="upload-button"
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={() => setIsModalCreateOpen(true)}
              data-testid="add-new-button"
            >
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search trust"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="trusts-table">
            <thead>
              <tr>
                <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('name', setSort)}
                  data-testid="sort-name"
                >
                  <div>
                    <label>Name</label>
                    <SortIcon
                      value={sort.key === 'trustName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('description', setSort)}
                  data-testid="sort-description"
                >
                  <div>
                    <label>Description</label>
                    <SortIcon
                      value={sort.key === 'description' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('createdAt', setSort)}
                  data-testid="sort-created-at"
                >
                  <div>
                    <label>Created At</label>
                    <SortIcon
                      value={sort.key === 'createdAt' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th data-testid="action-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((trust) => {
                        const isTrustBelongsToUser =
                          trust?.user?._id === user?._id ||
                          user?.role === EUserRole.Admin;

                        return (
                          <tr
                            key={trust._id}
                            data-testid={`trust-row-${trust._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid={`checkbox-${trust._id}`}
                            >
                              <input type="checkbox" />
                            </td>
                            <td data-testid={`name-${trust._id}`}>
                              {trust.name}
                            </td>
                            <td data-testid={`description-${trust._id}`}>
                              {trust.description}
                            </td>
                            <td data-testid={`created-at-${trust._id}`}>
                              {formatDate(trust.createdAt ?? '')}
                            </td>
                            <td data-testid={`action-icons-${trust._id}`}>
                              <div className="action-item">
                                <div
                                  className="edit-icon"
                                  onClick={() =>
                                    setIsModalUpdateOpen(trust._id)
                                  }
                                  data-testid={`edit-trust-${trust._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() => setRecordToDelete(trust)}
                                  data-testid={`delete-trust-${trust._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-found"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={trustsList.length}
          data-testid="pagination"
        />
      </CardContainer>

      <SideModal
        isOpen={!!isModalCreateOpen}
        setIsOpen={() => setIsModalCreateOpen(false)}
        title={'New Trust'}
        data-testid="create-trust-modal"
      >
        <NewTrustForm
          onCancel={() => setIsModalCreateOpen(false)}
          onSuccess={() => {
            getTrusts();
            setIsModalCreateOpen(false);
          }}
          data-testid="new-trust-form"
        />
      </SideModal>

      <SideModal
        isOpen={!!isModalUpdateOpen}
        setIsOpen={() => setIsModalUpdateOpen(undefined)}
        title={'Update Trust'}
        data-testid="update-trust-modal"
      >
        <UpdateTrustForm
          onCancel={() => setIsModalUpdateOpen(undefined)}
          onSuccess={() => {
            getTrusts();
            setIsModalUpdateOpen(undefined);
          }}
          trustIdToUpdate={isModalUpdateOpen!}
          data-testid="update-trust-form"
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Trust"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteTrust()}
        data-testid="confirm-delete-modal"
      >
        Do you want to delete <b>{recordToDelete?.name}</b> ?
      </ConfirmModal>
    </>
  );
};
