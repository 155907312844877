import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
// Assuming similar user icon component for lead owners
// Replace with actual icons used in your project
import { formatDate } from '../../../utils'; // Assuming a similar utility function for formatting dates
import { Abbreviation, Badge } from '../badge';
import { breakpoint } from '../../../constants';
import { handleDragStart } from './pipeline';
import { Lead } from '../../../backend/careo-api';
import {
  leadStatusValueType,
  leadTagValueType,
} from '../../lead/view-lead.component';

// Styled Components for the LeadCardPipeline
const LeadCardPipelineContainer = styled.div`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  .top-container {
    .date-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .date {
      font-size: 14px;
      font-weight: 600;
    }
    .owner-icons {
      display: flex;
      gap: 1px;
    }

    .region {
      font-size: 12px;
      color: #a0aec0;
    }
  }

  .status-badges {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .overview {
    font-size: 12px;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 5px;
    .top-container {
      .date-container {
      }
      .date {
        font-size: 8px;
      }

      .region {
        font-size: 8px;
      }
    }

    .status-badges {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .overview {
      font-size: 8px;
    }
  }
`;

type LeadCardPipelineProps = {
  item: Lead;
  listIndex: number;
  itemIndex: number;
  setIsViewModalOpen: Dispatch<SetStateAction<Lead | undefined>>;
};

export const LeadCardPipeline = ({
  item,
  listIndex,
  itemIndex,
  setIsViewModalOpen,
}: LeadCardPipelineProps) => {
  return (
    <LeadCardPipelineContainer
      key={item._id}
      draggable="true"
      onDragStart={(e) => handleDragStart(e, listIndex, itemIndex)}
      onDoubleClick={() => setIsViewModalOpen(item)}
    >
      <div className="top-container">
        <div className="date-container">
          <div className="date">{formatDate(item.createdAt!)}</div>
          <div className="owner-icons">
            {item.cpms.slice(0, 1).map((el) => (
              <Abbreviation title={`${el.firstName} ${el.lastName}`}>
                {el.firstName[0]}
                {el.lastName[0]}
              </Abbreviation>
            ))}
            {item.cpms.length > 1 && (
              <Abbreviation>+{item.cpms.length - 1}</Abbreviation>
            )}
          </div>
        </div>
        <div className="region" data-testid="region">
          {item.region}
        </div>
      </div>
      <div className="status-badges">
        <Badge data-testid="lead-tag" type={leadTagValueType(item.tag)}>
          {item.tag}
        </Badge>
        <Badge
          data-testid="lead-status"
          type={leadStatusValueType(item.status)}
        >
          {item.status}
        </Badge>
      </div>
      <div className="overview" data-testid="overview">
        {item.overview}
      </div>
      <div className="status-badges" data-testid="additional-status-badges">
        <Badge type="neutral" data-testid="trust-hospital">
          Trust/Hospital: {item.trust.name}
        </Badge>
        <Badge type="neutral" data-testid="specialty">
          Specialty: {item.specialty}
        </Badge>
      </div>
    </LeadCardPipelineContainer>
  );
};
