import React from 'react';
import { Address } from '../../backend/careo-api';

type AddressDetailsProps = {
  address?: Address;
};

export const AddressDetails = ({ address }: AddressDetailsProps) => {
  return (
    <div className="info-card" data-testid="info-card">
      <div className="info-card-title" data-testid="info-card-title">
        Address
      </div>
      <hr />
      <div className="info-card-content row" data-testid="info-card-content">
        <div className="row">
          <div className="info-item-title col-2" data-testid="address-label">
            Address
          </div>
          <div className="info-item-data col-10" data-testid="address-data">
            {address?.street || '-'}
          </div>
        </div>
        <div className="row">
          <div className="info-item-title col-2" data-testid="city-label">
            City
          </div>
          <div className="info-item-data col-10" data-testid="city-data">
            {address?.city || '-'}
          </div>
        </div>
        <div className="row">
          <div className="info-item-title col-2" data-testid="county-label">
            County
          </div>
          <div className="info-item-data col-10" data-testid="county-data">
            {address?.county || '-'}
          </div>
        </div>
        <div className="row">
          <div className="info-item-title col-2" data-testid="region-label">
            Region
          </div>
          <div className="info-item-data col-10" data-testid="region-data">
            {address?.region || '-'}
          </div>
        </div>
        <div className="row">
          <div className="info-item-title col-2" data-testid="country-label">
            Country
          </div>
          <div className="info-item-data col-10" data-testid="country-data">
            {address?.country || '-'}
          </div>
        </div>
        <div className="row">
          <div className="info-item-title col-2" data-testid="postcode-label">
            Post Code
          </div>
          <div className="info-item-data col-10" data-testid="postcode-data">
            {address?.zip || '-'}
          </div>
        </div>
      </div>
    </div>
  );
};
