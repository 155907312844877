import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file-list.component';
import { SideModal } from '../../ui';
import { DocumentsListComponent } from '../../document/documents-list.component';

type QualificationsProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'graduateTrainingFiles';

export const Qualifications = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: QualificationsProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] }[]
  >([{ key: 'graduateTrainingFiles', files: [] }]);
  const { getValues, setValue } = useForm<{
    graduateTrainingFiles: any[];
  }>();

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = prev[itemIndex].files.filter(
        (el) => el._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files.push(file);
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'graduateTrainingFiles',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const graduateTrainingFiles =
        compliance?.metadata?.graduateTrainingFiles ?? [];
      setValue('graduateTrainingFiles', graduateTrainingFiles);
      setFilesList([
        { key: 'graduateTrainingFiles', files: graduateTrainingFiles },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      {/* Candidate primary qualification certificate & post graduate training certificates */}
      <TitleCard data-testid="title-card-qualification-certificates">
        Candidate primary qualification certificate & post graduate training
        certificates
      </TitleCard>
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-qualification"
      >
        <div className="col-md-6" data-testid="col-upload-qualification">
          <FieldFile
            label="Upload file"
            onChange={(e) => handleFileChange(e, 'graduateTrainingFiles')}
            data-testid="field-file-qualification"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => setIsModalOpen('graduateTrainingFiles')}
          data-testid="select-qualification-btn"
        >
          <Button type="primary" data-testid="select-qualification-btn-label">
            Select from files
          </Button>
        </div>
      </div>
      <div className="description" data-testid="qualification-description">
        Max file size: 5MB. File format: pdf, docx, png, and jpeg <br />
        Cross reference with CV, Application form, and professional registration
        body (NMC, GMC, HCPC)
      </div>
      <DocumentsListComponent
        documents={
          filesList.find((el) => el.key === 'graduateTrainingFiles')?.files ??
          []
        }
        onClickDelete={(id) => {
          onClickDelete('graduateTrainingFiles', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={() => reFetchCompliance?.()}
        data-testid="documents-list-graduate-training"
      />

      {/* Check Qualifications */}
      <TitleCard data-testid="title-card-check-qualifications">
        Check Qualifications
      </TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="qualificationCheck"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
        data-testid="qualification-check-radio-group"
      />

      {/* File Select Modal */}
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
        data-testid="side-modal-file-select-qualification"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
          data-testid="select-file-list-qualification"
        />
      </SideModal>
    </>
  );
};
