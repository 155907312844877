import React, { useEffect } from 'react';
import {
  Select,
  TitleCard,
  FieldText,
  Button,
  RadioGroup,
  FormContainer,
} from '../ui';
import {
  countriesWithRegions,
  genders,
  nationalities,
  regionsWithCounties,
  titles,
  jobLevelWithGrades,
  specialtiesList,
} from '../../constants';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  Payroll,
  User,
  UpdateCandidateDto,
  EUserRole,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  candidateSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormsServerErrors } from '../../utils';
import { useAuth } from '../../contexts/auth.context';
import { isCRMApp } from '../../environment/app.type';

type UpdateCandidateFormProps = {
  usersList: User[];
  payrollsList: Payroll[];
  onCancel: () => void;
  onSuccess: () => void;
  selectedCandidate: Candidate;
};

export const UpdateCandidateForm = ({
  usersList,
  onCancel,
  onSuccess,
  payrollsList,
  selectedCandidate,
}: UpdateCandidateFormProps) => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<UpdateCandidateDto>({
    resolver: yupResolver(candidateSchema as any),
  });
  const formValues = watch();

  const recruitersList = usersList.filter((el) =>
    [EUserRole.Admin, EUserRole.Recruiter, EUserRole.Manager].includes(
      el.role as EUserRole,
    ),
  );

  const officersList = usersList.filter((el) =>
    [EUserRole.Admin, EUserRole.Officer, EUserRole.Manager].includes(
      el.role as EUserRole,
    ),
  );

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.candidates
      .candidatesControllerUpdate(selectedCandidate._id, values)
      .then(() => {
        toast.success('Candidate updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset({
      ...selectedCandidate,
      recruiterId: selectedCandidate.recruiter?._id,
      officerId: selectedCandidate.officer?._id,
      payrollProviderId: selectedCandidate.payrollProvider?._id,
      birthDate: selectedCandidate.birthDate?.split?.('T')?.[0] ?? '',
    } as UpdateCandidateDto);
  }, [selectedCandidate]);

  return (
    <FormContainer data-testid="form-container">
      {isCRMApp ? (
        <Select
          disabled={!isAdmin}
          placeholder="Select recruiter"
          required
          label={'Recruiter'}
          options={recruitersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          register={register('recruiterId')}
          error={errors.recruiterId}
          control={control}
          data-testid="recruiter-select"
        />
      ) : (
        <Select
          disabled={!isAdmin}
          placeholder="Select Officer"
          required
          label={'Officer'}
          options={officersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          register={register('officerId')}
          error={errors.officerId}
          control={control}
          data-testid="officer-select"
        />
      )}

      <TitleCard data-testid="personal-details-title">
        Personal Details
      </TitleCard>

      <div className="row" data-testid="personal-details-row">
        <div className="col-md-2" data-testid="title-select">
          <Select
            placeholder="Title"
            label="Title"
            required
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title')}
            error={errors.title}
            control={control}
            withoutChip
            data-testid="title-select"
          />
        </div>
        <div className="col-md-5" data-testid="first-name">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName')}
            error={errors.firstName}
            data-testid="first-name-input"
          />
        </div>
        <div className="col-md-5" data-testid="last-name">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName')}
            error={errors.lastName}
            data-testid="last-name-input"
          />
        </div>
      </div>

      <div className="row" data-testid="contact-info-row">
        <div className="col-md-6" data-testid="email">
          <FieldText
            placeholder="Enter here ..."
            label="Email"
            required
            register={register('email')}
            error={errors.email}
            data-testid="email-input"
          />
        </div>
        <div className="col-md-6" data-testid="mobile-phone">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.phoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('phoneNumber')}
            error={errors.phoneNumber}
            data-testid="mobile-phone-input"
          />
        </div>
      </div>

      {/* Continue adding data-testid to each component... */}

      <div className="form-actions" data-testid="form-actions">
        <Button onClick={() => onCancel()} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="submit-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
