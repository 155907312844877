import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  SearchInput,
  SideModal,
  SubTitlePage,
  Table,
  TitlePage,
  HeaderPageContainer,
  UploadButton,
  MultipleSelect,
  Abbreviation,
  ProgressBar,
  Pagination,
  CreateNewProjectForm,
} from '../../components';

import { EditIcon, EyeIcon, PlusIcon, SortIcon } from '../../icons';

import {
  ERoute,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Payroll, User } from '../../backend/careo-api';
import { useAuth } from '../../contexts/auth.context';

type ProjectsListPageProps = {
  isDashboard?: boolean;
  itemsPerPage?: number;
};

export type Project = {
  _id: string;
  name: string;
  specialty: string;
  region: string;
  activities: number;
  initials: string;
  fullName: string;
  progress: number;
  status: 'Active' | 'Draft';
};

export const projectsData: Project[] = [
  {
    _id: '1',
    name: 'Addenbrookes Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 1,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 100,
    status: 'Active',
  },
  {
    _id: '2',
    name: 'Leicester NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 1,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 100,
    status: 'Active',
  },
  {
    _id: '3',
    name: 'Leeds NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 3,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 50,
    status: 'Draft',
  },
  {
    _id: '4',
    name: 'Manchester NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 2,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 70,
    status: 'Draft',
  },
  {
    _id: '5',
    name: 'Bedford NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 0,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 10,
    status: 'Draft',
  },
  {
    _id: '6',
    name: 'Milton Keynes NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 0,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 10,
    status: 'Draft',
  },
  {
    _id: '7',
    name: 'Manchester NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 1,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 70,
    status: 'Draft',
  },
  {
    _id: '8',
    name: 'Manchester NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 0,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 50,
    status: 'Draft',
  },
  {
    _id: '9',
    name: 'Cambridge NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 2,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 60,
    status: 'Draft',
  },
  {
    _id: '10',
    name: 'Birmingham NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 1,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 90,
    status: 'Active',
  },
  {
    _id: '11',
    name: 'Coventry NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 3,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 30,
    status: 'Draft',
  },
  {
    _id: '12',
    name: 'Warwick NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 4,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 40,
    status: 'Draft',
  },
  {
    _id: '13',
    name: 'Nottingham NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 1,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 100,
    status: 'Active',
  },
  {
    _id: '14',
    name: 'Derby NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 2,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 20,
    status: 'Draft',
  },
  {
    _id: '15',
    name: 'Stoke NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 5,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 50,
    status: 'Draft',
  },
  {
    _id: '16',
    name: 'Chesterfield NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 0,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 10,
    status: 'Draft',
  },
  {
    _id: '17',
    name: 'Sheffield NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 2,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 75,
    status: 'Draft',
  },
  {
    _id: '18',
    name: 'Lincoln NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 1,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 60,
    status: 'Draft',
  },
  {
    _id: '19',
    name: 'Peterborough NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 2,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 100,
    status: 'Active',
  },
  {
    _id: '20',
    name: 'Kettering NHS Hospital',
    specialty: 'OMFS',
    region: 'Midlands',
    activities: 3,
    initials: 'GS',
    fullName: 'Gary Sheridan',
    progress: 20,
    status: 'Draft',
  },
  // Continue adding up to 50 items, following a similar pattern.
];

export const ProjectsListPage = ({
  isDashboard = false,
  itemsPerPage = 7,
}: ProjectsListPageProps) => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>(projectsData);
  const [isLoading, setIsLoading] = useState(false);
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalCreateListOpen, setIsModalCreateListOpen] = useState(false);
  const [isModalAddProjectOpen, setIsModalAddProjectOpen] = useState(false);
  const [isModalUpdateProjectOpen, setIsModalUpdateProjectOpen] =
    useState<Project>();
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);

  const [filter, setFilter] = useState<any>({
    search: '',
    teams: [],
    grades: [],
    recruiters: [],
    status: [],
  });

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [designationOptions, setDesignationOptions] = useState<string[]>([]);
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);

  const onClickCheckbox = (id: string, value: boolean) => {
    setSelectedProjectsIds((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const onClickEdit = (project: Project) => {
    setIsModalUpdateProjectOpen(project);
  };

  const onClickCreate = () => {
    setIsModalAddProjectOpen(true);
  };

  const onClickCreateList = () => {
    setIsModalCreateListOpen(true);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PROJECTS}/${id}`);
  };

  const uploadProjectsCsv = async (file: File) => {};

  const deleteProject = async () => {};

  const getPayrolls = async () => {};

  const getUsers = async () => {};

  const getProjects = async () => {};

  const paginatedData = useMemo(() => {
    return getItemsOfPage(projectsList, currentPage, itemsPerPage);
  }, [projectsList, currentPage]);

  useEffect(() => {
    // const filteredData = filterProjects(projects, filter);
    // const sortedData = sortProjects(filteredData, sort);
    setProjectsList(projects);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(projects.length, itemsPerPage));
  }, [projects, filter, sort]);

  useEffect(() => {
    getProjects();
    getPayrolls();
    getUsers();
  }, []);

  return (
    <>
      <CardContainer
        className="project-list-card-container"
        data-testid="project-list-card-container"
      >
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Projects</TitlePage>
            <SubTitlePage data-testid="subtitle-page">
              Manage your Project
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={() => {}}
              data-testid="upload-button"
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-new-button"
            >
              <PlusIcon data-testid="plus-icon" /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search project"
            onChange={(e) =>
              setFilter((prev: any) => ({
                ...prev,
                search: e.target.value ?? '',
              }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Teams"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev: any) => ({
                ...prev,
                departments: values ?? [],
              }));
            }}
            options={[]}
            disabled
            data-testid="multiple-select-teams"
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev: any) => ({
                ...prev,
                departments: values ?? [],
              }));
            }}
            options={[]}
            disabled
            data-testid="multiple-select-grades"
          />
          <MultipleSelect
            placeholder="All Recruiters"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev: any) => ({ ...prev, levels: values ?? [] }));
            }}
            options={[]}
            disabled
            data-testid="multiple-select-recruiters"
          />
          <MultipleSelect
            placeholder="All Status"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev: any) => ({ ...prev, grades: values ?? [] }));
            }}
            options={[]}
            disabled
            data-testid="multiple-select-status"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="table">
            <thead>
              <tr>
                <th
                  className="checkbox-table"
                  data-testid="checkbox-table-header"
                ></th>
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="sort-client"
                >
                  <div>
                    <label>Client </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                      data-testid="sort-icon-client"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('designation', setSort)}
                  data-testid="sort-specialty"
                >
                  <div>
                    <label>Specialty</label>
                    <SortIcon
                      value={sort.key === 'designation' ? sort.value : ''}
                      data-testid="sort-icon-specialty"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('grade', setSort)}
                  data-testid="sort-region"
                >
                  <div>
                    <label>Region</label>
                    <SortIcon
                      value={sort.key === 'grade' ? sort.value : ''}
                      data-testid="sort-icon-region"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user', setSort)}
                  data-testid="sort-activities"
                >
                  <div>
                    <label>Actvities</label>
                    <SortIcon
                      value={sort.key === 'user' ? sort.value : ''}
                      data-testid="sort-icon-activities"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="sort-cpm"
                >
                  <div>
                    <label>CPM</label>
                    <SortIcon
                      value={sort.key === 'county' ? sort.value : ''}
                      data-testid="sort-icon-cpm"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="sort-progress"
                >
                  <div>
                    <label>Progress</label>
                    <SortIcon
                      value={sort.key === 'county' ? sort.value : ''}
                      data-testid="sort-icon-progress"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="sort-status"
                >
                  <div>
                    <label>Status</label>
                    <SortIcon
                      value={sort.key === 'county' ? sort.value : ''}
                      data-testid="sort-icon-status"
                    />
                  </div>
                </th>
                <th data-testid="empty-column"></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((project) => {
                        return (
                          <tr
                            key={project._id}
                            data-testid={`project-row-${project._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid="checkbox-table-row"
                            >
                              <input type="checkbox" />
                            </td>
                            <td data-testid={`project-name-${project._id}`}>
                              {project.name}
                            </td>
                            <td
                              data-testid={`project-specialty-${project._id}`}
                            >
                              {project.specialty}
                            </td>
                            <td data-testid={`project-region-${project._id}`}>
                              {project.region}
                            </td>
                            <td
                              data-testid={`project-activities-${project._id}`}
                            >
                              {project.activities}
                            </td>
                            <td data-testid={`project-cpm-${project._id}`}>
                              <Badge type="neutral">
                                <Abbreviation>{project.initials}</Abbreviation>
                                {project.fullName}
                              </Badge>
                            </td>
                            <td data-testid={`project-progress-${project._id}`}>
                              <ProgressBar value={project.progress} />
                            </td>
                            <td data-testid={`project-status-${project._id}`}>
                              <Badge
                                type={
                                  project.status === 'Active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {project.status}
                              </Badge>
                            </td>
                            <td data-testid={`project-actions-${project._id}`}>
                              <div
                                className="action-item"
                                data-testid="action-item"
                              >
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(project._id)}
                                  data-testid={`view-icon-${project._id}`}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className={`edit-icon`}
                                  onClick={() => {}}
                                  data-testid={`edit-icon-${project._id}`}
                                >
                                  <EditIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-item-row">
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-item-text"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={projectsList.length}
          data-testid="pagination"
        />
      </CardContainer>
      <SideModal
        isOpen={isModalAddProjectOpen}
        setIsOpen={setIsModalAddProjectOpen}
        title={'New Project'}
        data-testid="new-project-modal"
      >
        <CreateNewProjectForm data-testid="create-new-project-form" />
      </SideModal>
      <SideModal
        isOpen={!!isModalUpdateProjectOpen}
        setIsOpen={() => setIsModalUpdateProjectOpen(undefined)}
        title={'Update Project'}
        data-testid="update-project-modal"
      >
        <></>
        {/* <UpdateProjectForm
        onCancel={() => setIsModalUpdateProjectOpen(undefined)}
        onSuccess={() => {
          getProjects();
          setIsModalUpdateProjectOpen(undefined);
        }}
        payrollsList={payrollsList}
        usersList={usersList}
        selectedProject={isModalUpdateProjectOpen!}
      /> */}
      </SideModal>
      <SideModal
        isOpen={isModalCreateListOpen}
        setIsOpen={setIsModalCreateListOpen}
        title={'Create New List'}
        data-testid="create-new-list-modal"
      >
        <></>
        {/* <NewSharedProjectsList
        onCancel={() => setIsModalCreateListOpen(false)}
        onSuccess={() => {
          getProjects();
          setIsModalCreateListOpen(false);
        }}
        projects={projects}
        users={usersList}
        selectedProjectsIds={selectedProjectsIds}
      /> */}
      </SideModal>
    </>
  );
};
