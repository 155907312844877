import React from 'react';
import { AddressDetails } from '../address/address-details.component';
import { Project } from '../../pages/project/projects-list.page';

type ProjectDetailsGeneralProps = {
  project: Project;
};

export const ProjectDetailsGeneral = ({
  project,
}: ProjectDetailsGeneralProps) => {
  return (
    <>
      <div className="info-card" data-testid="personal-info-card">
        <div className="info-card-title" data-testid="personal-info-title">
          Personal Info
        </div>
        <hr />
        <div className="row" data-testid="personal-info-row">
          <div className="col-md-6" data-testid="personal-info-col-left">
            <div
              className="info-card-content row"
              data-testid="personal-info-left"
            >
              <div className="row" data-testid="client-name">
                <div
                  className="info-item-title col-4"
                  data-testid="client-name-title"
                >
                  Client Name
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="client-name-data"
                >
                  Addenbrookes Hospital
                </div>
              </div>
              <div className="row" data-testid="category">
                <div
                  className="info-item-title col-4"
                  data-testid="category-title"
                >
                  Category
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="category-data"
                >
                  OMFS
                </div>
              </div>
              <div className="row" data-testid="hospital">
                <div
                  className="info-item-title col-4"
                  data-testid="hospital-title"
                >
                  Hospital
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="hospital-data"
                >
                  Addenbrookes Hospital
                </div>
              </div>
              <div className="row" data-testid="region">
                <div
                  className="info-item-title col-4"
                  data-testid="region-title"
                >
                  Region
                </div>
                <div className="info-item-data col-8" data-testid="region-data">
                  Midlands
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" data-testid="personal-info-col-right">
            <div
              className="info-card-content row"
              data-testid="personal-info-right"
            >
              <div className="row" data-testid="cpm">
                <div className="info-item-title col-4" data-testid="cpm-title">
                  CPM
                </div>
                <div className="info-item-data col-8" data-testid="cpm-data">
                  Gary Sheridan
                </div>
              </div>
              <div className="row" data-testid="service-coordinator">
                <div
                  className="info-item-title col-4"
                  data-testid="service-coordinator-title"
                >
                  Service Coordinator
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="service-coordinator-data"
                >
                  Steve Old
                </div>
              </div>
              <div className="row" data-testid="cem">
                <div className="info-item-title col-4" data-testid="cem-title">
                  CEM
                </div>
                <div className="info-item-data col-8" data-testid="cem-data">
                  Nick Hall
                </div>
              </div>
              <div className="row" data-testid="reporting-instructions">
                <div
                  className="info-item-title col-4"
                  data-testid="reporting-instructions-title"
                >
                  Reporting Instructions
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="reporting-instructions-data"
                >
                  Clinical Genetics Department on Level B Callum Edwards,
                  Performance & Operation Manager
                  <br /> Clinical Genetics Ext. 25651
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddressDetails
        address={{
          street: "Addenbrooke's Hospital",
          city: 'Cambridge',
          county: 'Cambridgeshire',
          region: 'East of England',
          country: 'United Kingdom',
          zip: 'CB2 0QQ',
        }}
        data-testid="address-details"
      />
    </>
  );
};
