import React from 'react';
import { Project } from '../../pages/project/projects-list.page';
import { RevenueChart } from './charts/revenue-chart';

type ProjectDetailsFinancialProps = {
  project: Project;
};

export const ProjectDetailsFinancial = ({
  project,
}: ProjectDetailsFinancialProps) => {
  return (
    <>
      <div className="info-card" data-testid="financials-card">
        <div className="info-card-title" data-testid="financials-title">
          Financials
        </div>
        <hr />
        <div className="info-card-content" data-testid="financials-content">
          <div className="row">
            <div className="col-lg-4" data-testid="revenue-chart">
              <RevenueChart
                isLoading={false}
                chartData={[
                  {
                    label: 'Revenue',
                    backgroundColor: '#11af60',
                    borderColor: '#11af60',
                    value: 69900,
                    percentage: 6.99,
                  },
                ]}
                data-testid="revenue-chart-component"
              />
            </div>
            <div className="col-lg-4" data-testid="cost-chart">
              <RevenueChart
                isLoading={false}
                chartData={[
                  {
                    label: 'Cost',
                    backgroundColor: '#FFAE00',
                    borderColor: '#FFAE00',
                    value: 52800,
                    percentage: 5.28,
                  },
                ]}
                data-testid="cost-chart-component"
              />
            </div>
            <div className="col-lg-4" data-testid="margin-chart">
              <RevenueChart
                isLoading={false}
                chartData={[
                  {
                    label: 'Margin',
                    backgroundColor: '#8C62FF',
                    borderColor: '#8C62FF',
                    value: 17100,
                    percentage: 24.4,
                  },
                ]}
                data-testid="margin-chart-component"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="info-card" data-testid="invoicing-card">
        <div className="info-card-title" data-testid="invoicing-title">
          Invoicing
        </div>
        <hr />
        <div className="row" data-testid="invoicing-row">
          <div className="col-md-6" data-testid="client-info-col">
            <div className="info-card-content row" data-testid="client-info">
              <div className="row" data-testid="client-name">
                <div
                  className="info-item-title col-4"
                  data-testid="client-name-title"
                >
                  Client Name
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="client-name-data"
                >
                  Addenbrookes Hospital
                </div>
              </div>
              <div className="row" data-testid="category">
                <div
                  className="info-item-title col-4"
                  data-testid="category-title"
                >
                  Category
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="category-data"
                >
                  OMFS
                </div>
              </div>
              <div className="row" data-testid="hospital">
                <div
                  className="info-item-title col-4"
                  data-testid="hospital-title"
                >
                  Hospital
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="hospital-data"
                >
                  Addenbrookes Hospital
                </div>
              </div>
              <div className="row" data-testid="region">
                <div
                  className="info-item-title col-4"
                  data-testid="region-title"
                >
                  Region
                </div>
                <div className="info-item-data col-8" data-testid="region-data">
                  Midlands
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" data-testid="service-info-col">
            <div className="info-card-content row" data-testid="service-info">
              <div className="row" data-testid="cpm">
                <div className="info-item-title col-4" data-testid="cpm-title">
                  CPM
                </div>
                <div className="info-item-data col-8" data-testid="cpm-data">
                  Gary Sheridan
                </div>
              </div>
              <div className="row" data-testid="service-coordinator">
                <div
                  className="info-item-title col-4"
                  data-testid="service-coordinator-title"
                >
                  Service Coordinator
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="service-coordinator-data"
                >
                  Steve Old
                </div>
              </div>
              <div className="row" data-testid="cem">
                <div className="info-item-title col-4" data-testid="cem-title">
                  CEM
                </div>
                <div className="info-item-data col-8" data-testid="cem-data">
                  Nick Hall
                </div>
              </div>
              <div className="row" data-testid="reporting-instructions">
                <div
                  className="info-item-title col-4"
                  data-testid="reporting-instructions-title"
                >
                  Reporting Instructions
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="reporting-instructions-data"
                >
                  Clinical Genetics Department on Level B Callum Edwards,
                  Performance & Operation Manager
                  <br /> Clinical Genetics Ext. 25651
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
