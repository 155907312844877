import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Client,
  CreateGeneralLeadDto,
  Lead,
  Trust,
  User,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { generalLeadSchema } from '../../../utils/validators/leads.validator';
import { useForm } from 'react-hook-form';
import { Button, FieldText, MultipleSelect, Select, Textarea } from '../../ui';
import {
  leadDurationList,
  leadStatusesList,
  leadTagsList,
  leadTypesList,
  monthsList,
  regionsWithCounties,
  specialtiesList,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import { RightArrowIcon } from '../../../icons';

type GeneralFormStepProps = {
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdLead: Lead | undefined;
  setCreatedLead: Dispatch<SetStateAction<Lead | undefined>>;
  setIsFormModalOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; data?: Lead }>
  >;
};

export const GeneralFormStep = ({
  usersList,
  trustsList,
  clientsList,
  step,
  setStep,
  createdLead,
  setCreatedLead,
  setIsFormModalOpen,
}: GeneralFormStepProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,
  } = useForm<CreateGeneralLeadDto>({
    resolver: yupResolver(generalLeadSchema),
  });

  const onSubmitGeneralLead = async (isWithExist = false) => {
    const values = getValues();

    const requestDto = {
      ...values,
      startingMonth: Number(values.startingMonth),
      durationValue: Number(values.durationValue),
    };

    const leadRequest = () =>
      createdLead
        ? AxiosInstance.leads.leadsControllerUpdateById(
            createdLead._id,
            requestDto,
          )
        : AxiosInstance.leads.leadsControllerCreate(requestDto);

    await leadRequest()
      .then((response) => {
        setCreatedLead(response.data);
        if (isWithExist) {
          setIsFormModalOpen({ isOpen: false });
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('General Lead saved successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (createdLead) {
      reset({
        ...createdLead,
        cpmIds: createdLead.cpms.map((el) => el._id),
        hospitalId: createdLead.hospital._id,
        trustId: createdLead.trust._id,
      });
    }
  }, [createdLead]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <FieldText
            placeholder="Enter here ..."
            label="Name"
            required
            register={register('name')}
            error={errors.name}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Status"
            required
            options={leadStatusesList.map((value) => ({
              label: value,
              value: value,
            }))}
            register={register('status')}
            control={control}
            error={errors.status}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Tag"
            required
            options={leadTagsList.map((value) => ({
              label: value,
              value: value,
            }))}
            register={register('tag')}
            control={control}
            error={errors.tag}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Type"
            required
            options={leadTypesList.map((value) => ({
              label: value,
              value: value,
            }))}
            register={register('type')}
            control={control}
            error={errors.type}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Strength"
            required
            options={[]}
          />
        </div>
        <div className="col-12">
          <MultipleSelect
            placeholder="Enter here ..."
            label="Select CPM(s)"
            options={usersList.map((el) => ({
              value: el._id,
              label: el.firstName + ' ' + el.lastName,
            }))}
            register={register('cpmIds')}
            control={control}
            required
            error={errors.cpmIds as any}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Region"
            options={regionsWithCounties.map((el) => ({
              value: el.region,
              label: el.region,
            }))}
            register={register('region')}
            control={control}
            required
            error={errors.region}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Trust"
            options={trustsList.map((el) => ({
              value: el._id,
              label: el.name,
            }))}
            register={register('trustId')}
            control={control}
            required
            error={errors.trustId}
          />
        </div>
        <div className="col-12">
          <Select
            placeholder="Enter here ..."
            label="Specialty"
            options={specialtiesList.map((el) => ({ label: el, value: el }))}
            register={register('specialty')}
            control={control}
            required
            error={errors.specialty}
          />
        </div>
        <div className="col-12">
          <Select
            placeholder="Enter here ..."
            label="Hospital"
            options={clientsList.map((el) => ({
              label: el.firstName + ' ' + el.lastName,
              value: el._id,
            }))}
            register={register('hospitalId')}
            control={control}
            required
            error={errors.hospitalId}
          />
        </div>
        <div className="col-12">
          <Textarea
            placeholder="Enter here ..."
            label="Overview"
            required
            register={register('overview')}
            error={errors.overview}
          />
        </div>
        <div className="col-12">
          <FieldText
            placeholder="Enter here ..."
            label="Resource Requirement"
            required
            register={register('resourceRequirement')}
            error={errors.resourceRequirement}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Starting Month"
            options={monthsList.map((el) => ({
              label: el.label,
              value: el.value,
            }))}
            register={register('startingMonth')}
            control={control}
            required
            error={errors.startingMonth}
          />
        </div>
        <div className="col-md-2">
          <FieldText
            placeholder="Enter here ..."
            label="Duration"
            required
            register={register('durationValue')}
            error={errors.durationValue}
            type="number"
          />
        </div>
        <div className="col-md-4">
          <Select
            placeholder="Enter here ..."
            label="&nbsp;"
            options={leadDurationList.map((el) => ({
              label: el,
              value: el,
            }))}
            register={register('durationType')}
            control={control}
            error={errors.durationType}
          />
        </div>
      </div>

      <div className="form-actions stepper" data-testid="form-actions">
        <div className="left-container">
          <Button
            onClick={handleSubmit(() => onSubmitGeneralLead(true))}
            type="primary"
            variant="outlined"
            data-testid="save-exit-button" // Added test ID for Save & Exit button
          >
            Save & Exit
          </Button>
          <Button
            type="danger"
            variant="outlined"
            onClick={() => setIsFormModalOpen({ isOpen: false })}
            data-testid="cancel-button"
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            onClick={handleSubmit(() => onSubmitGeneralLead(false))}
            data-testid="next-button" // Added test ID for Next button
          >
            Next <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};
