import React, { useState } from 'react';
import { LoginContainer } from './login.style';
import { AppIcon } from '../../icons';
import { Link, useParams } from 'react-router-dom';
import { ResetPasswordDto } from '../../backend/careo-api';
import { useForm } from 'react-hook-form';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ResetPasswordSchema,
} from '../../utils';
import { Button, FieldText, TitlePage } from '../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

interface ResetPasswordDtoUI extends ResetPasswordDto {
  confirmPassword: string;
}

export const ResetPasswordPage = () => {
  const { token } = useParams();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordDtoUI>({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSent, setIsSent] = useState(false);

  const onClickResetPassword = async () => {
    const values = getValues();
    await AxiosInstance.auth
      .authControllerResetPassword(
        { token: token ?? '' },
        { password: values.password },
      )
      .then((response) => {
        toast.success(
          'Your password has been successfully reset. You can now log in with your new password.',
        );
        setIsSent(true);
      })
      .catch((e: AxiosInstanceErrorResponse) => {
        if (e.status === 401) {
          setErrorMessage('Wrong Token please check your credentials');
          setError('password', { message: '' });
          setError('confirmPassword', { message: '' });
        }
        if (e.status === 400) {
          setErrorMessage('Wrong Token please check your credentials');
          setError('password', { message: '' });
          setError('confirmPassword', { message: '' });
        }
      });
  };

  return (
    <LoginContainer data-testid="login-container">
      <div className="left-container" data-testid="left-container">
        <div className="image-container" data-testid="image-container"></div>
        <div className="bottom-container" data-testid="bottom-container">
          <AppIcon isWhiteColor data-testid="app-icon" />
          <div className="title" data-testid="login-title">
            Let’s empower your <br /> candidates today.
          </div>
          <div className="subtitle" data-testid="login-subtitle">
            We help to complete all your conveyancing needs easily
          </div>
        </div>
      </div>
      <div className="right-container" data-testid="right-container">
        <div className="top-container" data-testid="top-container"></div>
        <div className="content-container" data-testid="content-container">
          <TitlePage className="text-center" data-testid="title-page">
            Reset your account
          </TitlePage>
          <br />
          <br />
          <br />
          <br />
          <div className="email-container" data-testid="password-container">
            <div
              className="input-container"
              data-testid="password-input-container"
            >
              <FieldText
                placeholder="Enter your password"
                type="password"
                label="Password"
                register={register('password', { required: true })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit(onClickResetPassword)();
                }}
                error={errors.password}
                data-testid="password-input"
              />
            </div>
          </div>
          <div
            className="email-container"
            data-testid="confirm-password-container"
          >
            <div
              className="input-container"
              data-testid="confirm-password-input-container"
            >
              <FieldText
                placeholder="Confirm your password"
                type="password"
                label="Confirm Password"
                register={register('confirmPassword', { required: true })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit(onClickResetPassword)();
                }}
                error={errors.confirmPassword}
                data-testid="confirm-password-input"
              />
            </div>
          </div>
          {errorMessage && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}
              data-testid="error-message"
            >
              {errorMessage}
            </span>
          )}
          {isSent ? (
            <div
              className="register-option"
              data-testid="reset-success-message"
            >
              Your password has been successfully reset. You can now log in with
              your new password. <br />
              Click{' '}
              <Link to={'/'} data-testid="login-link">
                Here
              </Link>{' '}
              to login.
            </div>
          ) : (
            <Button
              type="primary"
              onClick={handleSubmit(onClickResetPassword)}
              data-testid="reset-password-button"
            >
              Reset password
            </Button>
          )}
        </div>
        <div className="bottom-container" data-testid="bottom-footer-container">
          <div className="copyright" data-testid="copyright">
            © 2024 Careo. All rights reserved.
          </div>
          <Link to={''} data-testid="terms-conditions-link">
            Terms & Conditions
          </Link>
          <a
            href={'https://www.careo.ai/privacy-policy'}
            target="_blank"
            data-testid="privacy-policy-link"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </LoginContainer>
  );
};
