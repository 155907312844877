import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UpdateTrustDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  trustSchema,
  handleFormsServerErrors,
} from '../../utils';
import { FieldText, FormContainer, Button } from '../ui';

type UpdateTrustFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  trustIdToUpdate: string;
};

export const UpdateTrustForm = ({
  onCancel,
  onSuccess,
  trustIdToUpdate,
}: UpdateTrustFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<UpdateTrustDto>({
    resolver: yupResolver(trustSchema as any),
  });

  const getTrust = async () => {
    await AxiosInstance.trust
      .trustsControllerFindById(trustIdToUpdate)
      .then((response) => {
        reset({
          ...response.data,
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.trust
      .trustsControllerUpdateById(trustIdToUpdate, values)
      .then(() => {
        toast.success('Trust updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getTrust();
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name')}
        error={errors.name}
        data-testid="name-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Description"
        register={register('description')}
        error={errors.description}
        data-testid="description-field"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
