import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '../../ui';
import { NewNotificationsListContainer } from './notifications-list.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  getTimeAgo,
} from '../../../utils';
import { toast } from 'react-toastify';
import { NewSmallNotificationRedIcon } from '../../../icons';
import { TNotificationUI } from '../../../utils/notifcations.utils';

type NewNotificationsListProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  notifications: TNotificationUI[];
  onClickNotificationItem: (notification: TNotificationUI) => void;
  getNotifications: any;
};

export const NewNotificationsList = ({
  setIsOpen,
  notifications,
  onClickNotificationItem,
  getNotifications,
}: NewNotificationsListProps) => {
  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickClear = async () => {
    await AxiosInstance.notifications
      .notificationsControllerHideNotifications({
        ids: notifications.map((el) => el._id),
      })
      .then(() => {
        getNotifications();
        setIsOpen(false);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <NewNotificationsListContainer data-testid="notifications-list-container">
      <div
        className="notification-content-list"
        data-testid="notification-content-list"
      >
        {notifications.map((el) => {
          return (
            <div
              className="notification-item"
              onClick={() => onClickNotificationItem(el)}
              key={el._id}
              data-testid={`notification-item-${el._id}`}
            >
              <div
                className="notification-item-top"
                data-testid={`notification-item-top-${el._id}`}
              >
                <div
                  className="notification-title"
                  data-testid={`notification-title-${el._id}`}
                >
                  {el?.title}
                </div>
                <div
                  className="notification-time"
                  data-testid={`notification-time-${el._id}`}
                >
                  {getTimeAgo(el.createdDate)}
                </div>
              </div>
              <div
                className="notification-item-bottom"
                data-testid={`notification-item-bottom-${el._id}`}
              >
                <div
                  className="notification-description"
                  data-testid={`notification-description-${el._id}`}
                >
                  {el?.description}
                </div>
                {el.isNew && (
                  <NewSmallNotificationRedIcon
                    data-testid={`new-small-notification-${el._id}`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="notification-actions" data-testid="notification-actions">
        <Button onClick={onClickClose} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          onClick={onClickClear}
          type="primary"
          data-testid="clear-all-button"
        >
          Clear All
        </Button>
      </div>
    </NewNotificationsListContainer>
  );
};
