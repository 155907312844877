import React from 'react';
import { Project } from '../../pages/project/projects-list.page';
import { DocumentsListComponent } from '../document';
import { DragDropUpload } from '../ui';

type ProjectDetailsLegalProps = {
  project: Project;
};

export const ProjectDetailsLegal = ({ project }: ProjectDetailsLegalProps) => {
  return (
    <>
      <div className="info-card" data-testid="contacts-info-card">
        <div className="info-card-title" data-testid="contacts-info-title">
          Contacts
        </div>
        <hr />
        <div className="row" data-testid="contacts-info-row">
          <div className="col-md-6" data-testid="contacts-info-col-left">
            <div
              className="info-card-content row"
              data-testid="contacts-info-left"
            >
              <div className="row" data-testid="client-name">
                <div
                  className="info-item-title col-4"
                  data-testid="client-name-title"
                >
                  Client Name
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="client-name-data"
                >
                  Addenbrookes Hospital
                </div>
              </div>
              <div className="row" data-testid="category">
                <div
                  className="info-item-title col-4"
                  data-testid="category-title"
                >
                  Category
                </div>
                <div
                  className="info-item-data col-8"
                  data-testid="category-data"
                >
                  OMFS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info-card" data-testid="documents-info-card">
        <div className="info-card-title" data-testid="documents-info-title">
          Documents
        </div>
        <hr />
        <DocumentsListComponent
          documents={[]}
          onClickDelete={() => {}}
          getDocuments={() => {}}
          data-testid="documents-list"
        />
        <DragDropUpload
          onUpload={(files: File[]) => ({}) as any}
          data-testid="drag-drop-upload"
        />
      </div>
    </>
  );
};
