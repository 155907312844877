import React, { Dispatch, SetStateAction } from 'react';
import { Button, FormContainer, Table } from '../ui';
import { PlusIcon } from '../../icons';
import { HostedFile } from '../../backend/careo-api';

type SelectFileListProps = {
  fileKey: string;
  documents: HostedFile[];
  addNewFile: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SelectFileList = ({
  fileKey,
  documents,
  setIsOpen,
  addNewFile,
}: SelectFileListProps) => {
  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = (file: any) => {
    addNewFile(fileKey, file);
  };

  return (
    <FormContainer data-testid="form-container">
      <Table data-testid="documents-table">
        <thead>
          <tr>
            <th data-testid="file-name-header">File Name</th>
            <th className="action-th" data-testid="action-header"></th>
          </tr>
        </thead>
        <tbody>
          {documents.map((el, index) => (
            <tr key={el._id} data-testid={`document-row-${el._id}`}>
              <td data-testid={`document-name-${el._id}`}>
                {index + 1}. {el.fileName}
              </td>
              <td>
                <div
                  className="action-item"
                  data-testid={`action-item-${el._id}`}
                >
                  <div
                    className="edit-icon"
                    onClick={() => {
                      onClickSubmit(el);
                    }}
                    data-testid={`edit-icon-${el._id}`}
                  >
                    <PlusIcon />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onClickClose} data-testid="cancel-button">
          Cancel
        </Button>
      </div>
    </FormContainer>
  );
};
