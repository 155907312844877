import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import { LeftArrowIcon, RightArrowIcon } from '../../../icons';

export const RotasContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  .weeks-wrapper {
    border-radius: 4px;
    border: 1px solid #e5e8eb;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0 0.5rem;
    gap: 16px;
    padding: 4px 8px;
    flex: 1;
    max-width: 100%;
    overflow: hidden;

    .weeks-list-container {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
    }
    .week-divider {
      border-radius: 1px;
      background: #e5e8eb;
      width: 1px;
      height: 16px;
    }
    .week-button {
      width: 104px;
      padding: 0px 10px;
      border-radius: 4px;
      font-size: 12px;
      background-color: unset !important;
      border: unset;
      outline: unset;
      flex: 0 0 auto;
      margin: 0 0.25rem;
      border-radius: 4px;
      color: #333;
      cursor: pointer;
      font-weight: bold;
      &.active {
        background-color: #0caf60 !important;
        color: white !important;
        border-color: #4caf50;
      }
    }
  }

  .scroll-arrow {
    cursor: pointer;
    color: #888;
    width: 30px;
    height: 30px;
    border: 1px solid #e5e8eb;
    background-color: #fbfbfb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #6c6c6c;
    }
  }
`;

// Type guard to check if the event is a TouchEvent
const isTouchEvent = (
  e: React.MouseEvent | React.TouchEvent,
): e is React.TouchEvent => 'touches' in e;

const generateWeeks = (year: number): WeekData[] => {
  const weeksList: WeekData[] = [];
  const startDate = new Date(year, 0, 1);

  while (startDate.getFullYear() === year) {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const label = `${startDate.getDate()}-${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}`;

    weeksList.push({
      value: new Date(startDate),
      label: label,
    });

    startDate.setDate(startDate.getDate() + 7); // Move to next week
  }

  return weeksList;
};

export type WeekData = { value: Date; label: string };

type WeeksSliderProps = {
  year: number;
  selectedWeek: WeekData | undefined;
  setSelectedWeek: Dispatch<SetStateAction<WeekData | undefined>>;
};

export const WeeksSlider = ({
  year,
  selectedWeek,
  setSelectedWeek,
}: WeeksSliderProps) => {
  const [weeks] = useState(generateWeeks(year));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const updateScrollButtonState = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsScrollLeftDisabled(scrollLeft <= 0);
      setIsScrollRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const scrollLeftArrow = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -150, behavior: 'smooth' });
      updateScrollButtonState();
    }
  };

  const scrollRightArrow = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 150, behavior: 'smooth' });
      updateScrollButtonState();
    }
  };

  const onSelectWeek = (index: number) => {
    setSelectedIndex(index);
    setSelectedWeek(weeks[index]);

    if (containerRef.current && index === 0) {
      containerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    } else if (buttonRefs.current[index]) {
      buttonRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  };

  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    const pageX = isTouchEvent(e) ? e.touches[0].pageX : e.pageX;
    setStartX(pageX);
    setScrollLeft(containerRef.current?.scrollLeft ?? 0);
  };

  const handleMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging || !containerRef.current) return;
    const pageX = isTouchEvent(e) ? e.touches[0].pageX : e.pageX;
    const walk = (pageX - startX) * 1.5;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    updateScrollButtonState();
  };

  return (
    <RotasContainer data-testid="rotas-container">
      <div
        className={`scroll-arrow ${isScrollLeftDisabled ? 'disabled' : ''}`}
        onClick={!isScrollLeftDisabled ? scrollLeftArrow : undefined}
        data-testid="scroll-left-arrow"
      >
        <LeftArrowIcon data-testid="left-arrow-icon" />
      </div>
      <div className="weeks-wrapper" data-testid="weeks-wrapper">
        <div
          className="weeks-list-container"
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          data-testid="weeks-list-container"
        >
          {weeks.map((week, index) => (
            <React.Fragment key={week.value.toJSON()}>
              {index > 0 && (
                <div
                  className="week-divider"
                  data-testid={`week-divider-${index}`}
                >
                  &nbsp;
                </div>
              )}
              <button
                ref={(el) => (buttonRefs.current[index] = el!)}
                className={`week-button ${index === selectedIndex ? 'active' : ''}`}
                onClick={() => onSelectWeek(index)}
                data-testid={`week-button-${index}`}
              >
                {week.label}
              </button>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div
        className={`scroll-arrow ${isScrollRightDisabled ? 'disabled' : ''}`}
        onClick={!isScrollRightDisabled ? scrollRightArrow : undefined}
        data-testid="scroll-right-arrow"
      >
        <RightArrowIcon data-testid="right-arrow-icon" />
      </div>
    </RotasContainer>
  );
};
