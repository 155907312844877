import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortLeads,
} from '../../utils';
import { Abbreviation, Badge, Pagination, Table } from '../ui';
import { DeleteIcon, EditIcon, EyeIcon, SortIcon } from '../../icons';
import { Lead } from '../../backend/careo-api';
import {
  leadStatusValueType,
  leadTagValueType,
  leadTypeValueType,
} from './view-lead.component';

type LeadsListProps = {
  leads: Lead[];
  isLoading: boolean;
  setIsViewModalOpen: Dispatch<SetStateAction<Lead | undefined>>;
  setIsFormModalOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; data?: Lead }>
  >;
  setRecordToDelete: Dispatch<SetStateAction<Lead | undefined>>;
};

const itemsPerPage = 8;

export const LeadsList = ({
  leads,
  isLoading,
  setIsViewModalOpen,
  setIsFormModalOpen,
  setRecordToDelete,
}: LeadsListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsList, setLeadsList] = useState<Lead[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const paginatedData = useMemo(() => {
    return getItemsOfPage(leads, currentPage, itemsPerPage);
  }, [leads, currentPage]);

  useEffect(() => {
    const sortedData = sortLeads(leads, sort);
    setLeadsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [leads, sort]);

  return (
    <>
      <div className="data-table-container" data-testid="data-table-container">
        <Table>
          <thead>
            <tr>
              <th
                onClick={() => onSelectSort('name', setSort)}
                data-testid="name-column"
              >
                <div>
                  <label>Name</label>
                  <SortIcon value={sort.key === 'name' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('status', setSort)}
                data-testid="status-column"
              >
                <div>
                  <label>Status</label>
                  <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('tag', setSort)}
                data-testid="tag-column"
              >
                <div>
                  <label>Tag</label>
                  <SortIcon value={sort.key === 'tag' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('cpm', setSort)}
                data-testid="cpm-column"
              >
                <div>
                  <label>CPM</label>
                  <SortIcon value={sort.key === 'cpm' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('type', setSort)}
                data-testid="type-column"
              >
                <div>
                  <label>Type</label>
                  <SortIcon value={sort.key === 'type' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('region', setSort)}
                data-testid="region-column"
              >
                <div>
                  <label>Region</label>
                  <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('trustHospital', setSort)}
                data-testid="trust-hospital-column"
              >
                <div>
                  <label>Trust/Hospital</label>
                  <SortIcon
                    value={sort.key === 'trustHospital' ? sort.value : ''}
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('speciality', setSort)}
                data-testid="speciality-column"
              >
                <div>
                  <label>Speciality</label>
                  <SortIcon
                    value={sort.key === 'speciality' ? sort.value : ''}
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Overview</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('dateAdded', setSort)}
                data-testid="date-added-column"
              >
                <div>
                  <label>Date Added</label>
                  <SortIcon
                    value={sort.key === 'dateAdded' ? sort.value : ''}
                  />
                </div>
              </th>
              <th data-testid="action-column">
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan={100}
                  className="text-center"
                  data-testid="loading-row"
                >
                  Loading ...
                </td>
              </tr>
            ) : (
              <>
                {paginatedData.length > 0 ? (
                  <>
                    {paginatedData.map((lead) => {
                      return (
                        <tr key={lead._id} data-testid={`lead-row-${lead._id}`}>
                          <td className="text-nowrap">{lead.name}</td>
                          <td>
                            <Badge
                              type={leadStatusValueType(lead.status)}
                              data-testid={`status-badge-${lead._id}`}
                            >
                              {lead.status}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              type={leadTagValueType(lead.tag)}
                              data-testid={`tag-badge-${lead._id}`}
                            >
                              {lead.tag}
                            </Badge>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              {lead.cpms.slice(0, 1).map((el) => (
                                <Badge
                                  type="neutral"
                                  key={el._id}
                                  data-testid={`cpm-badge-${el._id}`}
                                >
                                  <Abbreviation>
                                    {el.firstName[0]}
                                    {el.lastName[0]}
                                  </Abbreviation>
                                  {el.firstName} {el.lastName}
                                </Badge>
                              ))}
                              {lead.cpms.length > 1 && (
                                <Badge
                                  type="neutral"
                                  onClick={() =>
                                    setIsFormModalOpen({
                                      isOpen: true,
                                      data: lead,
                                    })
                                  }
                                >
                                  +{lead.cpms.length - 1}
                                </Badge>
                              )}
                            </div>
                          </td>
                          <td>
                            <Badge
                              type={leadTypeValueType(lead.type)}
                              data-testid={`type-badge-${lead._id}`}
                            >
                              {lead.type}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              type="neutral"
                              data-testid={`region-badge-${lead._id}`}
                            >
                              {lead.region}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              type="neutral"
                              data-testid={`trust-hospital-badge-${lead._id}`}
                            >
                              {lead.trust.name}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              type="neutral"
                              data-testid={`specialty-badge-${lead._id}`}
                            >
                              {lead.specialty}
                            </Badge>
                          </td>
                          <td>
                            <div
                              className="ellipsis-2"
                              data-testid={`overview-text-${lead._id}`}
                            >
                              {lead.overview}
                            </div>
                          </td>
                          <td
                            className="text-nowrap"
                            data-testid={`date-added-text-${lead._id}`}
                          >
                            {formatDate(lead.createdAt ?? '')}
                          </td>
                          <td data-testid={`action-buttons-${lead._id}`}>
                            <div className="action-item">
                              <div
                                className="view-icon"
                                onClick={() => setIsViewModalOpen(lead)}
                                data-testid={`view-icon-${lead._id}`}
                              >
                                <EyeIcon />
                              </div>
                              <div
                                className="edit-icon"
                                onClick={() =>
                                  setIsFormModalOpen({
                                    isOpen: true,
                                    data: lead,
                                  })
                                }
                                data-testid={`edit-icon-${lead._id}`}
                              >
                                <EditIcon />
                              </div>
                              <div
                                className="delete-icon"
                                onClick={() => setRecordToDelete(lead)}
                                data-testid={`delete-icon-${lead._id}`}
                              >
                                <DeleteIcon />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="no-items-row"
                    >
                      No item found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        totalEntries={leadsList.length}
        data-testid="pagination-component"
      />
    </>
  );
};
