import React, { useEffect, useState } from 'react';
import {
  Button,
  CardContainer,
  ConfirmModal,
  SideModal,
  Table,
  TitlePage,
} from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { Campaign, CampaignTemplate } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { Switch } from '@mui/material';
import { NewCampaignForm, UpdateCampaignForm } from '../campaign';

export const CampaignsList = () => {
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<Campaign | null>(null);
  const [templateLists, setTemplateLists] = useState<CampaignTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campaignToBeDelete, setCampaignToBeDelete] = useState<Campaign>();

  const onClickDeleteCampaign = async (id: string) => {
    await AxiosInstance.campaigns
      .campaignsControllerDelete(id)
      .then((response) => {
        toast.success('Campaign deleted successfully');
        getCampaigns();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setCampaignToBeDelete(undefined);
  };

  const onClickSwitch = async (idCampaign: string, newValues: boolean) => {
    await AxiosInstance.campaigns
      .campaignsControllerUpdate(idCampaign, {
        isActive: newValues,
      })
      .then((response) => {
        toast.success('Campaign status updated successfully');
        getCampaigns();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCampaignTemplates = async () => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerFindAll()
      .then((response) => {
        setTemplateLists(response.data.items);
      })
      .catch(() => {});
  };

  const getCampaigns = async () => {
    setIsLoading(true);
    await AxiosInstance.campaigns
      .campaignsControllerFindAll()
      .then((response) => {
        setCampaignsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getCampaignTemplates();
    getCampaigns();
  }, []);

  return (
    <>
      <CardContainer className="right-container" data-testid="card-container">
        <div
          className="settings-title-content"
          data-testid="settings-title-content"
        >
          <TitlePage>Campaigns</TitlePage>
          <Button
            type="primary"
            onClick={() => setIsModalOpen(true)}
            data-testid="add-new-button"
          >
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content" data-testid="settings-content">
          <div
            className="data-table-container"
            data-testid="data-table-container"
          >
            <Table data-testid="campaigns-table">
              <thead>
                <tr>
                  <th>
                    <div>
                      <label data-testid="campaign-name-header">
                        Campaign Name
                      </label>
                      <SortIcon data-testid="sort-campaign-name" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="template-name-header">
                        Template Name
                      </label>
                      <SortIcon data-testid="sort-template-name" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="frequency-header">Frequency</label>
                      <SortIcon data-testid="sort-frequency" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="active-header">Active</label>
                      <SortIcon data-testid="sort-active" />
                    </div>
                  </th>
                  <th className="action-th">
                    <div data-testid="action-header">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="loading-row"
                    >
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {campaignsList.length > 0 ? (
                      <>
                        {campaignsList.map((campaign) => (
                          <tr
                            key={campaign._id}
                            data-testid={`campaign-row-${campaign._id}`}
                          >
                            <td data-testid={`campaign-name-${campaign._id}`}>
                              {campaign.name}
                            </td>
                            <td data-testid={`template-name-${campaign._id}`}>
                              {campaign.template.name}
                            </td>
                            <td data-testid={`frequency-${campaign._id}`}>
                              {campaign.frequency}
                            </td>
                            <td>
                              <Switch
                                onClick={() =>
                                  onClickSwitch(
                                    campaign._id,
                                    !campaign.isActive,
                                  )
                                }
                                checked={campaign.isActive}
                                data-testid={`active-switch-${campaign._id}`}
                              />
                            </td>
                            <td>
                              <div
                                className="action-item"
                                data-testid={`action-item-${campaign._id}`}
                              >
                                <div
                                  className="edit-icon"
                                  onClick={() => setEntityToUpdate(campaign)}
                                  data-testid={`edit-icon-${campaign._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() =>
                                    setCampaignToBeDelete(campaign)
                                  }
                                  data-testid={`delete-icon-${campaign._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={100}
                          className="text-center"
                          data-testid="no-item-row"
                        >
                          No item found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New Campaign'}
      >
        <NewCampaignForm
          setIsOpen={setIsModalOpen}
          getCampaigns={getCampaigns}
          templateLists={templateLists}
        />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update Campaign'}
      >
        <UpdateCampaignForm
          campaign={entityToUpdate as Campaign}
          setIsOpen={() => setEntityToUpdate(null)}
          getCampaigns={getCampaigns}
          templateLists={templateLists}
        />
      </SideModal>

      {campaignToBeDelete && (
        <ConfirmModal
          isOpen={true}
          title="Delete Campaign"
          onNegativeBtnClick={() => setCampaignToBeDelete(undefined)}
          onPositiveBtnClick={() =>
            onClickDeleteCampaign(campaignToBeDelete._id)
          }
        >
          Do you want to delete <b>{campaignToBeDelete.name}</b>
        </ConfirmModal>
      )}
    </>
  );
};
