import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file-list.component';
import { SideModal } from '../../ui';
import { DocumentsListComponent } from '../../document/documents-list.component';

type OccupationHealthProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'questionnaireFiles' | 'questionnaireFiles2';

export const OccupationHealth = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: OccupationHealthProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] }[]
  >([
    { key: 'questionnaireFiles', files: [] },
    { key: 'questionnaireFiles2', files: [] },
  ]);
  const { register, getValues, setValue } = useForm<{
    questionnaireFiles: any[];
    questionnaireFiles2: any[];
    isOccupationHealth: string;
    isReporting: string;
    isAllReport: string;
  }>();

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = prev[itemIndex].files.filter(
        (el) => el._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files.push(file);
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key:
      | 'questionnaireFiles'
      | 'questionnaireFiles2'
      | 'isOccupationHealth'
      | 'isReporting'
      | 'isAllReport',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      setValue(
        'isOccupationHealth',
        compliance?.metadata?.isOccupationHealth ? 'true' : 'false',
      );
      setValue(
        'isReporting',
        compliance?.metadata?.isReporting ? 'true' : 'false',
      );
      setValue(
        'isAllReport',
        compliance?.metadata?.isAllReport ? 'true' : 'false',
      );
      const questionnaireFiles = compliance?.metadata?.questionnaireFiles ?? [];
      const questionnaireFiles2 =
        compliance?.metadata?.questionnaireFiles2 ?? [];
      setValue('questionnaireFiles', questionnaireFiles);
      setValue('questionnaireFiles2', questionnaireFiles2);
      setFilesList([
        { key: 'questionnaireFiles', files: questionnaireFiles },
        { key: 'questionnaireFiles2', files: questionnaireFiles2 },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      {/* Occupational Health Section */}
      <TitleCard data-testid="title-card-occupational-health">
        Occupational Health
      </TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Epp',
            value: 'true',
          },
          {
            label: 'Non Epp',
            value: 'false',
          },
        ]}
        name="occupation"
        register={register('isOccupationHealth')}
        onChange={(value) => {
          onUpdateCompliance('isOccupationHealth', value);
        }}
        data-testid="occupation-radio-group"
      />

      {/* Upload Questionnaire */}
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-questionnaire"
      >
        <div className="col-md-6" data-testid="col-upload-questionnaire">
          <FieldFile
            label="Upload Questionnaire"
            onChange={(e) => handleFileChange(e, 'questionnaireFiles')}
            data-testid="field-file-questionnaire"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => setIsModalOpen('questionnaireFiles')}
          data-testid="select-questionnaire-btn"
        >
          <Button type="primary" data-testid="select-questionnaire-btn-label">
            Select from files
          </Button>
        </div>
      </div>

      <DocumentsListComponent
        documents={
          filesList.find((el) => el.key === 'questionnaireFiles')?.files ?? []
        }
        onClickDelete={(id) => {
          onClickDelete('questionnaireFiles', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={(_) => {
          reFetchCompliance?.();
        }}
        data-testid="documents-list-questionnaire"
      />

      {/* Reporting Section */}
      <TitleCard data-testid="title-card-reporting">Reporting</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Epp',
            value: 'true',
            description:
              'List of required reports: Hep B Titre levels/ MMR/ Varicella/ TB/ Hep Surface Antigen/ Hep C/ HIV (dual screening). Last 3 documents must be identity validated',
          },
          {
            label: 'Non Epp',
            value: 'false',
            description:
              'List of required reports: Hep B Titre levels/ MMR/ Varicella/ TB',
          },
        ]}
        name="isReporting"
        register={register('isReporting', { required: true })}
        onChange={(value) => {
          onUpdateCompliance('isReporting', value);
        }}
        data-testid="reporting-radio-group"
      />

      {/* Request Further Documents */}
      <TitleCard data-testid="title-card-request-documents">
        Request further documents
      </TitleCard>
      <div
        className="row upload-buttons-container"
        data-testid="upload-buttons-questionnaire2"
      >
        <div className="col-md-6" data-testid="col-upload-questionnaire2">
          <FieldFile
            label="Upload documents"
            onChange={(e) => handleFileChange(e, 'questionnaireFiles2')}
            data-testid="field-file-questionnaire2"
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => setIsModalOpen('questionnaireFiles2')}
          data-testid="select-questionnaire2-btn"
        >
          <Button type="primary" data-testid="select-questionnaire2-btn-label">
            Select from files
          </Button>
        </div>
      </div>

      <DocumentsListComponent
        documents={
          filesList.find((el) => el.key === 'questionnaireFiles2')?.files ?? []
        }
        onClickDelete={(id) => {
          onClickDelete('questionnaireFiles2', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={(_) => {
          reFetchCompliance?.();
        }}
        data-testid="documents-list-questionnaire2"
      />

      {/* Government Check Switch */}
      <div
        className="government-check-switch"
        data-testid="government-check-switch"
      >
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            {...register('isAllReport', { required: true })}
            onChange={(e) => {
              onUpdateCompliance('isAllReport', e.target.checked);
            }}
            data-testid="government-check-switch-input"
          />
          <label
            className="form-check-label"
            htmlFor="flexSwitchCheckDefault"
            data-testid="government-check-switch-label"
          >
            All reports and questionnaire will be verified via a CQOHS approved
            Occ Health provider (healthier business)
          </label>
        </div>
      </div>

      {/* Pass/Fail Section */}
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="isPass"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
        data-testid="pass-fail-radio-group"
      />

      {/* Select File Modal */}
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
        data-testid="side-modal-file-select-occupation-health"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
          data-testid="select-file-list-occupation-health"
        />
      </SideModal>
    </>
  );
};
