import React from 'react';
import { FieldText, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { CreateTrustDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  trustSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type NewTrustFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewTrustForm = ({ onCancel, onSuccess }: NewTrustFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateTrustDto>({
    resolver: yupResolver(trustSchema as any),
  });

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.trust
      .trustsControllerCreateTrust(values)
      .then(() => {
        toast.success('Trust added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name')}
        error={errors.name}
        data-testid="name-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Description"
        register={register('description')}
        error={errors.description}
        data-testid="description-field"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
