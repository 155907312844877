import React, { Dispatch, SetStateAction } from 'react';
import { TitleCard, FieldText, Button, Select, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { CreateUserDto, EUserRole } from '../../backend/careo-api';
import { AddUserSchema, AxiosInstance } from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { isCRMApp } from '../../environment/app.type';

type NewUserFormProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getUsers: () => void;
};

export const NewUserForm = ({ setIsOpen, getUsers }: NewUserFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserDto>({
    resolver: yupResolver(AddUserSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.users
      .usersControllerCreateUser(values)
      .then(() => {
        toast.success('User added successfully');
        getUsers();
        onClickClose();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <Select
        label="Role"
        required
        register={register('role', { required: true })}
        options={[
          {
            value: isCRMApp ? EUserRole.Recruiter : EUserRole.Officer,
            label: isCRMApp ? 'Recruiter' : 'Officer',
          },
          {
            value: EUserRole.Manager,
            label: 'Manager',
          },
        ]}
        placeholder="Select role"
        error={errors?.role}
        data-testid="select-role"
      />
      <TitleCard data-testid="title-card-user-details">User Details</TitleCard>
      <div className="row" data-testid="row-name">
        <div className="col-md-6" data-testid="col-first-name">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
            data-testid="field-first-name"
          />
        </div>
        <div className="col-md-6" data-testid="col-last-name">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
            data-testid="field-last-name"
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Email"
        required
        register={register('email', { required: true })}
        error={errors.email}
        data-testid="field-email"
      />
      <FieldText
        type="phoneNumber"
        placeholder="+442071234567"
        label="Phone Number"
        required
        register={register('phoneNumber', { required: true })}
        error={errors.phoneNumber}
        data-testid="field-phone-number"
      />

      <div className="row" data-testid="row-password">
        <div className="col-md-6" data-testid="col-password">
          <FieldText
            placeholder="Enter here ..."
            label="Password"
            required
            register={register('password', { required: true })}
            error={errors.password}
            data-testid="field-password"
          />
        </div>
        <div className="col-md-6" data-testid="col-confirm-password">
          <FieldText
            placeholder="Enter here ..."
            label="Confirm Password"
            required
            register={register('confirmPassword', { required: true })}
            error={errors.confirmPassword}
            data-testid="field-confirm-password"
          />
        </div>
      </div>

      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onClickClose} data-testid="button-cancel">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="button-create"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
