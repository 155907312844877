import React, { useState } from 'react';
import { Project } from '../../pages/project/projects-list.page';
import { ColumnSwitch, PlusIcon, RowSwitch } from '../../icons';
import { Button, MultipleSelect, SearchInput, TitleCard } from '../ui';
import { WeekData, WeeksSlider } from './rotas-components/weeks-slider';
import { RotasWeekCalendar } from './rotas-components/rotas-week-calendar';

type ProjectDetailsRotasProps = {
  project: Project;
};

export const ProjectDetailsRotas = ({ project }: ProjectDetailsRotasProps) => {
  const [isRowSwitch, setIsRowSwitch] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState<WeekData>();

  return (
    <div className="rotas-list-content" data-testid="rotas-list-content">
      <div
        className="rotas-header-container"
        data-testid="rotas-header-container"
      >
        <div
          className="rotas-header-left-container"
          data-testid="rotas-header-left"
        >
          <TitleCard className="title-page" data-testid="title-card">
            August
          </TitleCard>
        </div>
        <div
          className="rotas-header-right-container"
          data-testid="rotas-header-right"
        >
          <Button
            type="success"
            variant="outlined"
            data-testid="generate-rota-button"
          >
            <PlusIcon /> Generate Rota with AI
          </Button>
          {isRowSwitch ? (
            <RowSwitch
              onClick={() => setIsRowSwitch(false)}
              data-testid="row-switch"
            />
          ) : (
            <ColumnSwitch
              onClick={() => setIsRowSwitch(true)}
              data-testid="column-switch"
            />
          )}
        </div>
      </div>
      <div
        className="rotas-filter-container"
        data-testid="rotas-filter-container"
      >
        <SearchInput
          placeholder="Search candidates"
          onChange={() => {}}
          data-testid="search-input"
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-1"
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-2"
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-3"
        />
        <MultipleSelect
          placeholder="All Tags"
          options={[]}
          onChange={() => {}}
          data-testid="tags-select"
        />
      </div>
      <div
        className="scrollable-weeks-container"
        data-testid="weeks-slider-container"
      >
        <WeeksSlider
          year={2024}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          data-testid="weeks-slider"
        />
      </div>
      <div className="weeks-calendar" data-testid="weeks-calendar">
        {selectedWeek && (
          <RotasWeekCalendar
            selectedWeek={selectedWeek}
            data-testid="rotas-week-calendar"
          />
        )}
      </div>
    </div>
  );
};
