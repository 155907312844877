import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import { TUIType, breakpoint, getTypeStyles } from '../../constants';
import { UploadIcon } from '../../icons';

const ButtonContainer = styled.div<{
  type: TUIType;
  variant: 'contained' | 'outlined';
}>`
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  font-size: 14px;
  ${(props) => getTypeStyles(props.type, props.variant)}
  &:not(.disabled):hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  &.disabled {
    cursor: not-allowed !important;
    color: gray !important;
    border: none !important;
    background-color: #e9ecef !important;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 10px 10px;
    font-size: 10px;
    gap: 5px;
  }
`;

type ButtonProps = {
  type?: TUIType;
  variant?: 'contained' | 'outlined';
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
};

export const Button: React.FC<ButtonProps> = ({
  onClick = () => {},
  children,
  type = 'default',
  variant = 'contained',
  disabled,
  className = '',
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      type={type}
      className={`button ${className}  ${disabled ? 'disabled' : ''}`}
      variant={variant}
      data-testid="custom-button"
    >
      {children}
    </ButtonContainer>
  );
};

type UploadButtonProps = {
  onUpload: (file: File) => void;
  children: React.ReactNode;
  accept?: string;
};

export const UploadButton = ({
  onUpload,
  children,
  accept = '*',
}: UploadButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUpload(file);
    }
    event.target.value = '';
  };

  return (
    <div>
      <Button
        type="default"
        onClick={handleButtonClick}
        data-testid="upload-button"
      >
        <UploadIcon data-testid="upload-icon" /> {children}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={accept}
        data-testid="file-input"
      />
    </div>
  );
};
