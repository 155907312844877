import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import {
  Button,
  CardContainer,
  ConfirmModal,
  HeaderPageContainer,
  SearchInput,
  SideModal,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  MultipleSelect,
  NewSharedCandidatesList,
  UpdateSharedCandidatesList,
  Pagination,
} from '../../components';
import { DeleteIcon, EditIcon, EyeIcon, PlusIcon, SortIcon } from '../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  filterCandidatesLists,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Candidate, CandidatesList, User } from '../../backend/careo-api';
import { toast } from 'react-toastify';

const itemsPerPage = 8;

export const SharedCandidatesListPage = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [candidatesListsDetails, setCandidatesListsDetails] = useState<
    CandidatesList[]
  >([]);
  const [candidatesListsDetailsLists, setCandidatesListsDetailsLists] =
    useState<CandidatesList[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [recordToDelete, setRecordToDelete] = useState<{
    item: CandidatesList;
    remove: boolean;
  } | null>(null);

  const [isModalCreateListOpen, setIsModalCreateListOpen] = useState(false);
  const [isModalUpdateListOpen, setIsModalUpdateListOpen] =
    useState<CandidatesList | null>(null);

  const [filter, setFilter] = useState({
    search: '',
    region: '',
    county: '',
    grade: '',
    level: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickEdit = (candidatesList: CandidatesList) => {
    setIsModalUpdateListOpen(candidatesList);
  };

  const onClickCreate = () => {
    setIsModalCreateListOpen(true);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.SHARED_CANDIDATES}/${id}`);
  };

  const getCandidatesListsDetails = async () => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerFindAll()
      .then((response) => {
        setCandidatesListsDetails(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteCandidateList = async () => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerRemove(recordToDelete?.item?._id!)
      .then(() => {
        toast.success('Candidate List Removed successfully');
        getCandidatesListsDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(null);
  };

  const disjoinCandidateList = async () => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerDisjoinList(recordToDelete?.item?._id!)
      .then(() => {
        toast.success('You have disjoined Candidate List successfully');
        getCandidatesListsDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setRecordToDelete(null);
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerFindAll()
      .then((response) => {
        setCandidates(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(
      candidatesListsDetailsLists,
      currentPage,
      itemsPerPage,
    );
  }, [candidatesListsDetailsLists, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidatesLists(candidatesListsDetails, filter);

    const sortedData = filteredData.sort((a, b) => {
      if (!sort.key || !sort.value) return 0;

      let keyA, keyB;

      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];

      if (typeof keyA !== 'string') keyA = '';
      if (typeof keyB !== 'string') keyB = '';

      const result = keyA.localeCompare(keyB);
      return sort.value === '+' ? result : -result;
    });

    setCandidatesListsDetailsLists(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [candidatesListsDetails, filter, sort]);

  useEffect(() => {
    getCandidatesListsDetails();
    getCandidates();
    getUsers();
  }, []);

  return (
    <>
      <TabFilter
        data-testid="tab-filter"
        filters={[
          {
            title: 'Candidates',
            url: ERoute.CANDIDATES,
          },
          {
            title: 'Lists',
            url: ERoute.SHARED_CANDIDATES,
          },
        ]}
      />
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Candidate Lists</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Lists
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="create-list-button"
            >
              <PlusIcon /> Create New List
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search candidate list"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Regions"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="regions-select"
          />
          <MultipleSelect
            placeholder="All Counties"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="counties-select"
          />

          <MultipleSelect
            placeholder="All Levels"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="levels-select"
          />
          <MultipleSelect
            placeholder="All Grades"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="grades-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="candidate-list-table">
            <thead>
              <tr>
                <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="name-header"
                >
                  <div>
                    <label>Name </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                      data-testid="sort-icon-name"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('candidates', setSort)}
                  data-testid="list-candidates-header"
                >
                  <div>
                    <label>List candidates</label>
                    <SortIcon
                      value={sort.key === 'candidates' ? sort.value : ''}
                      data-testid="sort-icon-candidates"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user', setSort)}
                  data-testid="line-manager-header"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon value={sort.key === 'user' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="created-on-header"
                >
                  <div>
                    <label>Created on</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="last-edited-header"
                >
                  <div>
                    <label>Last Edited</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th data-testid="action-column-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((itemList) => {
                const candidatesName = itemList.candidates
                  .map((el) => el.firstName + ' ' + el.lastName)
                  .join(', ');

                const isUpdateDisabled = user?._id !== itemList.user._id;

                return (
                  <tr
                    key={itemList._id}
                    data-testid={`candidate-row-${itemList._id}`}
                  >
                    <td
                      className="checkbox-table"
                      data-testid="checkbox-column"
                    >
                      <input type="checkbox" />
                    </td>
                    <td data-testid="candidate-name">{itemList.name}</td>
                    <td
                      title={candidatesName}
                      data-testid="candidate-list-names"
                    >
                      <div className="candidates-names">{candidatesName}</div>
                    </td>
                    <td data-testid="line-manager-name">
                      {itemList?.user.firstName} {itemList?.user.lastName}
                    </td>
                    <td data-testid="created-date">
                      {formatDate(itemList.createdAt)}{' '}
                    </td>
                    <td data-testid="updated-date">
                      {itemList.updatedAt
                        ? formatDate(itemList.updatedAt)
                        : '-'}{' '}
                    </td>
                    <td data-testid="action-item">
                      <div className="action-item">
                        <div
                          className="view-icon"
                          onClick={() => onClickView(itemList._id)}
                          data-testid="view-icon"
                        >
                          <EyeIcon />
                        </div>
                        <div
                          className={`edit-icon ${
                            isUpdateDisabled && 'disabled'
                          }`}
                          onClick={() =>
                            !isUpdateDisabled && onClickEdit(itemList)
                          }
                          data-testid="edit-icon"
                        >
                          <EditIcon />
                        </div>
                        <div
                          className={`delete-icon`}
                          onClick={() =>
                            setRecordToDelete({
                              item: itemList,
                              remove: !isUpdateDisabled,
                            })
                          }
                          data-testid="delete-icon"
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={candidatesListsDetailsLists.length}
          data-testid="pagination"
        />
      </CardContainer>
      <SideModal
        isOpen={isModalCreateListOpen}
        setIsOpen={setIsModalCreateListOpen}
        title={'Create New List'}
        data-testid="create-list-modal"
      >
        <NewSharedCandidatesList
          onCancel={() => setIsModalCreateListOpen(false)}
          onSuccess={() => {
            getCandidatesListsDetails();
            setIsModalCreateListOpen(false);
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidatesIds={[]}
          data-testid="new-shared-candidates-list"
        />
      </SideModal>
      <SideModal
        isOpen={!!isModalUpdateListOpen}
        setIsOpen={() => setIsModalUpdateListOpen(null)}
        title={'Update List'}
        data-testid="update-list-modal"
      >
        <UpdateSharedCandidatesList
          onCancel={() => setIsModalUpdateListOpen(null)}
          onSuccess={() => {
            getCandidatesListsDetails();
            setIsModalUpdateListOpen(null);
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidateList={isModalUpdateListOpen!}
          data-testid="update-shared-candidates-list"
        />
      </SideModal>
      <ConfirmModal
        isOpen={!!recordToDelete}
        title={
          recordToDelete?.remove
            ? 'Delete Candidate List'
            : 'Disjoin Candidate List'
        }
        onNegativeBtnClick={() => setRecordToDelete(null)}
        onPositiveBtnClick={() =>
          recordToDelete?.remove
            ? deleteCandidateList()
            : disjoinCandidateList()
        }
        data-testid="confirm-modal"
      >
        {recordToDelete?.remove ? (
          <>
            Do you want to delete <b>{recordToDelete?.item?.name}</b> ?
          </>
        ) : (
          <>
            Do you want to disjoin <b>{recordToDelete?.item?.name}</b> ?
          </>
        )}

        <div
          style={{
            fontSize: '15px',
            color: '#E6BB20',
          }}
        ></div>
      </ConfirmModal>
    </>
  );
};
