import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Compliance,
  HostedFile,
  ExtendedCandidate,
  User,
  Payroll,
} from '../../backend/careo-api';
import {
  TitlePage,
  CardContainer,
  UserIcon,
  ATag,
  ComplianceEdit,
  TitleCard,
  ComplianceList,
  Button,
  ProgressBar,
  DragDropUpload,
  UpdateCandidateForm,
  CandidateDetailsGeneral,
  DocumentsListComponent,
  SideModal,
  TabPageContainer,
  CandidateComplianceDetailsContainer,
  ComplianceContainer,
  ComplianceStatus,
  NewNotificationsList,
  NewNotificationsListContainer,
} from '../../components';
import {
  LeftArrowIcon,
  CallIcon,
  MessageIcon,
  NewSmallNotificationRedIcon,
  EditIcon,
} from '../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  getTimeAgo,
  uploadCandidateFiles,
  TNotificationUI,
  getNotificationData,
} from '../../utils';

export enum ECandidateComplianceTabs {
  GENERAL = 'General',
  DOCUMENTS = 'Documents',
  COMPLIANCE = 'Compliance',
}

export const CandidateComplianceDetailsPage = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const location = useLocation();

  const tabs = [
    {
      name: ECandidateComplianceTabs.GENERAL,
      url: `/candidates/${id}`,
    },
    {
      name: ECandidateComplianceTabs.DOCUMENTS,
      url: `/candidates/${id}/documents`,
    },
    {
      name: ECandidateComplianceTabs.COMPLIANCE,
      url: `/candidates/${id}/compliance`,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUpdateCandidateOpen, setIsModalUpdateCandidateOpen] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [selectedTabCompliance, setSelectedTabCompliance] =
    useState<Compliance | null>(null);

  const [candidate, setCandidate] = useState<ExtendedCandidate | null>(null);
  const [candidateDocuments, setCandidateDocuments] = useState<HostedFile[]>(
    [],
  );
  const [compliancesList, setCompliancesList] = useState<Compliance[]>([]);
  const [notifications, setNotifications] = useState<TNotificationUI[]>([]);

  const [usersList, setUsersList] = useState<User[]>([]);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);

  const onClickNotificationItem = async (notificationUI: TNotificationUI) => {
    navigate(notificationUI.url);
    await AxiosInstance.notifications
      .notificationsControllerViewNotification(notificationUI._id)
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getComplianceList();
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadCandidateFiles(id!, files)
      .then(() => {
        toast.success('Document uploaded successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickGoBack = () => {
    navigate('/candidates');
  };

  const onClickTab = (url: string) => {
    navigate(url);
  };

  const getComplianceList = async () => {
    await AxiosInstance.compliances
      .compliancesControllerFindAll({
        candidateId: id,
      })
      .then((response) => {
        setCompliancesList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!, { analytics: true })
      .then((response) => {
        setCandidate(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((response) => {
        setCandidateDocuments(response.data.items);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getNotifications = async () => {
    await AxiosInstance.notifications
      .notificationsControllerFindAll()
      .then((response) => {
        const notificationsResponse = response.data.items;
        const result = notificationsResponse
          .map((el) => getNotificationData(el))
          .filter((el) => el?.title) as TNotificationUI[];
        setNotifications(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getPayrolls = async () => {
    await AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (!type) {
      setSelectedTabCompliance(null);
    }
    const selectedCompliance =
      compliancesList.find((com) => com.taskName === type) ?? null;

    setSelectedTabCompliance(selectedCompliance);
  }, [location, compliancesList]);

  useEffect(() => {
    let tab = tabs.find((el) => el.url === location.pathname) ?? null;
    if (!tab && type) {
      const complianceTab = `/candidates/${id}/compliance/${type}`;
      if (decodeURIComponent(location.pathname) === complianceTab) {
        tab = {
          name: ECandidateComplianceTabs.COMPLIANCE,
          url: `/candidates/${id}/compliance`,
        };
      }
    }

    setSelectedTab(tab);
  }, [location]);

  useEffect(() => {
    getUsers();
    getPayrolls();
    getCandidateDetails();
    getCandidateDocuments();
    getComplianceList();
    getNotifications();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateComplianceDetailsContainer data-testid="candidate-compliance-details-container">
        <div className="candidate-header" data-testid="candidate-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon />
          </div>
          <TitlePage data-testid="candidate-title">Detail Candidate</TitlePage>
        </div>

        <CardContainer
          className="page-summary-container"
          data-testid="page-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
              entity="candidate"
              data-testid="user-icon"
            />
            <div className="module-info" data-testid="module-info">
              <div className="name" data-testid="candidate-name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div
                className="availability"
                data-testid="candidate-availability"
              >
                {candidate.address?.city}
              </div>
            </div>
          </div>
          <div className="module-contact" data-testid="module-contact-phone">
            <CallIcon />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact" data-testid="module-contact-email">
            <MessageIcon />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container" data-testid="actions-container">
            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
              data-testid="message-button"
            >
              Message
            </Button>
          </div>
          <div
            className="edit-container"
            onClick={() => setIsModalUpdateCandidateOpen(true)}
            data-testid="edit-candidate-button"
          >
            <EditIcon />
          </div>
        </CardContainer>

        <CardContainer
          className="candidate-information-container"
          data-testid="candidate-info-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  key={el.url}
                  className={`tab-item ${el.url === selectedTab?.url ? 'active' : ''}`}
                  onClick={() => onClickTab(el.url)}
                  data-testid={`tab-item-${el.url}`}
                >
                  {el.name}
                </ATag>
              ))}
            </div>

            {(selectedTab?.name === ECandidateComplianceTabs.GENERAL ||
              selectedTab?.name === ECandidateComplianceTabs.DOCUMENTS) && (
              <div
                className="tab-content-container"
                data-testid="tab-content-container"
              >
                {selectedTab?.name === ECandidateComplianceTabs.GENERAL && (
                  <CandidateDetailsGeneral
                    candidate={candidate}
                    data-testid="candidate-details-general"
                  />
                )}
                {selectedTab?.name === ECandidateComplianceTabs.DOCUMENTS && (
                  <>
                    <div
                      className="info-card"
                      data-testid="personal-documents-card"
                    >
                      <div className="info-card-title">Personal Documents</div>
                      <hr />
                      <div
                        className="data-table-container"
                        data-testid="documents-list-container"
                      >
                        <DocumentsListComponent
                          documents={candidateDocuments}
                          onClickDelete={onClickDelete}
                          getDocuments={getCandidateDocuments}
                          data-testid="documents-list"
                        />
                      </div>
                    </div>
                    <div
                      className="info-card"
                      data-testid="drag-drop-upload-card"
                    >
                      <DragDropUpload
                        onUpload={handleFileChange}
                        data-testid="drag-drop-upload"
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </TabPageContainer>
        </CardContainer>

        {selectedTab?.name === ECandidateComplianceTabs.COMPLIANCE && (
          <>
            {selectedTabCompliance ? (
              <CardContainer
                className="candidate-information-container"
                data-testid="compliance-edit-container"
              >
                <TabPageContainer data-testid="compliance-edit-tab">
                  <div
                    className="tab-content-container"
                    data-testid="compliance-edit-content"
                  >
                    <ComplianceContainer data-testid="compliance-container">
                      <ComplianceEdit
                        selectedTab={selectedTabCompliance}
                        getComplianceList={getComplianceList}
                        data-testid="compliance-edit"
                      />
                    </ComplianceContainer>
                  </div>
                </TabPageContainer>
              </CardContainer>
            ) : (
              <div
                className="compliance-list-container"
                data-testid="compliance-list-container"
              >
                <CardContainer
                  className="candidate-information-container"
                  data-testid="compliance-info-container"
                >
                  <TitleCard data-testid="compliance-title-card">
                    Compliance
                  </TitleCard>
                  <TabPageContainer data-testid="compliance-tab-container">
                    <div
                      className="tab-content-container"
                      data-testid="compliance-tab-content"
                    >
                      <ComplianceContainer data-testid="compliance-list-container">
                        <div
                          className="info-card compliance-info-container"
                          data-testid="compliance-info-card"
                        >
                          <div
                            className="compliance-status"
                            data-testid="compliance-status"
                          >
                            Compliance Status :
                            <ComplianceStatus
                              value={candidate.complianceStatus}
                              data-testid="compliance-status-value"
                            />
                          </div>
                          <ProgressBar
                            value={candidate.completionPercentage}
                            data-testid="progress-bar"
                          />
                        </div>
                        <div
                          className="data-table-container"
                          data-testid="compliance-list-table"
                        >
                          <ComplianceList
                            compliancesList={compliancesList}
                            getComplianceList={getComplianceList}
                            data-testid="compliance-list"
                          />
                        </div>
                      </ComplianceContainer>
                    </div>
                  </TabPageContainer>
                </CardContainer>
                <CardContainer
                  className="compliance-updates-container"
                  data-testid="compliance-updates-container"
                >
                  <TitleCard data-testid="updates-title-card">
                    Updates
                  </TitleCard>
                  <TabPageContainer data-testid="updates-tab-container">
                    <div
                      className="tab-content-container"
                      data-testid="updates-tab-content"
                    >
                      <ComplianceContainer data-testid="compliance-updates-container">
                        {!!notifications.length ? (
                          <NewNotificationsListContainer data-testid="notifications-list-container">
                            <div
                              className="notification-content-list"
                              data-testid="notification-list"
                            >
                              {notifications.slice(0, 5).map((el) => (
                                <div
                                  key={el._id}
                                  className="notification-item"
                                  onClick={() => onClickNotificationItem(el)}
                                  data-testid={`notification-item-${el._id}`}
                                >
                                  <div className="notification-item-top">
                                    <div
                                      className="notification-title"
                                      data-testid="notification-title"
                                    >
                                      {el?.title}
                                    </div>
                                    <div
                                      className="notification-time"
                                      data-testid="notification-time"
                                    >
                                      {getTimeAgo(el.createdDate)}
                                    </div>
                                  </div>
                                  <div className="notification-item-bottom">
                                    <div
                                      className="notification-description"
                                      data-testid="notification-description"
                                    >
                                      {el?.description}
                                    </div>
                                    {el.isNew && (
                                      <NewSmallNotificationRedIcon data-testid="new-small-notification" />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <br />
                            <Button
                              onClick={() => setIsModalOpen(true)}
                              type="primary"
                              data-testid="show-all-notifications-button"
                            >
                              Show All
                            </Button>
                          </NewNotificationsListContainer>
                        ) : (
                          <div
                            className="no-items pt-5"
                            data-testid="no-updates-message"
                          >
                            No updates yet
                          </div>
                        )}
                      </ComplianceContainer>
                    </div>
                  </TabPageContainer>
                </CardContainer>
              </div>
            )}
          </>
        )}
      </CandidateComplianceDetailsContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Updates"
        data-testid="updates-side-modal"
      >
        <NewNotificationsList
          setIsOpen={setIsModalOpen}
          notifications={notifications}
          onClickNotificationItem={onClickNotificationItem}
          getNotifications={getNotifications}
          data-testid="new-notifications-list"
        />
      </SideModal>

      <SideModal
        isOpen={isModalUpdateCandidateOpen}
        setIsOpen={setIsModalUpdateCandidateOpen}
        title="Edit Candidate"
        data-testid="edit-candidate-modal"
      >
        <UpdateCandidateForm
          onCancel={() => setIsModalUpdateCandidateOpen(false)}
          onSuccess={() => {
            getCandidateDetails();
            setIsModalUpdateCandidateOpen(false);
          }}
          payrollsList={payrollsList}
          usersList={usersList}
          selectedCandidate={candidate}
          data-testid="update-candidate-form"
        />
      </SideModal>
    </>
  );
};
