import React, { useEffect, useRef, useState } from 'react';
import {
  AttachFileIcon,
  CancelIcon,
  EditIcon,
  RightArrowIcon,
} from '../../icons';
import { Button, FieldText, TextEditor } from '../ui';
import { EmailContentContainer } from './emails-content.style';
import { EmailAttachment } from '../../backend/careo-api';
import { EmailBodyUi } from '../../pages/emails';
import { toast } from 'react-toastify';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  base64ToFile,
  draftEmailRequest,
  sendEmailRequest,
  updateDraftEmailRequest,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';

type EmailCreateProps = {
  onCancel: () => void;
  data?: EmailBodyUi;
};

export const EmailCreate = ({ onCancel, data }: EmailCreateProps) => {
  const navigate = useNavigate();
  const { unLinkEmail } = useAuth();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [hostedFiles, setHostedFiles] = useState<EmailAttachment[]>([]);

  const sendEmail = async (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => {
    if (!email || !subject || !body) {
      toast.warning('Email, subject and body are required !');
      return;
    }

    const properEmail = email?.match(/<([^>]+)>/)?.[1] || email;

    await sendEmailRequest(properEmail, subject, body, attachments)
      .then((response) => {
        toast.success('Email sent successfully');
        navigate(`/emails`);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        if (error.status === 403) {
          unLinkEmail();
        }
        toast.error(error?.message ?? 'Something went wrong');
      });
  };

  const draftEmail = async (
    email: string,
    subject: string,
    body: string,
    attachments: File[],
  ) => {
    if (!email || !subject || !body) {
      toast.warning('Email, subject and body are required !');
      return;
    }

    await draftEmailRequest(email, subject, body, attachments)
      .then((response) => {
        toast.success('Email saved successfully');
        navigate(`/emails`);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        if (error.status === 403) {
          unLinkEmail();
        }
        toast.error(error?.message ?? 'Something went wrong');
      });
  };

  const updateDraftEmail = async (
    id: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await updateDraftEmailRequest(
      id,
      recipient,
      subject,
      body,
      attachments,
      hostedFiles,
    )
      .then((response) => {
        toast.success('Email saved successfully');
        navigate(`/emails`);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        if (error.status === 403) {
          unLinkEmail();
        }
        toast.error(error?.message ?? 'Something went wrong');
      });
  };

  const sendDraftEmail = async (
    id: string,
    recipient: string,
    subject: string,
    body: string,
    attachments: File[],
    hostedFiles: EmailAttachment[],
  ) => {
    if (!subject || !body) {
      toast.warning('Subject and body are required !');
      return;
    }

    await updateDraftEmailRequest(
      id,
      recipient,
      subject,
      body,
      attachments,
      hostedFiles,
    )
      .then((response) => {
        AxiosInstance.emailsDrafts
          .emailDraftsControllerSendDraftEmail(id)
          .then(() => {
            toast.success('Email sent successfully');
            navigate(`/emails`);
          });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        if (error.status === 403) {
          unLinkEmail();
        }
        toast.error(error?.message ?? 'Something went wrong');
      });
  };

  const clearData = () => {
    setSubject('');
    setBody('');
    setAttachments([]);
    setHostedFiles([]);
    onCancel();
  };

  const onClickSendEmail = () => {
    data?.draftId
      ? sendDraftEmail(
          data.draftId!,
          recipient,
          subject,
          body,
          attachments,
          hostedFiles,
        )
      : sendEmail(recipient, subject, body, attachments);
  };

  const onClickCreateOrUpdateDraft = () => {
    data?.draftId
      ? updateDraftEmail(
          data?.draftId,
          recipient,
          subject,
          body,
          attachments,
          hostedFiles,
        )
      : draftEmail(recipient, subject, body, attachments);
  };

  useEffect(() => {
    if (data) {
      setRecipient(data?.to?.[0] ?? '');
      setSubject(data?.subject ?? '');
      setBody(data?.bodyHtml ?? '');
      setAttachments(
        (data.attachments as EmailAttachment[]).map((el: EmailAttachment) =>
          base64ToFile(el.data, el.filename, el.mimeType),
        ) ?? [],
      );
    }
  }, []);

  return (
    <EmailContentContainer>
      <div className="create-email-container">
        <div className="to-input">
          To:{' '}
          {data?.to.length ? (
            <FieldText
              value={`${recipient}`}
              disabled
              data-testid="to-input-disabled"
            />
          ) : (
            <FieldText
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
              data-testid="to-input"
            />
          )}
        </div>
        <div className="subject-input">
          Subject:{' '}
          <FieldText
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            data-testid="subject-input"
          />
        </div>
        <div className="email-attachments">
          <button
            style={{ background: 'none', border: 'none' }}
            onClick={() => fileInputRef.current?.click()}
            data-testid="attach-file-button"
          >
            <AttachFileIcon />
          </button>
          <div className="attachments-list" data-testid="attachments-list">
            {!(attachments.length + hostedFiles.length) ? (
              'No attachments'
            ) : (
              <>
                {hostedFiles.map((el, index) => (
                  <div
                    className="attachment-file"
                    key={index}
                    data-testid={`attachment-file-hosted-${index}`}
                  >
                    <div>
                      {(el as EmailAttachment).filename ?? 'unknown file'}
                    </div>
                    <CancelIcon
                      onClick={() =>
                        setHostedFiles((prev: any) =>
                          prev.filter((_: any, i: number) => i !== index),
                        )
                      }
                      data-testid={`cancel-hosted-attachment-${index}`}
                    />
                  </div>
                ))}
                {attachments.map((el, index) => (
                  <div
                    className="attachment-file"
                    key={index}
                    data-testid={`attachment-file-${index}`}
                  >
                    <div>{el.name}</div>
                    <CancelIcon
                      onClick={() =>
                        setAttachments((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      }
                      data-testid={`cancel-attachment-${index}`}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(event) => {
              if (event.target.files) {
                const files = Array.from(event.target.files);
                setAttachments((prev) => [...prev, ...files]);
              }
            }}
            multiple
            data-testid="file-input"
          />
        </div>
        <TextEditor
          value={body}
          onChange={(value) => setBody(value)}
          data-testid="text-editor"
        />
      </div>
      <div className="actions-buttons" data-testid="actions-buttons">
        <Button
          type="danger"
          onClick={clearData}
          className="cancel-button"
          data-testid="cancel-button"
        >
          <CancelIcon /> Cancel
        </Button>
        <Button
          type="default"
          onClick={onClickCreateOrUpdateDraft}
          data-testid="draft-button"
        >
          <EditIcon /> {data?.draftId ? 'Update Draft' : 'Draft'}
        </Button>
        <Button
          type="primary"
          onClick={onClickSendEmail}
          data-testid="send-email-button"
        >
          Send <RightArrowIcon />
        </Button>
      </div>
    </EmailContentContainer>
  );
};
