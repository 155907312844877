import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Select,
  TitleCard,
  FieldText,
  Button,
  RadioGroup,
  FormContainer,
} from '../ui';
import { useForm } from 'react-hook-form';
import {
  Campaign,
  CampaignTemplate,
  CreateCampaignDto,
  EChannel,
  EFrequency,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  campaignSchema,
  handleFormsServerErrors,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type UpdateCampaignFormProps = {
  campaign: Campaign;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getCampaigns: () => void;
  templateLists: CampaignTemplate[];
};

export const UpdateCampaignForm = ({
  campaign,
  setIsOpen,
  getCampaigns,
  templateLists,
}: UpdateCampaignFormProps) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
    reset,
    setValue,
  } = useForm<CreateCampaignDto>({
    resolver: yupResolver(campaignSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.campaigns
      .campaignsControllerUpdate(campaign._id, {
        ...values,
        frequency: values.frequency,
        runningTime: values.runningTime,
      })
      .then(() => {
        toast.success('Campaign updated successfully');
        getCampaigns();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset({
      ...campaign,
      startDate: campaign?.startDate?.split('T')?.[0] ?? '',
      campaignTemplateId: campaign.template?._id ?? '',
      runningTime: campaign.runningTime,
    });
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <div className="row">
        <div className="col-md-7">
          <FieldText
            placeholder="Enter here ..."
            label="Campaign name"
            required
            register={register('name', { required: true })}
            error={errors?.name}
            data-testid="campaign-name-field"
          />
        </div>
        <div className="col-md-5">
          <Select
            control={control}
            label="Channel"
            required
            options={[
              {
                value: EChannel.Whatsapp,
                label: 'WhatsApp',
              },
              {
                value: EChannel.Email,
                label: 'Email',
              },
            ]}
            register={register('channel', { required: true })}
            error={errors?.channel}
            data-testid="channel-select"
          />
        </div>
      </div>
      <Select
        control={control}
        label="Template"
        options={templateLists.map((el) => ({
          label: el.name,
          value: el._id,
        }))}
        required
        register={register('campaignTemplateId', { required: true })}
        error={errors?.campaignTemplateId}
        data-testid="template-select"
      />
      <TitleCard data-testid="schedule-type-title">Schedule type</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Daily',
            value: EFrequency.Daily,
          },
          {
            label: 'Weekly',
            value: EFrequency.Weekly,
          },
          {
            label: 'Monthly',
            value: EFrequency.Monthly,
          },
        ]}
        name="frequency"
        register={register('frequency', { required: true })}
        error={errors?.frequency}
        data-testid="frequency-radio-group"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Start Date"
        required
        type="date"
        register={register('startDate', { required: true })}
        error={errors?.startDate}
        data-testid="start-date-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Send Time (GMT)"
        required
        type="time"
        register={register('runningTime', { required: true })}
        error={errors?.runningTime}
        data-testid="send-time-field"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onClickClose} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
