import React, { Dispatch, SetStateAction } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Chevron } from '../../icons';
import styled from 'styled-components';
import { OptionType } from './inputs';

const DropdownSelectTextContainer = styled.div<{ disabled: boolean }>`
  button {
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: unset;
    outline: unset;
    color: #687588;
    font-size: 14px;
    background-color: unset !important;
    text-transform: unset;
    svg {
      path {
        stroke: ${({ disabled }) => (disabled ? '#bdbdbdcf' : '#687588')};
      }
    }
  }
`;

type DropdownSelectTextProps = {
  selectedItem: OptionType;
  setSelectedItem: Dispatch<SetStateAction<OptionType>>;
  items: OptionType[];
  disabled?: boolean;
};

export const DropdownSelectText = ({
  selectedItem,
  setSelectedItem,
  items,
  disabled = false,
}: DropdownSelectTextProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (option: OptionType) => {
    setSelectedItem(option);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DropdownSelectTextContainer
      disabled={disabled}
      data-testid="dropdown-select-container"
    >
      <Button
        onClick={handleClick}
        disabled={disabled}
        data-testid="dropdown-button"
      >
        <div>{selectedItem.label}</div>
        <Chevron direction="bottom" data-testid="chevron-icon" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        data-testid="dropdown-menu"
      >
        {items.map((el, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelect(el)}
            data-testid={`dropdown-menu-item-${el.value}`}
          >
            {el.label}
          </MenuItem>
        ))}
      </Menu>
    </DropdownSelectTextContainer>
  );
};
