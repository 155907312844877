import React, { useEffect, useState } from 'react';
import { EditIcon, LeftArrowIcon } from '../../icons';
import {
  ATag,
  CardContainer,
  SideModal,
  TitlePage,
  ProjectDetailsContainer,
  TabPageContainer,
  ProjectDetailsGeneral,
  ProjectDetailsActivities,
  ProjectDetailsMatching,
  ProjectDetailsLegal,
  ProjectDetailsRotas,
  ProjectDetailsFinancial,
} from '../../components';
import { ERoute } from '../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Availability,
  Compliance,
  HostedFile,
  Job,
  Payroll,
  User,
} from '../../backend/careo-api';
import { useAuth } from '../../contexts/auth.context';
import { Project, projectsData } from './projects-list.page';

export enum EProjectTabs {
  ROTAS = 'rotas',
  GENERAL = 'general',
  ACTIVITIES = 'activities',
  MATCHING = 'matching',
  FINANCIAL = 'financial',
  LEGAL = 'legal',
}

const tabs = [
  EProjectTabs.ROTAS,
  EProjectTabs.GENERAL,
  EProjectTabs.ACTIVITIES,
  EProjectTabs.MATCHING,
  EProjectTabs.FINANCIAL,
  EProjectTabs.LEGAL,
];

export const ProjectDetailsPage = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(EProjectTabs.GENERAL);
  const [project, setProject] = useState<Project | null>(projectsData[0]);
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [vacanciesList, setVacanciesList] = useState<Job[]>([]);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [compliancesList, setCompliancesList] = useState<Compliance[]>([]);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickGoBack = () => {
    navigate(-1);
  };

  const getProjectDetails = () => {};

  const getProjectDocuments = () => {};

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as EProjectTabs) ||
        EProjectTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {}, []);

  if (!project) {
    return <></>;
  }

  return (
    <>
      <ProjectDetailsContainer data-testid="project-details-container">
        <div className="project-header" data-testid="project-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon data-testid="left-arrow-icon" />
          </div>
          <TitlePage data-testid="title-page">Detail Project</TitlePage>
        </div>

        <CardContainer
          className="page-summary-container"
          data-testid="page-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <div className="module-info" data-testid="module-info">
              <div className="name" data-testid="project-name">
                {project.name}
              </div>
              <div className="availability" data-testid="project-full-name">
                {project.fullName}
              </div>
            </div>
          </div>

          <div
            className={`edit-container edit-icon`}
            onClick={() => {}}
            data-testid="edit-container"
          >
            <EditIcon data-testid="edit-icon" />
          </div>
        </CardContainer>

        <CardContainer
          className="project-information-container"
          data-testid="project-information-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${el === selectedTab && 'active'}`}
                  onClick={() => navigate(`/${ERoute.PROJECTS}/${id}/${el}`)}
                  data-testid={`tab-item-${el.toLowerCase()}`}
                  key={el}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === EProjectTabs.ROTAS && (
                <ProjectDetailsRotas
                  project={project}
                  data-testid="project-rotas-tab"
                />
              )}
              {selectedTab === EProjectTabs.GENERAL && (
                <ProjectDetailsGeneral
                  project={project}
                  data-testid="project-general-tab"
                />
              )}
              {selectedTab === EProjectTabs.ACTIVITIES && (
                <ProjectDetailsActivities
                  project={project}
                  data-testid="project-activities-tab"
                />
              )}
              {selectedTab === EProjectTabs.MATCHING && (
                <ProjectDetailsMatching
                  project={project}
                  data-testid="project-matching-tab"
                />
              )}
              {selectedTab === EProjectTabs.FINANCIAL && (
                <ProjectDetailsFinancial
                  project={project}
                  data-testid="project-financial-tab"
                />
              )}
              {selectedTab === EProjectTabs.LEGAL && (
                <ProjectDetailsLegal
                  project={project}
                  data-testid="project-legal-tab"
                />
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </ProjectDetailsContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Add Note Project"
        data-testid="add-note-project-modal"
      >
        <></>
        {/* <AddNewNoteProjectForm
        project={project}
        onCancel={() => setIsModalOpen(false)}
        onSuccess={() => {
          getProjectDetails();
          setIsModalOpen(false);
        }}
        data-testid="add-note-project-form"
      /> */}
      </SideModal>
    </>
  );
};
