import React, { useEffect } from 'react';
import { Select, TitleCard, FieldText, Button, FormContainer } from '../ui';
import {
  jobLevelWithGrades,
  shiftList,
  specialtiesList,
} from '../../constants';
import { useForm } from 'react-hook-form';
import {
  Client,
  Job,
  User,
  UpdateJobDto,
  EJobStatus,
  EPayOption,
} from '../../backend/careo-api';
import { AxiosInstance, vacancySchema } from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

export const UpdateVacancyForm = ({
  onCancel,
  onSuccess,
  vacancy,
  usersList,
  clientsList,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  vacancy: Job;
  usersList: User[];
  clientsList: Client[];
}) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<UpdateJobDto>({
    resolver: yupResolver(vacancySchema as any),
  });
  const formValues = watch();

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.jobs
      .jobsControllerUpdate(vacancy._id, {
        ...values,
      })
      .then(() => {
        toast.success('Vacancy updated successfully');
        onSuccess();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    reset({
      ...vacancy,
      userId: vacancy.user?._id,
      clientId: vacancy.client?._id,
      vacantFrom: vacancy.vacantFrom?.split?.('T')?.[0] ?? '',
      vacantTo: vacancy.vacantTo?.split?.('T')?.[0] ?? '',
    });
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <Select
        placeholder="Select recruiter"
        required
        label="Recruiter"
        options={[
          ...usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('userId')}
        error={errors.userId}
        control={control}
        data-testid="select-recruiter"
      />
      <TitleCard data-testid="title-card-vacancy-details">
        Vacancy Details
      </TitleCard>
      <Select
        placeholder="Select client"
        label="Client"
        options={[
          ...clientsList.map((el) => ({
            label: el.clientName,
            value: el._id,
          })),
        ]}
        register={register('clientId')}
        error={errors.clientId}
        control={control}
        data-testid="select-client"
      />
      <div className="row" data-testid="row-start-end-date">
        <div className="col-md-6" data-testid="col-start-date">
          <FieldText
            placeholder="Enter here ..."
            label="Start Date"
            required
            type="date"
            register={register('vacantFrom')}
            error={errors.vacantFrom}
            data-testid="field-start-date"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="End Date"
            required
            type="date"
            register={register('vacantTo')}
            error={errors.vacantTo}
            data-testid="field-end-date"
          />
        </div>
      </div>
      <Select
        options={shiftList}
        placeholder="Select Shift"
        label="Shift"
        required
        register={register('shift')}
        error={errors.shift}
        control={control}
        data-testid="select-shift"
      />
      <Select
        options={jobLevelWithGrades.map((el) => ({
          label: el.level,
          value: el.level,
        }))}
        placeholder="Select Job Title"
        label="level"
        required
        register={register('level')}
        error={errors.level}
        control={control}
        data-testid="select-job-title"
      />
      <Select
        options={[
          ...(jobLevelWithGrades
            .find((el) => el.level === formValues.level)
            ?.grades?.map((el) => ({ label: el, value: el })) ?? []),
        ]}
        placeholder="Select Grade"
        label="grade"
        required
        register={register('grade')}
        error={errors.grade}
        disabled={!formValues.level}
        control={control}
        data-testid="select-grade"
      />
      <Select
        options={specialtiesList.map((el) => ({ label: el, value: el }))}
        placeholder="Select Specialty"
        label="Specialty"
        required
        register={register('specialty', { required: true })}
        error={errors.specialty}
        control={control}
        data-testid="select-specialty"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Job Description"
        required
        register={register('jobDescription', { required: true })}
        error={errors.jobDescription}
        data-testid="field-job-description"
      />
      <Select
        options={[
          { label: 'Hired', value: EJobStatus.Hired },
          { label: 'Pending', value: EJobStatus.Pending },
          { label: 'Active', value: EJobStatus.Active },
          { label: 'Closed', value: EJobStatus.Closed },
        ]}
        placeholder="Select Vacancy Status"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
        control={control}
        data-testid="select-vacancy-status"
      />
      <TitleCard data-testid="title-card-pay">Pay</TitleCard>
      <div className="row" data-testid="row-pay">
        <div className="col-md-6" data-testid="col-pay-option">
          <Select
            options={[
              { label: 'Time', value: EPayOption.Time },
              { label: 'Fixed', value: EPayOption.Fixed },
            ]}
            placeholder="Select Pay Option"
            label="Option"
            required
            register={register('payOption', { required: true })}
            error={errors.payOption}
            control={control}
            data-testid="select-pay-option"
          />
        </div>
        <div className="col-md-6" data-testid="col-pay-amount">
          <FieldText
            placeholder="Enter here ..."
            label="Pay amount (per hour)"
            required
            register={register('payAmount', { required: true })}
            error={errors.payAmount}
            type="number"
            min={0}
            data-testid="field-pay-amount"
          />
        </div>
      </div>
      <div className="row" data-testid="row-hours-vat">
        <div className="col-md-6" data-testid="col-hours-per-week">
          <FieldText
            placeholder="Enter here ..."
            label="Hours per week"
            required
            register={register('hoursPerWeek', { required: true })}
            error={errors.hoursPerWeek}
            type="number"
            min={0}
            data-testid="field-hours-per-week"
          />
        </div>
        <div className="col-md-6" data-testid="col-vat">
          <FieldText
            placeholder="Enter here ..."
            label="VAT"
            required
            register={register('vat', { required: true })}
            error={errors.vat}
            type="number"
            min={0}
            data-testid="field-vat"
          />
        </div>
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onCancel} data-testid="button-cancel">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="button-update"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
