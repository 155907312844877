import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  ConfirmModal,
  HeaderPageContainer,
  NewPlacementForm,
  PlacementStatusBadge,
  SearchInput,
  SideModal,
  SubTitlePage,
  Table,
  TitlePage,
  UpdateApplicationForm,
  MultipleSelect,
  Pagination,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../../icons';
import {
  applicationApprovalStatusList,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterPlacement,
  filterPlacements,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortPlacements,
} from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Application,
  EApplicationApprovalStatus,
  EApplicationStatus,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';

const itemsPerPage = 8;

export const rejectedPlacementStatuses = [
  EApplicationApprovalStatus.CANDIDATE_REJECTED,
  EApplicationApprovalStatus.CLIENT_REJECTED,
  EApplicationApprovalStatus.RECRUITER_CANCELED,
  EApplicationApprovalStatus.RECRUITER_DECLINED,
  EApplicationApprovalStatus.RECRUITER_STOPPED,
  EApplicationApprovalStatus.SYSTEM_CANCELLED,
];

export const PlacementsListPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isProfileCompleteQuery = searchParams.get('isProfileComplete');

  const [placements, setPlacements] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [applicationsList, setApplicationsList] = useState<Application[]>([]);
  const [placementsList, setPlacementsList] = useState<Application[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [placementToUpdate, setPlacementToUpdate] = useState<Application>();
  const [recordToDelete, setRecordToDelete] = useState<Application>();

  const [filter, setFilter] = useState<TFilterPlacement>({
    search: '',
    levels: [],
    grades: [],
    statuses: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickAddNew = () => {
    setIsModalOpen(true);
  };

  const onClickEdit = (placement: Application) => {
    setPlacementToUpdate(placement);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PLACEMENTS}/${id}`);
  };

  const getPlacements = async () => {
    setIsLoading(true);
    await AxiosInstance.applications
      .applicationsControllerGetAllPlacements()
      .then((response) => {
        let result = response.data;
        if (isProfileCompleteQuery === 'false') {
          result = result.filter((el) => !el.candidate.isProfileComplete);
        }
        setPlacements(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deletePlacement = async () => {
    await AxiosInstance.applications
      .applicationsControllerDeleteApplication(recordToDelete?._id!)
      .then(() => {
        toast.success('Placement Removed successfully');
        getPlacements();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  const getApplications = async () => {
    AxiosInstance.applications
      .applicationsControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        const filteredResponse = result.filter(
          (el) =>
            el.status !== EApplicationStatus.Rejected &&
            el.status !== EApplicationStatus.Placement,
        );
        setApplicationsList(filteredResponse);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(placementsList, currentPage, itemsPerPage);
  }, [placementsList, currentPage]);

  useEffect(() => {
    const filteredData = filterPlacements(placements, filter);
    const sortedData = sortPlacements(filteredData, sort);
    setPlacementsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [placements, filter, sort]);

  useEffect(() => {
    getPlacements();
    getApplications();
  }, []);

  return (
    <>
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Placements</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Placement
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <Button
              type="primary"
              onClick={onClickAddNew}
              data-testid="add-new-button"
            >
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search placement"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            data-testid="levels-select"
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
            data-testid="grades-select"
          />
          <MultipleSelect
            placeholder="All Statuses"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, statuses: values ?? [] }));
            }}
            options={applicationApprovalStatusList.map((el) => ({
              label: el.label,
              value: el.value,
            }))}
            data-testid="statuses-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="placements-table">
            <thead>
              <tr data-testid="table-header">
                <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" data-testid="select-all-checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="employee-name-header"
                >
                  <div>
                    <label>Employee Name</label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                      data-testid="sort-icon-first-name"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('grade', setSort)}
                  data-testid="placement-header"
                >
                  <div>
                    <label>Placement</label>
                    <SortIcon
                      value={sort.key === 'grade' ? sort.value : ''}
                      data-testid="sort-icon-placement"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('availableFrom', setSort)}
                  data-testid="from-header"
                >
                  <div>
                    <label>From</label>
                    <SortIcon
                      value={sort.key === 'availableFrom' ? sort.value : ''}
                      data-testid="sort-icon-from"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('availableTo', setSort)}
                  data-testid="to-header"
                >
                  <div>
                    <label>To</label>
                    <SortIcon
                      value={sort.key === 'availableTo' ? sort.value : ''}
                      data-testid="sort-icon-to"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('clientName', setSort)}
                  data-testid="client-name-header"
                >
                  <div>
                    <label>Client Name</label>
                    <SortIcon
                      value={sort.key === 'clientName' ? sort.value : ''}
                      data-testid="sort-icon-client-name"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('client-firstName', setSort)}
                  data-testid="lead-contact-header"
                >
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'client-firstName' ? sort.value : ''}
                      data-testid="sort-icon-lead-contact"
                    />
                  </div>
                </th>
                <th data-testid="status-header">
                  <div>
                    <label>Status</label>
                    <SortIcon data-testid="sort-icon-status" />
                  </div>
                </th>
                <th data-testid="actions-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((placement) => {
                        return (
                          <tr
                            key={placement?._id}
                            data-testid={`placement-row-${placement?._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid="checkbox-table"
                            >
                              <input
                                type="checkbox"
                                data-testid={`select-placement-checkbox-${placement?._id}`}
                              />
                            </td>
                            <td data-testid="employee-name-cell">
                              <div
                                className="name-item"
                                data-testid="placement-name-item"
                              >
                                <div>
                                  <div>
                                    {placement.candidate?.firstName}{' '}
                                    {placement.candidate?.lastName}
                                  </div>
                                  <div
                                    className="email"
                                    data-testid="employee-email"
                                  >
                                    {placement.candidate?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid="placement-grade">
                              <div
                                className="name-item"
                                data-testid="placement-grade-item"
                              >
                                <div>
                                  <div>{placement.job?.grade}</div>
                                  <div
                                    className="email"
                                    data-testid="placement-specialty"
                                  >
                                    {placement.job?.specialty}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid="from-date">
                              {formatDate(placement.availableFrom)}
                            </td>
                            <td data-testid="to-date">
                              {formatDate(placement.availableTo)}
                            </td>
                            <td data-testid="client-name">
                              {placement.job?.client?.clientName}
                            </td>
                            <td data-testid="lead-contact">
                              <div
                                className="name-item"
                                data-testid="lead-contact-item"
                              >
                                <div>
                                  <div>
                                    {placement.job?.client?.firstName}{' '}
                                    {placement.job?.client?.lastName}
                                  </div>
                                  <div
                                    className="email"
                                    data-testid="lead-contact-email"
                                  >
                                    {placement.job?.client?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid="status-badge">
                              <PlacementStatusBadge
                                approvalStatus={
                                  placement.approvalStatus ??
                                  EApplicationApprovalStatus.NOT_ACTIVE
                                }
                                data-testid="placement-status-badge"
                              />
                            </td>
                            <td data-testid="actions-column">
                              <div
                                className="action-item"
                                data-testid="placement-actions"
                              >
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(placement?._id)}
                                  data-testid={`view-icon-${placement?._id}`}
                                >
                                  <EyeIcon
                                    data-testid={`eye-icon-${placement?._id}`}
                                  />
                                </div>
                                <div
                                  className={`edit-icon ${rejectedPlacementStatuses.includes(placement?.approvalStatus!) && 'disabled'}`}
                                  onClick={() =>
                                    !rejectedPlacementStatuses.includes(
                                      placement?.approvalStatus!,
                                    ) && onClickEdit(placement)
                                  }
                                  data-testid={`edit-icon-${placement?._id}`}
                                >
                                  <EditIcon
                                    data-testid={`edit-icon-svg-${placement?._id}`}
                                  />
                                </div>
                                <div
                                  className={`delete-icon ${rejectedPlacementStatuses.includes(placement?.approvalStatus!) && 'disabled'}`}
                                  onClick={() =>
                                    !rejectedPlacementStatuses.includes(
                                      placement?.approvalStatus!,
                                    ) && setRecordToDelete(placement)
                                  }
                                  data-testid={`delete-icon-${placement?._id}`}
                                >
                                  <DeleteIcon
                                    data-testid={`delete-icon-svg-${placement?._id}`}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-items-row">
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-text"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={placementsList.length}
          data-testid="pagination"
        />
      </CardContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="New Placement"
        data-testid="new-placement-modal"
      >
        <NewPlacementForm
          setIsOpen={setIsModalOpen}
          getPlacements={getPlacements}
          applicationsList={applicationsList}
          data-testid="new-placement-form"
        />
      </SideModal>

      <SideModal
        isOpen={!!placementToUpdate}
        setIsOpen={() => setPlacementToUpdate(undefined)}
        title="Update Placement"
        data-testid="update-placement-modal"
      >
        <UpdateApplicationForm
          applicationToUpdate={placementToUpdate!}
          onCancel={() => setPlacementToUpdate(undefined)}
          onSuccess={() => {
            getPlacements();
            setPlacementToUpdate(undefined);
          }}
          data-testid="update-placement-form"
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title={'Delete Placement'}
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deletePlacement()}
        data-testid="confirm-delete-modal"
      >
        Do you want to delete this placement ? <br />
        {new Date(recordToDelete?.availableFrom ?? '') <= new Date() ? (
          <>
            Note: <b>Placement is already started</b>
          </>
        ) : recordToDelete?.approvalStatus !==
          EApplicationApprovalStatus.NOT_ACTIVE ? (
          <>
            Note: <b>Placement is already validated</b>
          </>
        ) : (
          <></>
        )}
      </ConfirmModal>
    </>
  );
};
