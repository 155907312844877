import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const ProjectDetailsContainer = styled.div`
  .project-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .back-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }

  .task-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .action-item {
    .disabled {
      cursor: not-allowed;
      background-color: gray;
    }
  }

  .notes-header-container {
    display: flex;
    justify-content: space-between;
  }

  .matching-list-content {
    .filter-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .title-page {
        flex: 1;
      }
      .search-input {
        flex: unset;
      }

      margin-bottom: 24px;
    }
  }

  .rotas-list-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .rotas-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rotas-header-left-container {
      }
      .rotas-header-right-container {
        display: flex;
        align-items: center;
      }
    }
    .rotas-filter-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      .search-input {
        max-width: 300px;
      }
      > div {
        width: 200px;
      }
    }
  }

  .accordion {
    gap: 24px;
    display: flex;
    flex-direction: column;

    &.info-card {
      border: unset;
      padding: unset;
    }
    .accordion-item {
      .accordion-collapse {
        margin: 24px;
      }
      .accordion-body {
        padding: unset;
      }

      .secondary-item .accordion-collapse {
        margin: 24px 0;
      }

      &.primary-item {
        border-radius: 16px;
        border: 1px solid #f1f2f4;
        background: #fff;
        .accordion-button {
          border: unset !important;
          padding: 24px;
          border-bottom: 1px solid #f1f2f4 !important;
        }

        > h2 > .accordion-button {
          font-size: 20px;
        }

        .accordion-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
      &.secondary-item {
        .accordion-button {
          border: unset !important;
          padding: 2px 0px;
        }
        .accordion-body {
          display: flex;
          flex-direction: column;
          gap: 32px;
        }
      }

      .accordion-collapse {
        border: unset;
      }

      .delete-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e03137;
        gap: 10px;
        border-radius: 10px;
        svg {
          fill: white;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .project-header {
      .back-button {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
