import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TitleCard, FieldText, Button, Textarea, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import {
  CampaignTemplate,
  Candidate,
  CreateCampaignTemplateDto,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  campaignTemplateSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type UpdateCampaignTemplateFormProps = {
  campaignTemplate: CampaignTemplate;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getCampaignTemplates: () => void;
};

export const UpdateCampaignTemplateForm = ({
  campaignTemplate,
  setIsOpen,
  getCampaignTemplates,
}: UpdateCampaignTemplateFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<CreateCampaignTemplateDto>({
    resolver: yupResolver(campaignTemplateSchema as any),
  });
  const formValues = watch();

  const [result, setResult] = useState('');

  function generateMessageFromTemplate(
    template: string,
    candidate: Candidate,
  ): string {
    return (
      template?.replace(/\[\[(.+?)\]\]/g, (_, key) => {
        switch (key) {
          case 'title':
            return candidate.title;
          case 'firstName':
            return candidate.firstName;
          case 'lastName':
            return candidate.lastName;
          default:
            return 'UNKNOWN KEY';
        }
      }) ?? ''
    );
  }

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.campaignTemplates
      .campaignTemplatesControllerUpdate(campaignTemplate._id, {
        ...values,
      })
      .then(() => {
        toast.success('Campaign Template updated successfully');
        getCampaignTemplates();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setResult(() => {
      return generateMessageFromTemplate(formValues.message, {
        title: 'Mr.',
        firstName: 'John',
        lastName: 'Doe',
      } as Candidate);
    });
  }, [formValues]);

  useEffect(() => {
    reset(campaignTemplate);
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label="Template name"
        required
        register={register('name', { required: true })}
        error={errors.name}
        data-testid="template-name-field"
      />
      <TitleCard data-testid="message-options-title">Message Options</TitleCard>
      <ul data-testid="message-options-list">
        <li>
          <b>[[title]]</b> : title of candidate (Mrs., Mr., Ms., Dr., Prof.)
        </li>
        <li>
          <b>[[firstName]]</b> : first name of candidate
        </li>
        <li>
          <b>[[lastName]]</b> : last name of candidate
        </li>
      </ul>
      <Textarea
        label="Template Message"
        required
        register={register('message', { required: true })}
        placeholder={`Dear [title] [lastName],\nI hope you are doing well!`}
        rows={3}
        error={errors.message}
        data-testid="template-message-textarea"
      />
      <Textarea
        label="Result (Example)"
        rows={3}
        value={result}
        disabled
        data-testid="result-example-textarea"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button onClick={onClickClose} data-testid="cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
