import React, { useEffect } from 'react';
import {
  Select,
  TitleCard,
  FieldText,
  Button,
  CardContainer,
  TitlePage,
} from '../ui';
import { useForm } from 'react-hook-form';
import { CreateCompanyDto } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import {
  countriesWithRegions,
  regionsWithCounties,
  titles,
} from '../../constants';
import { handleFormsServerErrors } from '../../utils';

export const CompanyDetails = () => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    setFocus,
  } = useForm<CreateCompanyDto>();
  const formValues = watch();

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.company
      .companyControllerUpdate(values)
      .then(() => {
        toast.success('Company updated successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCompanyDetails = () => {
    AxiosInstance.company
      .companyControllerFindOne()
      .then((response) => {
        const company = response.data;
        if (company) {
          reset({
            ...company,
          });
        }
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <CardContainer className="right-container" data-testid="card-container">
      <div
        className="settings-title-content"
        data-testid="settings-title-content"
      >
        <TitlePage>Company Info</TitlePage>
      </div>
      <hr />
      <div className="settings-content" data-testid="settings-content">
        <div className="row">
          <div className="col-md-6">
            <FieldText
              data-testid="company-name"
              placeholder="Enter here ..."
              label="Company name"
              required
              register={register('name', { required: true })}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              data-testid="company-website"
              placeholder="Enter here ..."
              label="Company Website"
              required
              register={register('website', { required: true })}
            />
          </div>
        </div>
        <FieldText
          data-testid="vat-number"
          placeholder="Enter here ..."
          label="VAT Number"
          required
          register={register('vat', { required: true })}
        />
        {/* address form */}
        <>
          <TitleCard data-testid="address-title">Address</TitleCard>
          <FieldText
            data-testid="street-address"
            placeholder="Enter here ..."
            label="Street Address"
            register={register('address.street', { required: true })}
            error={errors?.address?.street}
          />
          <FieldText
            data-testid="city"
            placeholder="Enter here ..."
            label="City"
            register={register('address.city', { required: true })}
            error={errors?.address?.city}
          />
          <div className="row">
            <div className="col-md-6">
              <Select
                data-testid="country-select"
                placeholder="Select country"
                label="Country"
                options={countriesWithRegions.map((el) => ({
                  label: el.country,
                  value: el.country,
                }))}
                register={register('address.country', { required: true })}
                error={errors?.address?.country}
                control={control}
              />
            </div>
            <div className="col-md-6">
              <Select
                data-testid="region-select"
                placeholder="Select Region"
                label="Region"
                required
                options={
                  countriesWithRegions
                    .find((el) => el.country === formValues.address?.country)
                    ?.regions?.map((el) => ({
                      label: el,
                      value: el,
                    })) ?? []
                }
                register={register('address.region', { required: 'true' })}
                error={errors?.address?.region}
                disabled={!formValues.address?.country}
                control={control}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Select
                data-testid="county-select"
                placeholder="Select County"
                label="County"
                required
                options={
                  regionsWithCounties
                    .find((el) => el.region === formValues.address?.region)
                    ?.counties?.map((el) => ({
                      label: el,
                      value: el,
                    })) ?? []
                }
                register={register('address.county', { required: 'true' })}
                error={errors?.address?.county}
                disabled={!formValues.address?.region}
                control={control}
              />
            </div>
            <div className="col-md-6">
              <FieldText
                data-testid="postcode"
                placeholder="Enter here ..."
                label="Postcode"
                required
                register={register('address.zip', { required: true })}
                error={errors?.address?.zip}
              />
            </div>
          </div>
        </>
        <TitleCard data-testid="account-title">Account</TitleCard>
        <div className="row">
          <div className="col-md-2">
            <Select
              data-testid="title-select"
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('title', { required: true })}
              error={errors.title}
              control={control}
              withoutChip
            />
          </div>
          <div className="col-md-5">
            <FieldText
              data-testid="first-name"
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstName', { required: true })}
              error={errors.firstName}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              data-testid="last-name"
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastName', { required: true })}
              error={errors.lastName}
            />
          </div>
        </div>
        <FieldText
          data-testid="job-title"
          placeholder="Enter here ..."
          label="Job title"
          required
          register={register('jobTitle', { required: true })}
          error={errors.jobTitle}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              data-testid="email"
              placeholder="Enter here ..."
              label="Email"
              required
              register={register('email', { required: true })}
              error={errors.email}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              data-testid="phone-number"
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumber', { required: true })}
              error={errors.phoneNumber}
            />
          </div>
        </div>
        <TitleCard data-testid="bank-details-title">Bank details</TitleCard>
        <FieldText
          data-testid="bank-name"
          placeholder="Enter here ..."
          label="Bank Name"
          register={register('bank.name')}
          error={errors.bank?.name}
        />
        <FieldText
          data-testid="account-name"
          placeholder="Enter here ..."
          label="Bank Account Name"
          register={register('bank.accountName')}
          error={errors.bank?.accountName}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              data-testid="sort-code"
              placeholder="Enter here ..."
              label="Sort Code"
              register={register('bank.sortNumber')}
              error={errors.bank?.sortNumber}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              data-testid="account-number"
              placeholder="Enter here ..."
              label="Account Number"
              register={register('bank.accountNumber')}
              error={errors.bank?.accountNumber}
            />
          </div>
        </div>
        <div className="setting-actions" data-testid="setting-actions">
          <Button
            type="primary"
            onClick={onClickSubmit}
            data-testid="save-button"
          >
            Save
          </Button>
        </div>
      </div>
    </CardContainer>
  );
};
