import React, { useEffect, useState } from 'react';
import {
  Alert,
  CardContainer,
  DashboardComplianceChart,
  DashboardRolesChart,
  DashboardSpecialitiesChart,
  OptionType,
  SubTitlePage,
  TitlePage,
  DropdownSelectText,
} from '../../components';
import { Link } from 'react-router-dom';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { DashboardStatistics, User } from '../../backend/careo-api';
import { CandidatesListPage } from '../candidate';
import * as moment from 'moment-timezone';
import { useAuth } from '../../contexts/auth.context';
import { DashboardContainer } from './dashboard.style';
import { toast } from 'react-toastify';

enum ETimePeriodsList {
  Today = 'Today',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastSixMonths = 'LastSixMonths',
  LastYear = 'LastYear',
  AllTime = 'AllTime',
}

const TimePeriodsList = [
  { value: ETimePeriodsList.Today, label: 'Today' },
  { value: ETimePeriodsList.LastWeek, label: 'Last Week' },
  { value: ETimePeriodsList.LastMonth, label: 'Last Month' },
  { value: ETimePeriodsList.LastSixMonths, label: 'Last 6 Months' },
  { value: ETimePeriodsList.LastYear, label: 'Last Year' },
  { value: ETimePeriodsList.AllTime, label: 'All Time' },
];

export const DashboardPage = () => {
  const { user } = useAuth();

  const [dateRange, setDateRange] = useState<
    | {
        fromDate?: string;
        toDate?: string;
      }
    | undefined
  >();

  const [selectedPeriod, setSelectedPeriod] = useState<OptionType>({
    value: ETimePeriodsList.Today,
    label: 'Today',
  });
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const [statistics, setStatistics] = useState<DashboardStatistics>({
    dashboard: {
      _id: '',
      CandidatesStats: { newlyCreated: 0 },
      VacanciesStatistics: {
        newlyCreated: 0,
        hired: 0,
        pending: 0,
        active: 0,
        closed: 0,
        Filled: 0,
        Unfilled: 0,
      },
      ComplianceStats: { IN_PROGRESS: 0, PASSED: 0, FAILED: 0, EXPIRED: 0 },
      user: {} as User,
      VacStatusSpecialitiesStats: {},
    },
    liveStats: {
      incompleteProfiles: 0,
      placementsWithIncompleteProfiles: 0,
      clientsWithIncompleteProfiles: 0,
      payrollsWithIncompleteProfiles: 0,
    },
  });

  const complianceStats = statistics.dashboard.ComplianceStats;

  const {
    clientsWithIncompleteProfiles,
    incompleteProfiles,
    payrollsWithIncompleteProfiles,
    placementsWithIncompleteProfiles,
  } = statistics.liveStats;

  const fetchDashboardStatistics = async () => {
    setIsLoading(true);
    await AxiosInstance.statistics
      .statisticsControllerFetchDashboardStatistics(dateRange)
      .then((result) => {
        setStatistics(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (dateRange) fetchDashboardStatistics();
  }, [dateRange]);

  useEffect(() => {
    switch (selectedPeriod.value) {
      case ETimePeriodsList.Today:
        setDateRange({
          fromDate: undefined,
          toDate: undefined,
        });
        break;
      case ETimePeriodsList.LastWeek:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastMonth:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'month')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastSixMonths:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(6, 'month')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastYear:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.AllTime:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(10, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      default:
        setDateRange({
          fromDate: undefined,
          toDate: undefined,
        });
        break;
    }
  }, [selectedPeriod]);

  return (
    <DashboardContainer data-testid="dashboard-container">
      <div className="alerts-container" data-testid="alerts-container">
        {incompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some candidates that are incomplete.
                <strong>
                  <Link
                    to={'/candidates?isProfileComplete=false'}
                    data-testid="incomplete-candidates-link"
                  >
                    Click here
                  </Link>
                </strong>
              </>
            }
            data-testid="incomplete-candidates-alert"
          />
        )}

        {payrollsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some payrolls that are incomplete.
                <strong>
                  <Link
                    to={'/payrolls?isProfileComplete=false'}
                    data-testid="incomplete-payrolls-link"
                  >
                    Click here
                  </Link>
                </strong>
              </>
            }
            data-testid="incomplete-payrolls-alert"
          />
        )}

        {clientsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some clients that are incomplete.
                <strong>
                  <Link
                    to={'/clients?isProfileComplete=false'}
                    data-testid="incomplete-clients-link"
                  >
                    Click here
                  </Link>
                </strong>
              </>
            }
            data-testid="incomplete-clients-alert"
          />
        )}

        {placementsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some placements that are incomplete.{' '}
                <strong>
                  <Link
                    to={'/placements?isProfileComplete=false'}
                    data-testid="incomplete-placements-link"
                  >
                    Click here
                  </Link>
                </strong>
              </>
            }
            data-testid="incomplete-placements-alert"
          />
        )}
      </div>

      <div data-testid="user-greeting">
        <TitlePage data-testid="greeting-title">
          Hi, {user?.firstName}
        </TitlePage>
        <div className="dashboard-subtitle" data-testid="dashboard-subtitle">
          <SubTitlePage>This is your HR report so far</SubTitlePage>
          <DropdownSelectText
            items={TimePeriodsList}
            selectedItem={selectedPeriod}
            setSelectedItem={setSelectedPeriod}
            data-testid="time-period-dropdown"
          />
        </div>
      </div>

      <div className="donut-charts" data-testid="donut-charts">
        <DashboardComplianceChart
          data={[
            complianceStats.PASSED,
            complianceStats.IN_PROGRESS,
            complianceStats.FAILED,
          ]}
          isLoading={isLoading}
          data-testid="compliance-chart"
        />
        <DashboardSpecialitiesChart
          stats={statistics.dashboard.VacStatusSpecialitiesStats as any}
          selectedSpeciality={selectedSpeciality}
          setSelectedSpeciality={setSelectedSpeciality}
          isLoading={isLoading}
          data-testid="specialities-chart"
        />
        <DashboardRolesChart
          stats={statistics.dashboard.VacStatusSpecialitiesStats as any}
          selectedSpeciality={selectedSpeciality}
          isLoading={isLoading}
          data-testid="roles-chart"
        />
      </div>

      {/* <TopStatisticsWidgets statistics={statistics.dashboard} /> */}

      <div className="dashboard-content" data-testid="dashboard-content">
        <CardContainer
          className="dashboard-candidate-list-container"
          data-testid="candidate-list-container"
        >
          <div>
            <CandidatesListPage
              isDashboard={true}
              data-testid="candidate-list-page"
            />
          </div>
        </CardContainer>
      </div>
    </DashboardContainer>
  );
};
