import React, { useEffect, useRef } from 'react';

interface OtpInputProps {
  length?: number;
  otpValue: string;
  setOtpValue: (value: string) => void;
  onComplete: (otp: string) => void;
}

const OtpInput: React.FC<OtpInputProps> = ({
  length = 6,
  otpValue,
  setOtpValue,
  onComplete,
}) => {
  const otpRefs = Array.from({ length }, () => useRef<HTMLInputElement>(null));

  const handleInput =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (!/^\d$/.test(value)) {
        event.target.value = ''; // Clear if not a digit
        return;
      }

      // Update the OTP value in the parent component
      const newOtpValue = otpValue.split('');
      newOtpValue[index] = value;
      setOtpValue(newOtpValue.join(''));

      // Move focus to the next input if filled
      if (index < otpRefs.length - 1) {
        otpRefs[index + 1].current?.focus();
      } else {
        submitOtp(newOtpValue.join('')); // Auto-submit if all digits are filled
      }
    };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace') {
        const newOtpValue = otpValue.split('');
        newOtpValue[index] = ''; // Clear the value at this index
        setOtpValue(newOtpValue.join(''));

        if (index > 0) {
          otpRefs[index - 1].current?.focus();
        }
      }
    };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = event.clipboardData.getData('text');
    if (new RegExp(`^\\d{${length}}$`).test(paste)) {
      // Handle only if pasted content matches length
      const newOtpValue = paste.split('');
      setOtpValue(newOtpValue.join(''));

      newOtpValue.forEach((char, i) => {
        if (otpRefs[i].current) {
          otpRefs[i].current!.value = char;
        }
      });
      submitOtp(newOtpValue.join('')); // Auto-submit after pasting
    }
    event.preventDefault();
  };

  const submitOtp = (otp: string) => {
    if (otp.length === length) {
      onComplete(otp);
      setOtpValue('');
      otpRefs[0].current?.focus();
    }
  };

  useEffect(() => {
    otpRefs[0].current?.focus();
  }, []);

  return (
    <div
      className="d-flex justify-content-center"
      data-testid="otp-input-container"
    >
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          ref={otpRefs[index]}
          type="text"
          maxLength={1}
          value={otpValue[index] || ''} // Display current value for this index
          className="m-2 text-center form-control rounded"
          onChange={handleInput(index)}
          onKeyDown={handleKeyDown(index)}
          onPaste={handlePaste}
          data-testid={`otp-input-${index}`} // Test ID for each input
        />
      ))}
    </div>
  );
};

export default OtpInput;
