import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const SideModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6000;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  .back-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      path {
        fill: #111827;
      }
    }
  }
  .content-container {
    background-color: white;
    width: 700px;
    height: 100%;
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow-y: auto;
    .side-modal-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }

  .action-container {
    cursor: pointer;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    height: 100vh;
    .back-button {
      display: none;
    }
    .content-container {
      padding: 20px;
      gap: 5px;
    }
  }

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;
