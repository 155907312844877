import styled from 'styled-components';
import { breakpoint } from '../../../constants';

interface SidebarContainerProps {
  isSidebarActive: boolean;
}

export const SidebarContainer = styled.aside<SidebarContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ isSidebarActive, theme }) =>
    isSidebarActive ? theme.sidebar.open : theme.sidebar.close};
  height: 100vh;
  background-color: ${(props) => props.theme.whiteColor};
  transition: 0.5s;
  .sidebar-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding: ${({ isSidebarActive }) =>
      isSidebarActive ? '24px 32px' : '24px 5px'};
    .sidebar-header {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .logo-container {
        text-align: center;
        .toggle-sidebar {
          display: none;
          color: #4723d9;
          font-size: 1.5rem;
          cursor: pointer;
        }
        svg {
          max-width: 100%;
        }
      }
      .dashboard-button {
        background-color: #0caf60;
        color: white;
        padding: 16px 20px;
        display: flex;
        justify-content: space-between;
        path {
          fill: white;
        }

        &:hover {
          background-color: #04944e;
        }
      }
    }
    .sidebar-footer {
    }

    .sidebar-list-container {
      display: flex;
      flex-direction: column;
    }

    span {
      display: ${({ isSidebarActive }) => (isSidebarActive ? 'unset' : 'none')};
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      font-size: 14px;
      font-weight: 500;
      color: ${(props) => props.theme.text.blackColor};
      gap: 10px;
      border-radius: 10px;
      path {
        fill: #000;
      }

      &.disabled {
        color: gray;
      }

      /* &:not(.disabled).active {
        color: #0caf60;
        path {
          fill: #0caf60;
        }
      } */

      &:not(.disabled).active,
      &:not(.disabled):hover {
        background-color: #0caf60;
        color: white;
        path {
          fill: white;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    width: ${({ isSidebarActive }) => (isSidebarActive ? '100%' : '0px')};
    z-index: 1;
    .sidebar-container {
      padding: ${({ isSidebarActive }) =>
        isSidebarActive ? '24px 32px;' : '0'};
      .sidebar-header {
        .logo-container {
          display: flex;
          justify-content: space-between;
          .toggle-sidebar {
            display: block;
          }
        }
      }
      a {
        justify-content: center;
      }
    }
  }

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;
