import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  DraggableArea,
  LeadCardPipeline,
  PipelineContainer,
  PipelineStatus,
} from '../ui';
import { leadStatusPipeline } from '../../constants';
import { Lead } from '../../backend/careo-api';
import { PlusIcon } from '../../icons';
import { AxiosInstance } from '../../utils';

type LeadsPipelineProps = {
  leads: Lead[];
  getLeads: () => void;
  setIsViewModalOpen: Dispatch<SetStateAction<Lead | undefined>>;
};

export const LeadsPipeline = ({
  leads,
  getLeads,
  setIsViewModalOpen,
}: LeadsPipelineProps) => {
  const [pipelines, setPipelines] = useState<PipelineStatus<Lead>[]>(
    leadStatusPipeline.map((el) => ({ ...el, items: [] })),
  );

  const handleDrop = async (id: string, newListIndex: number) => {
    const newStatus = leadStatusPipeline[newListIndex].value;
    await AxiosInstance.leads.leadsControllerUpdateById(id, {
      status: newStatus,
    });
  };

  useEffect(() => {
    const groupedLeads = leads.reduce<Record<string, Lead[]>>((acc, curr) => {
      const status = curr.status;
      acc[status] = acc[status] ? [...acc[status], curr] : [curr];
      return acc;
    }, {});

    console.log('groupedLeads');
    console.log(groupedLeads);

    setPipelines((prevPipelines) =>
      prevPipelines.map((pipeline) => ({
        ...pipeline,
        items: groupedLeads[pipeline.value] || [],
      })),
    );
  }, [leads]);

  return (
    <PipelineContainer>
      {pipelines.map((el, listIndex: number) => (
        <div className="item-container" key={listIndex}>
          <div className="item-header">
            <div className="item-title">
              <label>{el.label}</label>
              <div className="item-total">{el.items.length}</div>
            </div>
            <PlusIcon />
          </div>
          <div className="cards-list-container">
            {el.isDraggableTo ? (
              <DraggableArea
                pipeline={pipelines}
                listIndex={listIndex}
                itemIndex={0}
                onSuccess={getLeads}
                onDragRequest={handleDrop}
              />
            ) : (
              <div className="draggable-area false">
                <hr />
              </div>
            )}
            {el.items.map((item, itemIndex: number) => (
              <>
                <LeadCardPipeline
                  item={item}
                  listIndex={listIndex}
                  itemIndex={itemIndex}
                  setIsViewModalOpen={setIsViewModalOpen}
                />
                {el.isDraggableTo ? (
                  <DraggableArea
                    pipeline={pipelines}
                    listIndex={listIndex}
                    itemIndex={0}
                    onSuccess={getLeads}
                    onDragRequest={handleDrop}
                  />
                ) : (
                  <div className="draggable-area false">
                    <hr />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
    </PipelineContainer>
  );
};
