import React, { useState } from 'react';
import { Button, ConfirmModal, SideModal, Table } from '../ui';
import { useParams } from 'react-router-dom';
import { DeleteIcon } from '../../icons';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { Client, EUserRole, Rate } from '../../backend/careo-api';
import { NewRateForm } from './new-rate-form.component';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth.context';

type ClientRatesProps = {
  client: Client;
  getClientDetails: () => void;
};

export const ClientRates = ({ client, getClientDetails }: ClientRatesProps) => {
  const { id } = useParams();
  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Rate>();

  const isClientBelongsToUser =
    client?.user?._id === user?._id || user?.role === EUserRole.Admin;

  const deleteRate = async () => {
    await AxiosInstance.clients
      .clientsControllerDeleteRate(id!, recordToDelete?._id!)
      .then(() => {
        toast.success('Rate removed successfully');
        getClientDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  return (
    <>
      <div className="info-card" data-testid="info-card">
        <div className="info-card-header" data-testid="info-card-header">
          <div className="info-card-title" data-testid="info-card-title">
            Rates List
          </div>
          {isClientBelongsToUser && (
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              data-testid="add-rate-button"
            >
              Add new rate
            </Button>
          )}
        </div>
        <hr />
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="rates-table">
            <thead>
              <tr>
                <th data-testid="job-title-header">
                  <div>
                    <label>Job Title</label>
                  </div>
                </th>
                <th data-testid="grade-header">
                  <div>
                    <label>Grade</label>
                  </div>
                </th>
                <th data-testid="charge-rate-header">
                  <div>
                    <label>Charge Rate</label>
                  </div>
                </th>
                <th data-testid="actions-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(client.rates ?? [])?.map((rate) => {
                return (
                  <tr key={rate._id} data-testid={`rate-row-${rate._id}`}>
                    <td data-testid={`rate-level-${rate._id}`}>
                      {rate?.level}
                    </td>
                    <td data-testid={`rate-grade-${rate._id}`}>
                      {rate?.grade}
                    </td>
                    <td data-testid={`rate-amount-${rate._id}`}>
                      {rate?.amount}
                    </td>
                    <td>
                      <div
                        className="action-item"
                        data-testid={`action-item-${rate._id}`}
                      >
                        <div
                          className={`delete-icon ${
                            !isClientBelongsToUser && 'disabled'
                          }`}
                          onClick={() =>
                            isClientBelongsToUser && setRecordToDelete(rate)
                          }
                          data-testid={`delete-icon-${rate._id}`}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="New Rate"
        data-testid="new-rate-modal"
      >
        <NewRateForm
          getClientDetails={getClientDetails}
          setIsOpen={setIsModalOpen}
          data-testid="new-rate-form"
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Rate"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteRate()}
        data-testid="confirm-delete-rate-modal"
      >
        Do you want to delete{' '}
        <b data-testid="rate-to-delete">
          {recordToDelete?.level}/{recordToDelete?.grade}
        </b>{' '}
        rate ?
      </ConfirmModal>
    </>
  );
};
